import { format, getTime, formatDistanceToNow } from 'date-fns';
import { ar, enUS } from 'date-fns/locale';

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export function NewfToNow(date, currentLang) {
  if (date === undefined) {
    return  '';
  }

  const newDate = new Date(date);

  return newDate
    ? formatDistanceToNow(newDate.setTime(newDate.getTime() + 3 * 60 * 60 * 1000), {
        addSuffix: true,
        locale: currentLang?.value === 'ar' ? ar : enUS,
      })
    : '';
}
