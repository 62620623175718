import createDataContext from './createDataContext';
import trackerApi from '../api/index';

const trackReducer = (state, action) => {
  switch (action.type) {
    case 'list_services':
      return { ...state, services: action.payload };
    case 'list_regions_services':
      return { ...state, regions: action.payload };
    case 'create_order':
      return { ...state, order: action.payload };
    case 'list_regions_details_services':
      return { ...state, servicesDetail: action.payload };
    case 'list_all_orders':
      return { ...state, orders: action.payload };
    case 'list_orders_total_elements':
      return { ...state, ordersTotalElements: action.payload };
    case 'list_all_offers':
      return { ...state, offers: action.payload };
    case 'append_list_all_orders':
      return { ...state, orders: [...state.orders, ...action.payload] };
    case 'append_list_all_offers':
      state.offers.filter((elem) => elem.order_id === action.payload[0].order_id);
      return { ...state, offers: state.offers.concat(action.payload) };
    case 'set_service_now':
      return { ...state, serviceNow: action.payload };
    case 'set_reigon_now':
      return { ...state, reigonNow: action.payload };
    case 'set_details_now':
      return { ...state, detailsNow: { ...state.detailsNow, ...action.payload } };
    case 'empty_details_now':
      return { ...state, detailsNow: {} };
    case 'list_all_notification':
      return { ...state, notifications: action.payload };
    case 'add_to_cart':
      return { ...state, cartList: state.cartList.concat([action.payload]) };
    case 'remove_from_cart':
      // eslint-disable-next-line no-case-declarations
      const afterRemovedablicated = state.cartList.filter((_, index) => index !== action.payload);
      return { ...state, cartList: afterRemovedablicated };
    case 'empty_cart':
      return { ...state, cartList: [], detailsNow: [], reigonNow: -1, serviceNow: -1 };
    default:
      return state;
  }
};

const listServices = (dispatch) => async () => {
  const response = await trackerApi.get('/service/list/available_services');
  dispatch({ type: 'list_services', payload: response.data });
  listrRgionServices(response.data[0]);
};

// const listrRgionServices = dispatch => async (service_id) => {
//     const response = await trackerApi.get(`/client/regions/for_service/${service_id}`);
//     dispatch({ type: 'list_regions_services', payload: response.data });

//   };

const listrRgionServices = (dispatch) => async (service_id) => {
  const response = await trackerApi.get(`/region/list/`);
  dispatch({ type: 'list_regions_services', payload: response.data?.data });
};

const listrRequiredFiledsServices = (dispatch) => async (service_id) => {
  const response = await trackerApi.get(`/service/${service_id}/required_details`);
  dispatch({ type: 'list_regions_details_services', payload: [response.data] });
};

const createOrder = (dispatch) => async (data) => {
  const response = await trackerApi.post(
    `/client/orders/create/`,
    {
      ...data,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  dispatch({ type: 'create_order', payload: response.data });
};

const listrAllOrders = (dispatch) => async (skip, limit) => {
  let appndOrders = false;
  if (skip && limit) {
    appndOrders = true;
  }
  skip = skip || 0;
  limit = limit || 10;
  const response = await trackerApi.get(`/orders/list/all?skip=${skip}&limit=${limit}`);
  dispatch({
    type: appndOrders ? 'append_list_all_orders' : 'list_all_orders',
    payload: response?.data?.data,
  });
  // dispatch({ type:'list_all_orders', payload: response?.data?.data });
  dispatch({
    type: 'list_orders_total_elements',
    payload: response?.data?.metadata.total_elements,
  });
  const orders_offers = [];
  response.data?.data.map(async (elem, index) => {
    const offers = await trackerApi.get(`/orders/${elem.id}/offers`);
    orders_offers.push(...offers.data.data);

    if (index === response.data.data.length - 1) {
      dispatch({ type: 'list_all_offers', payload: orders_offers });
    }
  });
};

const listrAllOffers = (dispatch) => async (order_id) => {
  const response = await trackerApi.get(`/orders/${order_id}/offers`);
  dispatch({ type: 'append_list_all_offers', payload: response.data });
};

const acceptOffer = (dispatch) => async (order_id, offer_id, callback) => {
  await trackerApi.put(`/orders/${order_id}/offers/accept/${offer_id}`);
  callback();
};

const rejectOffer = (dispatch) => async (order_id, offer_id, message, callback) => {
  await trackerApi.put(`/orders/${order_id}/offers/reject/${offer_id}`, {
    reject_message: message,
  });
  callback();
};

const listrAllNotification = (dispatch) => async () => {
  const response = await trackerApi.get(`/client/notifications`);
  const notifications = response.data.map((element, index) => ({
    id: element?.id,
    title: 'Order Status changed',
    description: element?.display_text,
    avatar: `https://minimals.cc/assets/icons/notification/ic_package.svg`,
    type: element?.notification_type,
    createdAt: '',
    isUnRead: true,
  }));
  dispatch({ type: 'list_all_notification', payload: notifications });
};

const readNotification = (dispatch) => async (notification_id, callback) => {
  try {
    await trackerApi.patch(`/client/notifications/${notification_id}`);
    callback();
  } catch (e) {
    console.log('error', e);
  }
};

const setServiceNow = (dispatch) => (id) => {
  dispatch({ type: 'set_service_now', payload: id });
};

const setReigonNow = (dispatch) => (id) => {
  dispatch({ type: 'set_reigon_now', payload: id });
};

const setDetailsNow = (dispatch) => (values) => {
  dispatch({ type: 'set_details_now', payload: values });
};

const emptyDetails = (dispatch) => (values) => {
  dispatch({ type: 'empty_details_now', payload: values });
};

const addToCart = (dispatch) => (value, callback) => {
  dispatch({ type: 'add_to_cart', payload: value });
  // eslint-disable-next-line no-unused-expressions
  callback && callback();
};

const removeFromCart = (dispatch) => (id, callback) => {
  dispatch({ type: 'remove_from_cart', payload: id });
  // eslint-disable-next-line no-unused-expressions
  callback && callback();
};

const createGroupOrder = (dispatch) => async (data, callback) => {
  const order = { ...data[0] };

  await trackerApi.post(`/orders/create`, order, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  dispatch({ type: 'empty_cart' });

  // eslint-disable-next-line no-unused-expressions
  callback && callback();
};

const getWaitingBids = async () => {
  //  const result = await trackerApi.get("/service/1/orders/waiting_bids")
  //  return result;
};

export const { Provider, Context } = createDataContext(
  trackReducer,
  {
    removeFromCart,
    setServiceNow,
    addToCart,
    listServices,
    setDetailsNow,
    createGroupOrder,
    listrRgionServices,
    createOrder,
    listrRequiredFiledsServices,
    listrAllOrders,
    setReigonNow,
    emptyDetails,
    listrAllNotification,
    readNotification,
    listrAllOffers,
    acceptOffer,
    rejectOffer,
    getWaitingBids,
  },
  {
    serviceNow: -1,
    reigonNow: -1,
    detailsNow: [],
    services: [],
    regions: [],
    order: {},
    servicesDetail: [],
    orders: [],
    cartList: [],
    offers: [],
    ordersTotalElements: 0,
    notifications: [],
  }
);
