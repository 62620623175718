/* eslint-disable */
import React, { useReducer } from 'react';

export default (reducer, actions, defaultValue) => {
  const Context = React.createContext();

  // eslint-disable-next-line react/prop-types
  const Provider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, defaultValue);

    const boundActions = {};
    // eslint-disable-next-line no-restricted-syntax, guard-for-in, prefer-const
    for (let key in actions) {
      boundActions[key] = actions[key](dispatch);
    }

    return (
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      <Context.Provider value={{ state, ...boundActions }}>{children}</Context.Provider>
    );
  };

  return { Context, Provider };
};
