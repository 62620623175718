/* eslint-disable */
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import { useSettingsContext } from '../../components/settings';
//
import Main from './Main';
import Header from './header';
import NavMini from './nav/NavMini';
import NavVertical from './nav/NavVertical';
import NavHorizontal from './nav/NavHorizontal';
import Footer from '../main/Footer';
import { setWebSocketNotification } from 'src/redux/slices/notifications';
import { dispatch, useSelector } from 'src/redux/store';
import { getActiveOrders } from 'src/redux/slices/Activeorders';
import { eventName, swalWarningSp, swalWarningWaredat } from 'src/utils/serviceUtils';
import { useAuthContext } from 'src/auth/useAuthContext';
import { PATH_AUTH } from 'src/routes/paths';
import { useChannel, usePresence } from 'ably/react';
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------
export default function DashboardLayout() {
  const { themeLayout } = useSettingsContext();
  const { translate } = useLocales();
  const isDesktop = useResponsive('up', 'lg');
  const [open, setOpen] = useState(false);
  const { page } = useSelector((state) => state.activeOrders);
  const { logout } = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();

  const isNavHorizontal = themeLayout === 'horizontal';
  const isNavMini = themeLayout === 'mini';

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let user_id = localStorage.getItem('user_id');
  let user_type = localStorage.getItem('userType');
  const { presenceData, updateStatus } = usePresence(`notifications:${user_id}`);
  const { channel, ably } = useChannel(`notifications:${user_id}`, (message) => {
    if (message?.name === eventName.deactivate_logout) {
      logout();
      navigate(PATH_AUTH.login, { replace: true });
    } else if (message?.name === eventName.order_time_over_event) {
      if (user_type === 'admin' || user_type === 'waredat') {
        swalWarningWaredat(message.data?.[0]?.payload?.order_id, translate);
      } else if (user_type === 'service_provider') {
        swalWarningSp(message.data?.[0]?.payload?.order_id, translate);
      }
      dispatch(setWebSocketNotification(message));
    } else {
      if (
        !(
          user_type === 'service_provider' &&
          message.data?.[0]?.notification_type === 'user:order:created'
        )
      ) {
        if (
          !(
            message.name === 'chat-outgoing' ||
            message.name === 'chat-incoming' ||
            message.name === 'chat-outgoing-admin' ||
            message.name === 'user:order:retracted_offer'
          )
        ) {
          dispatch(setWebSocketNotification(message));
        }
      }

      //client
      let currRoute = window.location.href?.split('/dashboard')[1];
      if (currRoute === '/client/list/orders') {
        dispatch(getActiveOrders(page));
      }
    }
  });

  const renderNavVertical = <NavVertical openNav={open} onCloseNav={handleClose} />;

  if (isNavHorizontal) {
    return (
      <>
        <Header onOpenNav={handleOpen} />

        {isDesktop ? <NavHorizontal /> : renderNavVertical}

        <Main className="main-area">
          <Outlet />
        </Main>
        <Footer />
      </>
    );
  }

  if (isNavMini) {
    return (
      <>
        <Header onOpenNav={handleOpen} />

        <Box
          sx={{
            display: { lg: 'flex' },
            minHeight: { lg: 1 },
          }}
          className="main-page"
        >
          {isDesktop ? <NavMini /> : renderNavVertical}

          <Main className="main-area">
            <Outlet />
          </Main>
        </Box>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={handleOpen} />

      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
        className="main-page"
      >
        {renderNavVertical}
        <Main className="main-area">
          <Outlet />
        </Main>
      </Box>
      <Footer />
    </>
  );
}