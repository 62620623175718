/* eslint-disable */
import PropTypes from 'prop-types';
// @mui
import { Dialog, DialogTitle, DialogActions, DialogContent, LinearProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { clearInterval, setInterval } from 'worker-timers';

// ----------------------------------------------------------------------

SessionDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.node,
  action: PropTypes.node,
  content: PropTypes.node,
};

export default function SessionDialog({
  title,
  content,
  action,
  open,
  onDispatchLogout,
  ...other
}) {
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    let timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 0) {
          clearInterval(timer);
          onDispatchLogout();
        }

        return oldProgress - 1;
      });
    }, 200);

    // return () => {
    //   clearInterval(timer);
    // };
  }, []);

  return (
    <Dialog fullWidth maxWidth="xs" open={open} {...other}>
      <LinearProgress sx={{ height: '7px' }} color="error" variant="determinate" value={progress} />
      <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>

      {content && <DialogContent sx={{ typography: 'body2' }}> {content} </DialogContent>}

      <DialogActions>{action}</DialogActions>
    </Dialog>
  );
}
