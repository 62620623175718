import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  user: {
    user_type: null,
    access_token: null,
    refresh_token: null,
    token_type: null,
    user_id: null,
  },
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const { setUser } = slice.actions;
