/* eslint-disable */
import axios from 'axios';
import { PATH_AUTH } from 'src/routes/paths';

const instance = axios.create({
  baseURL: 'https://api-s2.waredat.sa/api/v1',
});

instance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => Promise.reject(err)
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      window.location.href = PATH_AUTH.login;
      localStorage.clear()
    }
    return Promise.reject(error);
  }
);

export default instance;
