import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import productReducer from './slices/product';
import userReducer from './slices/user';
import activeOrdersReducer from './slices/Activeorders';
import OrdersReducer from './slices/ordersSlice';
import HistoryOrdersReducer from './slices/ClientHistoryOrders';
import OrderStatsReducer from './slices/orderStats';
import NotificationsReducer from './slices/notifications';
import WaitingOffersReducer from './slices/WaitingOffersSp';
import HistoryOrdersSpReducer from './slices/HistoryOrdersSp';
import OtpReducer from './slices/Otp';
// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

export const OrdersPersistConfig = {
  key: 'orders',
  storage,
  keyPrefix: 'redux-',
};

export const UserPersistConfig = {
  key: 'user',
  storage,
  keyPrefix: 'redux-',
};

export const NotificationPersistConfig = {
  key: 'notification',
  storage,
  keyPrefix: 'redux-',
};

const rootReducer = combineReducers({
  user: persistReducer(UserPersistConfig, userReducer),
  otp: OtpReducer,
  activeOrders: activeOrdersReducer,
  historyOrders: HistoryOrdersReducer,
  waitingOffers: WaitingOffersReducer,
  historyOrdersSp: HistoryOrdersSpReducer,
  notifications: persistReducer(NotificationPersistConfig,NotificationsReducer) ,
  order_stats: OrderStatsReducer,
  orders: persistReducer(OrdersPersistConfig, OrdersReducer),
  product: persistReducer(productPersistConfig, productReducer),
});

export default rootReducer;
