/* eslint-disable */
import PropTypes from 'prop-types';
// @mui
import { Box } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// config
import { HEADER, NAV } from '../../config-global';
// components
import { useSettingsContext } from '../../components/settings';
import { useEffect } from 'react';
import { isValidToken } from 'src/auth/utils';
import { useNavigate } from 'react-router-dom';
// ----------------------------------------------------------------------

const SPACING = 8;

Main.propTypes = {
  sx: PropTypes.object,
  children: PropTypes.node,
};

export default function Main({ children, sx, ...other }) {
  const navigate = useNavigate();
  const { themeLayout } = useSettingsContext();
  useEffect(() => {
    let token = localStorage.getItem('accessToken');
    if (token === null) {
      localStorage.clear();
      navigate('/login', { replace: true });
    }
    let validToken = isValidToken(token);
    if (validToken === false) {
      localStorage.clear();
      navigate('/login', { replace: true });
    }
  }, []);

  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  const isDesktop = useResponsive('up', 'lg');

  if (isNavHorizontal) {
    return (
      <Box
        component="main"
        sx={{
          pt: `${HEADER.H_MOBILE + SPACING}px`,
          pb: `${HEADER.H_MOBILE + SPACING}px`,
          ...(isDesktop && {
            px: 2,
            pt: `${HEADER.H_DASHBOARD_DESKTOP + 80}px`,
            pb: `${HEADER.H_DASHBOARD_DESKTOP + SPACING}px`,
          }),
        }}
      >
        {children}
      </Box>
    );
  }



  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: `${HEADER.H_MOBILE + SPACING}px`,
        px: 2,
        ...(window.location.href.includes('/support-chat') &&
          isDesktop === false && {
            height: '90vh',
            pb: '10px',
            pt: '65px',
            pl: '4px',
            pr: '4px',
          }),

        ...(isDesktop && {
          px: 2,
          py: `${HEADER.H_DASHBOARD_DESKTOP + SPACING}px`,
          width: `calc(100% - ${NAV.W_DASHBOARD}px)`,
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI}px)`,
          }),
        }),

        ...(isDesktop &&
          window.location.href.includes('/support-chat') && {
            px: 0,
            py: '95px',
            pb: '2px',
            pl: '4px',
            pr: '4px',
            width: `calc(100% - ${NAV.W_DASHBOARD}px)`,
            ...(isNavMini && {
              width: `calc(100% - ${NAV.W_DASHBOARD_MINI}px)`,
            }),
          }),

        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  );
}
