/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
// ----------------------------------------------------------------------
const initialState = {
  order_stats: {
    order_completed: {
      order_completed: 0,
      order_completed_count: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      order_completed_mom_percentage: 0,
    },
    order_in_progress: {
      order_in_progress: 0,
      order_in_progress_count: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      order_in_progress_mom_percentage: 0,
    },
    order_cancelled: {
      order_cancelled: 0,
      order_cancelled_count: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      order_cancelled_mom_percentage: 0,
    },
  },
};

const slice = createSlice({
  name: 'order_stats',
  initialState,
  reducers: {
    setOrderStats: (state, action) => {
      state.order_stats.order_completed.order_completed = action.payload?.[0]?.order_completed;
      state.order_stats.order_completed.order_completed_count =
        action.payload?.[0]?.order_completed_count;
      state.order_stats.order_completed.order_completed_mom_percentage =
        action.payload?.[0]?.order_completed_mom_percentage;
      state.order_stats.order_in_progress.order_in_progress =
        action.payload?.[1]?.order_in_progress;
      state.order_stats.order_in_progress.order_in_progress_count =
        action.payload?.[1]?.order_in_progress_count;
      state.order_stats.order_in_progress.order_in_progress_mom_percentage =
        action.payload?.[1]?.order_in_progress_mom_percentage;
      state.order_stats.order_cancelled.order_cancelled = action.payload?.[2]?.order_cancelled;
      state.order_stats.order_cancelled.order_cancelled_count =
        action.payload?.[2]?.order_cancelled_count;
      state.order_stats.order_cancelled.order_cancelled_mom_percentage =
        action.payload?.[2]?.order_cancelled_mom_percentage;
    },
  },
});

// Reducer
export default slice.reducer;
export const { setOrderStats } = slice.actions;
