import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
export const CreateNewPasswordPage = Loadable(lazy(() => import('../pages/auth/CreateNewPassword')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));

// DASHBOARD: GENERAL
export const GeneralAppPage = Loadable(lazy(() => import('../pages/dashboard/GeneralAppPage')));

export const ServicePage = Loadable(lazy(() => import('../pages/dashboard/Service')));

export const WaitingOffersPage = Loadable(
  lazy(() => import('../pages/dashboard/WaitingOffersPage'))
);

export const ExpensesPage = Loadable(lazy(() => import('../pages/dashboard/ExpensesPage')));

// DASHBOARD: ECOMMERCE
export const EcommerceCheckoutPage = Loadable(
  lazy(() => import('../pages/dashboard/EcommerceCheckoutPage'))
);

// DASHBOARD: USER
export const UserAccountPage = Loadable(lazy(() => import('../pages/dashboard/UserAccountPage')));
export const ChangePasswordPage = Loadable(lazy(() => import('../pages/ChangePasswordPage/ChangePasswordPage')));

// TEST RENDER PAGE BY ROLE
export const PermissionDeniedPage = Loadable(
  lazy(() => import('../pages/dashboard/PermissionDeniedPage'))
);

// BLANK PAGE
export const BlankPage = Loadable(lazy(() => import('../pages/dashboard/BlankPage')));

// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const HomePage = Loadable(lazy(() => import('../pages/HomePage')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));

//
export const OrderDetailsPage = Loadable(lazy(() => import('../pages/dashboard/OrderDetailsPage')));
export const ClientOrderDetails = Loadable(
  lazy(() => import('../pages/clientOrders/ClientOrderDetails'))
);
export const OffersPage = Loadable(
  lazy(() => import('../pages/serviceProvider/offersPage/offersPage'))
);
export const ExpenseDetailsPage = Loadable(
  lazy(() => import('../pages/serviceProvider/expenseDetails/expenseDetailsPage'))
);
export const ClientServicesPage = Loadable(
  lazy(() => import('../pages/clientServices/ClientServicesPage'))
);
export const ClientCreateOrderPage = Loadable(
  lazy(() => import('../pages/clientCreateOrderPage/ClientCreateOrderPage'))
);
export const ClientReviewOrderPage = Loadable(
  lazy(() => import('../pages/clientReviewOrderPage/ClientReviewOrderPage'))
);
export const ClientCreateSeaOrderPage = Loadable(
  lazy(() => import('../pages/clientCreateSeaOrderPage/ClientCreateSeaOrderPage'))
);
export const ClientCreateLandOrderPage = Loadable(
  lazy(() => import('../pages/clientCreateLandOrderPage/ClientCreateLandOrderPage'))
);
export const ClientListOrdersPage = Loadable(
  lazy(() => import('../pages/clientListOrdersPage/ClientListOrdersPage'))
);
export const ClientOrderReviewDetailsPage = Loadable(
  lazy(() => import('../pages/clientOrderReviewDetailsPage/ClientOrderReviewDetailsPage'))
);

export const AdminOrdersPage = Loadable(lazy(() => import('../pages/adminOrders/AdminOrders')));
export const AdminCreateUserPage = Loadable(
  lazy(() => import('../pages/adminUserManagement/createUser/AdminCreateUser'))
);
export const AdminCreateGroupPage = Loadable(
  lazy(() => import('../pages/adminUserManagement/createGroup/AdminCreateGroup'))
);
export const AdminCreatePermissionPage = Loadable(
  lazy(() => import('../pages/adminUserManagement/createPermission/AdminCreatePermission'))
);
export const AdminOffersPage = Loadable(lazy(() => import('../pages/adminOrders/AdminOffers')));
export const AdminOrderDetailsPage = Loadable(
  lazy(() => import('../pages/adminOrders/AdminOrderDetails'))
);
export const AdminRegisterPage = Loadable(
  lazy(() => import('../pages/adminRegister/AdminRegister'))
);

export const ServiceRegionsSpPage = Loadable(
  lazy(() => import('../pages/serviceRegionsSP/ServiceRegionsSp'))
);

export const SupportPage = Loadable(lazy(() => import('../pages/supportPage/SupportPage')));

export const ChatPage = Loadable(lazy(() => import('../pages/ChatPage/ChatPage')));

export const AdminSupportPage = Loadable(
  lazy(() => import('../pages/AdminSupportPage/AdminSupportPage'))
);

export const AccountPage = Loadable(
  lazy(() => import('../pages/AccountPage/AccountPage'))
);
