/* eslint-disable */
import axios from 'axios';
// config
import { HOST_API_KEY } from '../config-global';
import { PATH_AUTH } from 'src/routes/paths';
// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API_KEY });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      if (window.location.href.includes('/login')) {
        localStorage.clear();
      } else {
        window.location.href = PATH_AUTH.login;
        localStorage.clear();
      }
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default axiosInstance;
