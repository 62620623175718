/* eslint-disable */

import { Box, Button, Checkbox, Chip, Link } from '@mui/material';
import { differenceInSeconds } from 'date-fns';
import { nanoid } from 'nanoid';
import React from 'react';
import Iconify from 'src/components/iconify/Iconify';
import Swal from 'sweetalert2';

export const getServiceName = (id, translate) => {
  switch (id) {
    case 1:
      return translate('Customs_Clearance');
      break;
    case 2:
      return translate('Sea_Shipping');
      break;
    case 3:
      return translate('Local_Land_Transportation');
      break;
    case 4:
      return translate('custom_local_transport');
      break;
    case 5:
      return translate('local_custom_clearacnce');
      break;
  }
};

export const getServiceDescription = (id, translate) => {
  switch (id) {
    case 1:
      return translate('Handle_the_Custom_clearance_process_and_documentation');
      break;
    case 2:
      return translate('Provide_Large_Scale_Shipping_from_Overseas');
      break;
    case 3:
      return translate('Provide_All_kinds_of_transportation_in_KSA');
      break;
  }
};

export const orderStatus = (status, translate) => {
  switch (status) {
    case 'draft':
      return translate('draft');
      break;
    case 'wait_bids':
      return translate('wait_bids');
      break;
    case 'wait_mod':
      return translate('wait_user_accept');
      break;
    case 'accepted':
      return translate('accepted');
      break;
    case 'rejected':
      return translate('rejected');
      break;
    case 'auto_rejected':
      return translate('auto_rejected');
      break;
    case 'auto_reject':
      return translate('auto_rejected');
      break;
    case 'pending':
      return translate('pending');
      break;
    case 'confirmed':
      return translate('confirmed');
      break;
    case 'wait_user_accept':
      return translate('wait_user_accept');
      break;
    case 'in_progress':
      return translate('in_progress');
      break;
    case 'completed':
      return translate('completed');
      break;
    case 'cancelled':
      return translate('cancelled');
      break;
    case 'autorejected':
      return translate('auto_rejected');
      break;
    case 'wait_payment':
      return translate('wait_payment');
      break;
    case 'resolving_issue':
      return translate('resolving_issue');
      break;
    case 'pending_completion':
      return translate('pending_completion');
      break;
    case 'open':
      return translate('open');
      break;
    case 'delayed':
      return translate('delayed');
      break;

    case 'delayed_waiting_client_inputs':
      return translate('delayed_waiting_client_inputs');
      break;
    case 'on_hold':
      return translate('on_hold');
      break;
    case 'resolved':
      return translate('resolved');
      break;
    case 'unresolved':
      return translate('un_resolved');
      break;
    case 'wait_initial_payment':
      return translate('wait_initial_payment');
      break;
    case 'wait_final_payment':
      return translate('wait_final_payment');
      break;

    case 'order_missed':
      return translate('order_missed');
      break;

    case 'await_user_completion':
      return translate('await_user_completion');
      break;
    case 'wait_waredat_payment':
      return translate('wait_waredat_payment');
      break;

    case 'await_user_payment_confirmation':
      return translate('await_user_payment_confirmation');
      break;
    case 'cancelled_by_client':
      return translate('cancelled_by_client');
      break;
    case 'cancelled_by_sp':
      return translate('cancelled_by_sp');
      break;
    case 'have_issue':
      return translate('have_issue');
      break;
    default:
      return `${status}`;
  }
};

export const orderColor = (status) => {
  switch (status) {
    case 'wait_mod':
      return { backgroundColor: '#FEE4A0', color: '#644444' };
      break;
    case 'wait_bids':
      return { backgroundColor: '#FEE4A0', color: '#644444' };
      break;
    case 'wait_initial_payment':
      return { backgroundColor: '#FEE4A0', color: '#644444' };
      break;
    case 'have_issue':
      return { backgroundColor: '#FEE4A0', color: '#644444' };
      break;
    case 'resolving_issue':
      return { backgroundColor: '#FEE4A0', color: '#644444' };
      break;

    case 'wait_final_payment':
      return { backgroundColor: '#FEE4A0', color: '#644444' };
      break;

    case 'await_user_completion':
      return { backgroundColor: '#FEE4A0', color: '#644444' };
      break;
    case 'await_user_payment_confirmation':
      return { backgroundColor: '#FEE4A0', color: '#644444' };
      break;
    case 'wait_waredat_payment':
      return { backgroundColor: '#FEE4A0', color: '#644444' };
      break;

    case 'order_missed':
      return { backgroundColor: 'rgba(255, 0, 0, 0.1)', color: '#FF0000' };
      break;

    case 'pending':
      return { backgroundColor: '#FEE4A0', color: '#644444' };
      break;
    case 'accepted':
      return { backgroundColor: '#B9EBD0', color: '#5C9677' };
      break;
    case 'confirmed':
      return { backgroundColor: '#B9EBD0', color: '#5C9677' };
      break;
    case 'rejected':
      return { backgroundColor: 'rgba(255, 0, 0, 0.1)', color: '#FF0000' };
      break;
    case 'auto_rejected':
      return { backgroundColor: 'rgba(255, 0, 0, 0.1)', color: '#FF0000' };
      break;
    case 'auto_reject':
      return { backgroundColor: 'rgba(255, 0, 0, 0.1)', color: '#FF0000' };
      break;
    case 'wait_user_accept':
      return { backgroundColor: '#FEE4A0', color: '#644444' };
      break;
    case 'in_progress':
      return { backgroundColor: '#B9EBD0', color: '#5C9677' };
      break;
    case 'completed':
      return { backgroundColor: '#B9EBD0', color: '#5C9677' };
      break;
    case 'cancelled':
      return { backgroundColor: 'rgba(255, 0, 0, 0.1)', color: '#FF0000' };
      break;
    case 'cancelled_by_client':
      return { backgroundColor: 'rgba(255, 0, 0, 0.1)', color: '#FF0000' };
      break;
    case 'cancelled_by_sp':
      return { backgroundColor: 'rgba(255, 0, 0, 0.1)', color: '#FF0000' };
      break;
    case 'autorejected':
      return { backgroundColor: 'rgba(255, 0, 0, 0.1)', color: '#FF0000' };
      break;
    case 'wait_payment':
      return { backgroundColor: '#FEE4A0', color: '#644444' };
      break;
    case 'pending_completion':
      return { backgroundColor: '#FEE4A0', color: '#644444' };
      break;
    case 'open':
      return { backgroundColor: '#B9EBD0', color: '#5C9677' };
      break;
    case 'delayed':
      return { backgroundColor: '#FEE4A0', color: '#644444' };
      break;
    case 'delayed_waiting_client_inputs':
      return { backgroundColor: '#FEE4A0', color: '#644444' };
      break;
    case 'on_hold':
      return { backgroundColor: '#FEE4A0', color: '#644444' };
      break;
    case 'resolved':
      return { backgroundColor: '#B9EBD0', color: '#5C9677' };
      break;
    case 'unresolved':
      return { backgroundColor: 'rgba(255, 0, 0, 0.1)', color: '#FF0000' };
      break;
    default:
      return {};
  }
};

export const renderSpan = (key, value, translate, transportType) => {
  switch (key) {
    case 'expected_weight':
      return (
        <span className="value text-black-06">{`${value.toString()} (${
          transportType === 'Local Land Transportation' ? translate('kilogram') : translate('kg')
        })`}</span>
      );
      break;
    case 'temperature':
      return <span className="value text-black-06">{`${value.toString()} °C`}</span>;
      break;
    case 'humidity':
      return <span className="value text-black-06">{`${value.toString()} %`}</span>;
      break;
    case 'hazardous':
      return <Checkbox checked={value} />;
      break;
    case 'gps_coord':
      return (
        <Link
          href={`http://www.google.com/maps/place/${value?.gps_coord_lat},${value?.gps_coord_lng}`}
          target="_blank"
          rel="noreferrer"
        >
          <Button variant="contained">{translate('gps_coord')}</Button>
        </Link>
      );
      break;

    case 'pickup_address':
      return (
        <Link
          href={`http://www.google.com/maps/place/${value?.gps_lat},${value?.gps_lng}`}
          target="_blank"
          rel="noreferrer"
        >
          <Button startIcon={<Iconify icon="grommet-icons:map" width={21} />} variant="contained">
            {translate('location')}
          </Button>
        </Link>
      );
      break;

    case 'hazard':
      return <Checkbox checked={value} />;
      break;
    case 'saber':
      return <Checkbox checked={value} />;
      break;
    case 'height':
      return <span className="value text-black-06">{`${value.toString()} (cm)`}</span>;
      break;
    case 'width':
      return <span className="value text-black-06">{`${value.toString()} (cm)`}</span>;
      break;
    case 'length':
      return <span className="value text-black-06">{`${value.toString()} (cm)`}</span>;
      break;
    case 'from':
      return transportType === 'Local Land Transportation' ? (
        <Link
          href={`http://www.google.com/maps/place/${value?.lat},${value?.lng}`}
          target="_blank"
          rel="noreferrer"
        >
          <Button variant="contained">{translate('gps_coord')}</Button>
        </Link>
      ) : (
        <span className="value text-black-06">{value.toString()}</span>
      );
      break;
    case 'to':
      return transportType === 'Local Land Transportation' ? (
        <Link
          href={`http://www.google.com/maps/place/${value?.lat},${value?.lng}`}
          target="_blank"
          rel="noreferrer"
        >
          <Button variant="contained">{translate('gps_coord')}</Button>
        </Link>
      ) : (
        <span className="value text-black-06">{value.toString()}</span>
      );
      break;
    case 'local_transportation':
      return <Checkbox checked={value} />;
      break;
    case 'Land_Transportation':
      return <Checkbox checked={value} />;
      break;

    case 'commodity':
      return (
        <Box sx={{ textAlign: 'end' }}>
          {value?.map((e, i) => (
            <React.Fragment key={nanoid()}>
              <span key={i} className="value text-black-06">
                {e}
              </span>
              <br />
            </React.Fragment>
          ))}
        </Box>
      );
      break;
    default:
      return <span className="value text-black-06">{value.toString()}</span>;
  }
};

export const getTransportTypeIcon = (transport_type) => {
  switch (transport_type) {
    case 'Sea':
      return 'iconoir:sea-waves';
      break;
    case 'Air':
      return 'mdi:local-airport';
      break;
    case 'Land':
      return 'mdi:farm-home-outline';
      break;
  }
};

export const getTransportTypeOrigin = (transport_type) => {
  switch (transport_type) {
    case 'Sea':
      return 'Origin Sea Port ';
      break;
    case 'Air':
      return 'Origin Airport';
      break;
    case 'Land':
      return 'Origin Land Port';
      break;
  }
};

export const getServiceLicence = (id, translate) => {
  switch (id) {
    case 1:
      return translate('custom_clearance_licence');
      break;
    case 2:
      return translate('freight_fowarder_broker_license');
      break;
    case 3:
      return translate('road_freight_transport_licence');
      break;
    case 4:
      return translate('port_licence');
      break;
    default:
      return 'Licence';
  }
};

export const orderFilesColor = (status) => {
  switch (status) {
    case 'read':
      return <Chip className="chip-green-c fw-500" label={status} />;
      break;
    case 'accepted':
      return <Chip className="chip-green-c fw-500" label={status} />;
      break;
    case 'rejected':
      return (
        <Chip
          className="chip-red-c fw-500"
          icon={<Iconify icon="basil:cross-solid" color="#FF5252" width={26} />}
          label={status}
        />
      );
      break;
    case 'pending':
      return (
        <Chip
          className="chip-yellow-c fw-500"
          icon={<Iconify icon="ei:clock" color="#ba8216" width={26} />}
          label={status}
        />
      );
      break;
    case 'unread':
      return <Chip className="chip-gray-c fw-500" label={status} />;
      break;
    default:
      return <Chip className="chip-gray-c fw-500" label={status} />;
  }
};

export const orderFileNames = (file, translate) => {
  switch (file.file_template_id) {
    case 8:
      return translate('draft_bl');
      break;
    case 10:
      return translate('final_bl');
      break;
    case 11:
      return translate('final_service_invoice');
      break;
    case 12:
      return translate('bill_of_lading');
      break;
    case 13:
      return translate('purchase_invoice_goods');
      break;
    case 14:
      return translate('saber_certificate');
      break;
    case 15:
      return translate('customs_release_doc');
      break;
    case 16:
      return translate('final_service_invoice');
      break;
    case 17:
      return translate('final_service_invoice');
      break;
    case 18:
      return translate('customs_release_doc');
      break;
    case 19:
      return translate('final_invoice');
      break;
    case 20:
      return translate('final_invoice');
      break;
    case 21:
      return translate('final_invoice');
      break;
    case 22:
      return translate('initial_invoice');
      break;
    case 23:
      return translate('initial_invoice');
      break;
    case 24:
      return translate('initial_invoice');
      break;
    case 25:
      return translate('packing_list');
      break;
    case 26:
      return translate('transfer_receipt_shipping');
      break;
    case 27:
      return translate('transfer_receipt_shipping');
      break;
    case 28:
      return translate('transfer_receipt_shipping');
      break;
    case 29:
      return translate('transfer_receipt_custom_final');
      break;
    case 30:
      return translate('transfer_receipt_custom_final');
      break;
    case 31:
      return translate('transfer_receipt_shipping_final');
      break;
    default:
      return translate(`${file?.name_from_user}`);
  }
};

export const getStatusChip = (status, translate) => {
  switch (status) {
    case 'confirmed':
      return (
        <Chip
          className="chip-green-c"
          icon={<Iconify icon="fluent:checkmark-16-filled" color="green" width={26} />}
          label={translate('confirmed')}
        />
      );
      break;
    case 'wait_mod':
      return (
        <Chip
          className="chip-yellow-c"
          icon={<Iconify icon="ei:clock" color="#ba8216" width={26} />}
          label={translate('Waiting_Offers')}
        />
      );
      break;
    case 'have_issue':
      return (
        <Chip
          className="chip-yellow-c"
          icon={<Iconify icon="zondicons:exclamation-outline" color="#ba8216" width={26} />}
          label={translate('have_issue')}
        />
      );
      break;
    case 'draft':
      return (
        <Chip
          className="chip-yellow-c"
          icon={<Iconify icon="ei:clock" color="#ba8216" width={26} />}
          label={translate('draft')}
        />
      );
      break;
    case 'wait_bids':
      return (
        <Chip
          className="chip-yellow-c"
          icon={<Iconify icon="ei:clock" color="#ba8216" width={26} />}
          label={translate('wait_bids')}
        />
      );
      break;

    case 'resolving_issue':
      return (
        <Chip
          className="chip-yellow-c"
          icon={<Iconify icon="ei:clock" color="#ba8216" width={26} />}
          label={translate('resolving_issue')}
        />
      );
      break;

    case 'wait_waredat_payment':
      return (
        <Chip
          className="chip-yellow-c"
          icon={<Iconify icon="ei:clock" color="#ba8216" width={26} />}
          label={translate('wait_waredat_payment')}
        />
      );
      break;
    case 'wait_user_accept':
      return (
        <Chip
          className="chip-yellow-c"
          icon={<Iconify icon="ei:clock" color="#ba8216" width={26} />}
          label={translate('wait_user_accept')}
        />
      );
      break;
    case 'wait_payment':
      return (
        <Chip
          className="chip-yellow-c"
          icon={<Iconify icon="ei:clock" color="#ba8216" width={26} />}
          label={translate('wait_payment')}
        />
      );
      break;

    case 'wait_initial_payment':
      return (
        <Chip
          className="chip-yellow-c"
          icon={<Iconify icon="ei:clock" color="#ba8216" width={26} />}
          label={translate('wait_initial_payment')}
        />
      );
      break;

    case 'await_user_payment_confirmation':
      return (
        <Chip
          className="chip-yellow-c"
          icon={<Iconify icon="ei:clock" color="#ba8216" width={26} />}
          label={translate('await_user_payment_confirmation')}
        />
      );
      break;

    case 'wait_final_payment':
      return (
        <Chip
          className="chip-yellow-c"
          icon={<Iconify icon="ei:clock" color="#ba8216" width={26} />}
          label={translate('wait_final_payment')}
        />
      );
      break;

    case 'await_user_completion':
      return (
        <Chip
          className="chip-yellow-c"
          icon={<Iconify icon="ei:clock" color="#ba8216" width={26} />}
          label={translate('await_user_completion')}
        />
      );
      break;

    case 'pending_completion':
      return (
        <Chip
          className="chip-yellow-c"
          icon={<Iconify icon="ei:clock" color="#ba8216" width={26} />}
          label={translate('pending_completion')}
        />
      );
      break;
    case 'in_progress':
      return (
        <Chip
          className="chip-green-c"
          icon={<Iconify icon="svg-spinners:3-dots-bounce" color="green" width={24} />}
          label={translate('in_progress')}
        />
      );
      break;
    case 'delayed':
      return (
        <Chip
          className="chip-yellow-c"
          icon={<Iconify icon="ei:clock" color="#ba8216" width={26} />}
          label={translate('delayed')}
        />
      );
      break;

    case 'delayed_waiting_client_inputs':
      return (
        <Chip
          className="chip-yellow-c"
          icon={<Iconify icon="ei:clock" color="#ba8216" width={26} />}
          label={translate('delayed_waiting_client_inputs')}
        />
      );
      break;
    case 'completed':
      return (
        <Chip
          className="chip-green-c"
          icon={<Iconify icon="fluent:checkmark-16-filled" color="green" width={26} />}
          label={translate('completed')}
        />
      );
      break;
    case 'cancelled':
      return (
        <Chip
          className="chip-red-c"
          icon={<Iconify icon="heroicons:x-mark-20-solid" color="red" width={26} />}
          label={translate('cancelled')}
        />
      );
      break;
    case 'cancelled_by_client':
      return (
        <Chip
          className="chip-red-c"
          icon={<Iconify icon="heroicons:x-mark-20-solid" color="red" width={26} />}
          label={translate('cancelled_by_client')}
        />
      );
      break;
    case 'cancelled_by_sp':
      return (
        <Chip
          className="chip-red-c"
          icon={<Iconify icon="heroicons:x-mark-20-solid" color="red" width={26} />}
          label={translate('cancelled_by_sp')}
        />
      );
      break;
    case 'autorejected':
      return (
        <Chip
          className="chip-red-c"
          icon={<Iconify icon="heroicons:x-mark-20-solid" color="red" width={26} />}
          label={translate('auto_rejected')}
        />
      );
      break;

    case 'order_missed':
      return (
        <Chip
          className="chip-red-c"
          icon={<Iconify icon="heroicons:x-mark-20-solid" color="red" width={26} />}
          label={translate('order_missed')}
        />
      );
      break;

    default:
      return (
        <Chip
          className="chip-yellow-c"
          icon={<Iconify icon="ei:clock" color="#ba8216" width={26} />}
          label={status}
        />
      );
  }
};

export const offerStatus = (status, translate) => {
  switch (status) {
    case 'draft':
      return translate('draft');
      break;
    case 'wait_bids':
      return translate('wait_bids');
      break;
    case 'wait_mod':
      return translate('wait_user_accept');
      break;
    case 'accepted':
      return translate('accepted');
      break;
    case 'rejected':
      return translate('rejected');
      break;
    case 'auto_rejected':
      return translate('auto_rejected');
      break;
    case 'auto_reject':
      return translate('auto_rejected');
      break;
    case 'pending':
      return translate('pending');
      break;
    default:
      return `${status}`;
  }
};

export const offerColor = (status) => {
  switch (status) {
    case 'wait_mod':
      return { backgroundColor: '#FEE4A0', color: '#644444' };
      break;
    case 'pending':
      return { backgroundColor: '#FEE4A0', color: '#644444' };
      break;
    case 'accepted':
      return { backgroundColor: '#B9EBD0', color: '#5C9677' };
      break;
    case 'rejected':
      return { backgroundColor: 'rgba(255, 0, 0, 0.1)', color: '#FF0000' };
      break;
    case 'auto_rejected':
      return { backgroundColor: 'rgba(255, 0, 0, 0.1)', color: '#FF0000' };
      break;
    case 'auto_reject':
      return { backgroundColor: 'rgba(255, 0, 0, 0.1)', color: '#FF0000' };
      break;
    default:
      return {};
  }
};

export const tooltipMessage = (status, translate) => {
  switch (status) {
    case 'draft':
      return translate('draft');
      break;
    case 'wait_bids':
      return translate('wait_bids');
      break;
    case 'wait_mod':
      return translate('wait_user_accept');
      break;
    case 'accepted':
      return translate('accepted');
      break;
    case 'rejected':
      return translate('rejected');
      break;
    case 'auto_rejected':
      return translate('auto_rejected');
      break;
    case 'auto_reject':
      return translate('auto_rejected_tooltip');
      break;
    case 'autorejected':
      return translate('auto_rejected_tooltip');
      break;
    case 'pending':
      return translate('pending');
      break;
    default:
      return `${status}`;
  }
};

export const permissions = {
  view_client_sp: 'view_client_sp',
  register_client_sp: 'register_client_sp',
  deactivate_client_sp: 'deactivate_client_sp',
  deactivate_client: 'deactivate_client',
  deactivate_sp: 'deactivate_sp',
  activate_client_sp: 'activate_client_sp',
  activate_client: 'activate_client',
  activate_sp: 'activate_sp',
  view_service_regions: 'view_service_regions',
  view_documents: 'view_documents',
  approve_region: 'approve_region',
  reject_region: 'reject_region',
  view_account_details: 'view_account_details',
  accept_vat_file: 'accept_vat_file',
  reject_vat_file: 'reject_vat_file',
  accept_national_address_file: 'accept_national_address_file',
  reject_national_address_file: 'reject_national_address_file',

  view_user_management: 'view_user_management',
  view_users: 'view_users',
  view_groups: 'view_groups',
  create_user: 'create_user',
  add_user_to_group: 'add_user_to_group',
  remove_user_from_group: 'remove_user_from_group',
  add_permissions_to_user: 'add_permissions_to_user',
  remove_permissions_from_user: 'remove_permissions_from_user',
  view_user_groups: 'view_user_groups',
  approve_client_offer_reject_note: 'approve_client_offer_reject_note',
  reject_client_offer_reject_note: 'reject_client_offer_reject_note',

  create_group: 'create_group',
  add_permission_to_group: 'add_permission_to_group',
  remove_permission_from_group: 'remove_permission_from_group',
  view_permissions_of_group: 'view_permissions_of_group',
  update_group_details: 'update_group_details',
  delete_group: 'delete_group',

  view_orders: 'view_orders',
  view_offers: 'view_offers',
  view_offer_details: 'view_offer_details',
  view_order_details: 'view_order_details',
  flag_order: 'flag_order',
  view_flag: 'view_flag',
  remove_flag: 'remove_flag',
  issue_initial_invoice: 'issue_initial_invoice',
  issue_final_invoice: 'issue_final_invoice',
  confirm_order: 'confirm_order',
  complete_order: 'complete_order',
  cleared_payment: 'cleared_payment',
  client_payment_confirmation: 'client_payment_confirmation',
  reject_cancel_order_request: 'reject_cancel_order_request',
  approve_cancel_order_request: 'approve_cancel_order_request',
  reject_offer: 'reject_offer',
  have_issue: 'have_issue',
  resolving_issue: 'resolving_issue',
  resolved_issue: 'resolved_issue',

  view_support: 'view_support',
  view_support_images: 'view_support_images',
  set_support_ticket_status: 'set_support_ticket_status',
  chat_permission: 'chat_permission',
};

export const checkPermissionClientSpPage = (user) => {
  if (user?.is_superuser) {
    return true;
  } else {
    let register_permissions = {
      register_client_sp: false,
      deactivate_client_sp: false,
      deactivate_client: false,
      deactivate_sp: false,
      activate_client_sp: false,
      activate_client: false,
      activate_sp: false,
      view_client_sp: false,
      view_service_regions: false,
      view_documents: false,
      approve_region: false,
      reject_region: false,
      view_account_details: false,
      accept_vat_file: false,
      reject_vat_file: false,
      accept_national_address_file: false,
      reject_national_address_file: false,
    };

    if (user?.groups?.length > 0) {
      user?.groups?.some((e) => {
        [...user?.user_permission, ...e?.permissions]?.forEach((x) => {
          if (x?.name === permissions.view_client_sp) {
            register_permissions.view_client_sp = true;
          }
          if (x?.name === permissions.register_client_sp) {
            register_permissions.register_client_sp = true;
          }
          if (x?.name === permissions.deactivate_client_sp) {
            register_permissions.deactivate_client_sp = true;
          }
          if (x?.name === permissions.deactivate_client) {
            register_permissions.deactivate_client = true;
          }
          if (x?.name === permissions.deactivate_sp) {
            register_permissions.deactivate_sp = true;
          }
          if (x?.name === permissions.activate_client_sp) {
            register_permissions.activate_client_sp = true;
          }
          if (x?.name === permissions.activate_client) {
            register_permissions.activate_client = true;
          }
          if (x?.name === permissions.activate_sp) {
            register_permissions.activate_sp = true;
          }
          if (x?.name === permissions.view_service_regions) {
            register_permissions.view_service_regions = true;
          }
          if (x?.name === permissions.view_documents) {
            register_permissions.view_documents = true;
          }
          if (x?.name === permissions.approve_region) {
            register_permissions.approve_region = true;
          }
          if (x?.name === permissions.reject_region) {
            register_permissions.reject_region = true;
          }

          if (x?.name === permissions.view_account_details) {
            register_permissions.view_account_details = true;
          }

          if (x?.name === permissions.accept_vat_file) {
            register_permissions.accept_vat_file = true;
          }

          if (x?.name === permissions.reject_vat_file) {
            register_permissions.reject_vat_file = true;
          }

          if (x?.name === permissions.accept_national_address_file) {
            register_permissions.accept_national_address_file = true;
          }

          if (x?.name === permissions.reject_national_address_file) {
            register_permissions.reject_national_address_file = true;
          }
        });
      });
    } else {
      if (user?.user_permission?.length > 0) {
        [...user?.user_permission]?.forEach((x) => {
          if (x?.name === permissions.view_client_sp) {
            register_permissions.view_client_sp = true;
          }
          if (x?.name === permissions.register_client_sp) {
            register_permissions.register_client_sp = true;
          }
          if (x?.name === permissions.deactivate_client_sp) {
            register_permissions.deactivate_client_sp = true;
          }
          if (x?.name === permissions.deactivate_client) {
            register_permissions.deactivate_client = true;
          }
          if (x?.name === permissions.deactivate_sp) {
            register_permissions.deactivate_sp = true;
          }
          if (x?.name === permissions.activate_client_sp) {
            register_permissions.activate_client_sp = true;
          }
          if (x?.name === permissions.activate_client) {
            register_permissions.activate_client = true;
          }
          if (x?.name === permissions.activate_sp) {
            register_permissions.activate_sp = true;
          }
          if (x?.name === permissions.view_service_regions) {
            register_permissions.view_service_regions = true;
          }
          if (x?.name === permissions.view_documents) {
            register_permissions.view_documents = true;
          }
          if (x?.name === permissions.approve_region) {
            register_permissions.approve_region = true;
          }
          if (x?.name === permissions.reject_region) {
            register_permissions.reject_region = true;
          }
          if (x?.name === permissions.view_account_details) {
            register_permissions.view_account_details = true;
          }

          if (x?.name === permissions.accept_vat_file) {
            register_permissions.accept_vat_file = true;
          }

          if (x?.name === permissions.reject_vat_file) {
            register_permissions.reject_vat_file = true;
          }

          if (x?.name === permissions.accept_national_address_file) {
            register_permissions.accept_national_address_file = true;
          }

          if (x?.name === permissions.reject_national_address_file) {
            register_permissions.reject_national_address_file = true;
          }
        });
      }
    }

    return register_permissions;
  }
};

export const checkPermissionUserPage = (user) => {
  if (user?.is_superuser) {
    return true;
  } else {
    let register_permissions = {
      create_user: false,
      add_user_to_group: false,
      remove_user_from_group: false,
      add_permission_to_user: false,
      remove_permission_from_user: false,
      view_user_groups: false,
      view_user_page: false,
    };

    if (user?.groups?.length > 0) {
      user?.groups?.some((e) => {
        [...user?.user_permission, ...e?.permissions]?.forEach((x) => {
          if (x?.name === permissions.view_user_management || x?.name === permissions.view_users) {
            register_permissions.view_user_page = true;
          }
          if (x?.name === permissions.create_user) {
            register_permissions.create_user = true;
          }
          if (x?.name === permissions.add_user_to_group) {
            register_permissions.add_user_to_group = true;
          }
          if (x?.name === permissions.remove_user_from_group) {
            register_permissions.remove_user_from_group = true;
          }
          if (x?.name === permissions.add_permissions_to_user) {
            register_permissions.add_permission_to_user = true;
          }
          if (x?.name === permissions.remove_permissions_from_user) {
            register_permissions.remove_permission_from_user = true;
          }
          if (x?.name === permissions.view_user_groups) {
            register_permissions.view_user_groups = true;
          }
        });
      });
    } else {
      if (user?.user_permission?.length > 0) {
        [...user?.user_permission]?.forEach((x) => {
          if (x?.name === permissions.view_user_management || x?.name === permissions.view_users) {
            register_permissions.view_user_page = true;
          }
          if (x?.name === permissions.create_user) {
            register_permissions.create_user = true;
          }
          if (x?.name === permissions.add_user_to_group) {
            register_permissions.add_user_to_group = true;
          }
          if (x?.name === permissions.remove_user_from_group) {
            register_permissions.remove_user_from_group = true;
          }
          if (x?.name === permissions.add_permissions_to_user) {
            register_permissions.add_permission_to_user = true;
          }
          if (x?.name === permissions.remove_permissions_from_user) {
            register_permissions.remove_permission_from_user = true;
          }
          if (x?.name === permissions.view_user_groups) {
            register_permissions.view_user_groups = true;
          }
        });
      }
    }

    return register_permissions;
  }
};

export const checkPermissionGroupsPage = (user) => {
  if (user?.is_superuser) {
    return true;
  } else {
    let register_permissions = {
      create_group: false,
      add_permissions_to_group: false,
      remove_permissions_from_group: false,
      view_permissions_of_group: false,
      update_group_details: false,
      delete_group: false,
      view_group_page: false,
    };

    if (user?.groups?.length > 0) {
      user?.groups?.some((e) => {
        [...user?.user_permission, ...e?.permissions]?.forEach((x) => {
          if (x?.name === permissions.view_user_management || x?.name === permissions.view_groups) {
            register_permissions.view_group_page = true;
          }
          if (x?.name === permissions.create_group) {
            register_permissions.create_group = true;
          }
          if (x?.name === permissions.add_permission_to_group) {
            register_permissions.add_permissions_to_group = true;
          }
          if (x?.name === permissions.remove_permission_from_group) {
            register_permissions.remove_permissions_from_group = true;
          }
          if (x?.name === permissions.view_permissions_of_group) {
            register_permissions.view_permissions_of_group = true;
          }
          if (x?.name === permissions.update_group_details) {
            register_permissions.update_group_details = true;
          }
          if (x?.name === permissions.delete_group) {
            register_permissions.delete_group = true;
          }
        });
      });
    } else {
      if (user?.user_permission?.length > 0) {
        [...user?.user_permission]?.forEach((x) => {
          if (x?.name === permissions.view_user_management || x?.name === permissions.view_groups) {
            register_permissions.view_group_page = true;
          }
          if (x?.name === permissions.create_group) {
            register_permissions.create_group = true;
          }
          if (x?.name === permissions.add_permission_to_group) {
            register_permissions.add_permissions_to_group = true;
          }
          if (x?.name === permissions.remove_permission_from_group) {
            register_permissions.remove_permissions_from_group = true;
          }
          if (x?.name === permissions.view_permissions_of_group) {
            register_permissions.view_permissions_of_group = true;
          }
          if (x?.name === permissions.update_group_details) {
            register_permissions.update_group_details = true;
          }
          if (x?.name === permissions.delete_group) {
            register_permissions.delete_group = true;
          }
        });
      }
    }

    return register_permissions;
  }
};

export const checkPermissionOrdersPage = (user) => {
  if (user?.is_superuser) {
    return true;
  } else {
    let register_permissions = {
      view_offers: false,
      view_offer_details: false,
      view_order_details: false,
      flag_order: false,
      view_flag: false,
      remove_flag: false,
      issue_initial_invoice: false,
      issue_final_invoice: false,
      complete_order: false,
      confirm_order: false,
      view_orders_page: false,
      approve_client_offer_reject_note: false,
      reject_client_offer_reject_note: false,
      cleared_payment: false,
      client_payment_confirmation: false,
      reject_offer: false,
      approve_cancel_order_request: false,
      reject_cancel_order_request: false,
      have_issue: false,
      resolving_issue: false,
      resolved_issue: false,
    };

    if (user?.groups?.length > 0) {
      user?.groups?.some((e) => {
        [...user?.user_permission, ...e?.permissions]?.forEach((x) => {
          if (x?.name === permissions.view_orders) {
            register_permissions.view_orders_page = true;
          }
          if (x?.name === permissions.view_offers) {
            register_permissions.view_offers = true;
          }
          if (x?.name === permissions.view_offer_details) {
            register_permissions.view_offer_details = true;
          }
          if (x?.name === permissions.view_order_details) {
            register_permissions.view_order_details = true;
          }
          if (x?.name === permissions.flag_order) {
            register_permissions.flag_order = true;
          }
          if (x?.name === permissions.view_flag) {
            register_permissions.view_flag = true;
          }
          if (x?.name === permissions.remove_flag) {
            register_permissions.remove_flag = true;
          }
          if (x?.name === permissions.issue_initial_invoice) {
            register_permissions.issue_initial_invoice = true;
          }
          if (x?.name === permissions.issue_final_invoice) {
            register_permissions.issue_final_invoice = true;
          }
          if (x?.name === permissions.confirm_order) {
            register_permissions.confirm_order = true;
          }
          if (x?.name === permissions.complete_order) {
            register_permissions.complete_order = true;
          }
          if (x?.name === permissions.approve_client_offer_reject_note) {
            register_permissions.approve_client_offer_reject_note = true;
          }

          if (x?.name === permissions.reject_client_offer_reject_note) {
            register_permissions.reject_client_offer_reject_note = true;
          }

          if (x?.name === permissions.cleared_payment) {
            register_permissions.cleared_payment = true;
          }
          if (x?.name === permissions.client_payment_confirmation) {
            register_permissions.client_payment_confirmation = true;
          }
          if (x?.name === permissions.reject_offer) {
            register_permissions.reject_offer = true;
          }
          if (x?.name === permissions.approve_cancel_order_request) {
            register_permissions.approve_cancel_order_request = true;
          }
          if (x?.name === permissions.reject_cancel_order_request) {
            register_permissions.reject_cancel_order_request = true;
          }
          if (x?.name === permissions.have_issue) {
            register_permissions.have_issue = true;
          }
          if (x?.name === permissions.resolving_issue) {
            register_permissions.resolving_issue = true;
          }
          if (x?.name === permissions.resolved_issue) {
            register_permissions.resolved_issue = true;
          }
        });
      });
    } else {
      if (user?.user_permission?.length > 0) {
        [...user?.user_permission]?.forEach((x) => {
          if (x?.name === permissions.view_orders) {
            register_permissions.view_orders_page = true;
          }
          if (x?.name === permissions.view_offers) {
            register_permissions.view_offers = true;
          }
          if (x?.name === permissions.view_offer_details) {
            register_permissions.view_offer_details = true;
          }
          if (x?.name === permissions.view_order_details) {
            register_permissions.view_order_details = true;
          }
          if (x?.name === permissions.flag_order) {
            register_permissions.flag_order = true;
          }
          if (x?.name === permissions.view_flag) {
            register_permissions.view_flag = true;
          }
          if (x?.name === permissions.remove_flag) {
            register_permissions.remove_flag = true;
          }
          if (x?.name === permissions.issue_initial_invoice) {
            register_permissions.issue_initial_invoice = true;
          }
          if (x?.name === permissions.issue_final_invoice) {
            register_permissions.issue_final_invoice = true;
          }
          if (x?.name === permissions.confirm_order) {
            register_permissions.confirm_order = true;
          }
          if (x?.name === permissions.complete_order) {
            register_permissions.complete_order = true;
          }
          if (x?.name === permissions.approve_client_offer_reject_note) {
            register_permissions.approve_client_offer_reject_note = true;
          }

          if (x?.name === permissions.reject_client_offer_reject_note) {
            register_permissions.reject_client_offer_reject_note = true;
          }

          if (x?.name === permissions.cleared_payment) {
            register_permissions.cleared_payment = true;
          }
          if (x?.name === permissions.client_payment_confirmation) {
            register_permissions.client_payment_confirmation = true;
          }
          if (x?.name === permissions.reject_offer) {
            register_permissions.reject_offer = true;
          }
          if (x?.name === permissions.approve_cancel_order_request) {
            register_permissions.approve_cancel_order_request = true;
          }
          if (x?.name === permissions.reject_cancel_order_request) {
            register_permissions.reject_cancel_order_request = true;
          }
          if (x?.name === permissions.have_issue) {
            register_permissions.have_issue = true;
          }
          if (x?.name === permissions.resolving_issue) {
            register_permissions.resolving_issue = true;
          }
          if (x?.name === permissions.resolved_issue) {
            register_permissions.resolved_issue = true;
          }
        });
      }
    }

    return register_permissions;
  }
};

export const checkPermissionSupportPage = (user) => {
  if (user?.is_superuser) {
    return true;
  } else {
    let support_permissions = {
      view_support: false,
      view_support_images: false,
      set_support_ticket_status: false,
      chat_permission: false,
    };

    if (user?.groups?.length > 0) {
      user?.groups?.some((e) => {
        [...user?.user_permission, ...e?.permissions]?.forEach((x) => {
          if (x?.name === permissions.view_support) {
            support_permissions.view_support = true;
          }

          if (x?.name === permissions.view_support_images) {
            support_permissions.view_support_images = true;
          }

          if (x?.name === permissions.set_support_ticket_status) {
            support_permissions.set_support_ticket_status = true;
          }
          if (x?.name === permissions.chat_permission) {
            support_permissions.chat_permission = true;
          }
        });
      });
    } else {
      if (user?.user_permission?.length > 0) {
        [...user?.user_permission]?.forEach((x) => {
          if (x?.name === permissions.view_support) {
            support_permissions.view_support = true;
          }
          if (x?.name === permissions.view_support_images) {
            support_permissions.view_support_images = true;
          }

          if (x?.name === permissions.set_support_ticket_status) {
            support_permissions.set_support_ticket_status = true;
          }
          if (x?.name === permissions.chat_permission) {
            support_permissions.chat_permission = true;
          }
        });
      }
    }

    return support_permissions;
  }
};

export const convertNumberToArabicNumber = (number, currentLang) => {
  return Number(number).toLocaleString(currentLang.value === 'ar' ? 'ar-EG' : 'en-US', {
    useGrouping: false,
  });
};

export const convertDateToMonthStringFormat = (dateTime, currentLang) => {
  return new Intl.DateTimeFormat(currentLang.value === 'ar' ? 'ar-EG' : 'en-GB', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(new Date(dateTime || new Date()));
};

export const convertDateToArabicDate = (date, currentLang) => {
  if (date === undefined) {
    return '';
  }

  return new Intl.DateTimeFormat(currentLang.value === 'ar' ? 'ar-EG' : 'en-GB').format(
    new Date(date)
  );
};

export const convertDateToDateString = (date, currentLang) => {
  return new Intl.DateTimeFormat(currentLang.value === 'ar' ? 'ar-EG' : 'en-US', {
    weekday: 'long',
    day: 'numeric',
    year: 'numeric',
    month: 'long',
  }).format(new Date(date || new Date()));
};

export const converTimeToArabic = (date, currentLang) => {
  return new Intl.DateTimeFormat(currentLang.value === 'ar' ? 'ar-EG' : 'en-US', {
    hour: 'numeric',
    minute: 'numeric',
  }).format(new Date(date || new Date()));
};

export const eventName = {
  deactivate_logout: 'deactivate-logout',
  order_time_over_event: 'order-time-over-event',
  order_created_event: 'order-created-event',
};

export function matchIsNumeric(text) {
  const isNumber = typeof text === 'number';
  const isString = typeof text === 'string';
  return (isNumber || (isString && text !== '')) && !isNaN(Number(text));
}

export const formatToSAR = (number, currentLang) => {
  let formatCurreny = new Intl.NumberFormat(currentLang.value === 'ar' ? 'ar-SA' : 'en-SA', {
    currency: 'SAR',
    style: 'currency',
  }).format(number);

  if (currentLang.value === 'en') {
    return formatCurreny.split('SAR')?.[1]?.trim();
  } else {
    return formatCurreny.split('ر.س.')?.[0]?.trim();
  }
};

export const getProgress = (startDate, endDate) => {
  let totalSeconds = differenceInSeconds(endDate, startDate);
  let timeRemaining = differenceInSeconds(endDate, new Date());
  return { totalSeconds, timeRemaining };
};

export const swalWarningSp = (order_id, translate) => {
  Swal.fire({
    title: translate('order_overdue'),
    text: `#${order_id} ${translate('order_time_finished_order')}`,
    icon: 'warning',
    width: '600px',
  });
};

export const swalWarningWaredat = (order_id, translate) => {
  Swal.fire({
    title: translate('order_overdue'),
    text: `#${order_id} ${translate('order_time_finished_waredat')}`,
    icon: 'warning',
    width: '700px',
  });
};

export const waredatRegionVerified = (waredat_verified, translate) => {
  switch (waredat_verified) {
    case 'pending_verification':
      return (
        <Chip
          sx={{ backgroundColor: '#FEE4A0', color: '#644444' }}
          label={translate('verification_pending')}
        />
      );
      break;
    case 'approved':
      return (
        <Chip sx={{ backgroundColor: '#B9EBD0', color: '#5C9677' }} label={translate('verified')} />
      );
      break;
    case 'rejected':
      return (
        <Chip
          sx={{ backgroundColor: 'rgba(255, 0, 0, 0.1)', color: '#FF0000' }}
          label={translate('rejected')}
        />
      );
      break;
    default:
      return (
        <Chip sx={{ backgroundColor: '#FEE4A0', color: '#644444' }} label={waredat_verified} />
      );
      break;
  }
};

export const getNavPathFromUserType = () => {
  const user_type = localStorage.getItem('userType');
  let path = '/';
  if (user_type === 'client') {
    path = `/dashboard/client/support-chat`;
  } else if (user_type === 'service_provider') {
    path = `/dashboard/service-provider/support-chat`;
  } else if (user_type === 'waredat' || user_type === 'admin') {
    path = `/dashboard/admin/support-chat`;
  }

  return path;
};

export const generateUniqueId = () => {
  return nanoid();
};

export const profileDocumetsVerfication = (status, translate) => {
  switch (status) {
    case 'pending_verification':
      return (
        <Chip
          sx={{ backgroundColor: '#FEE4A0', color: '#644444' }}
          label={translate('verification_pending')}
        />
      );
      break;
    case 'approved':
      return (
        <Chip sx={{ backgroundColor: '#B9EBD0', color: '#5C9677' }} label={translate('verified')} />
      );
      break;
    case 'rejected':
      return (
        <Chip
          sx={{ backgroundColor: 'rgba(255, 0, 0, 0.1)', color: '#FF0000' }}
          label={translate('rejected')}
        />
      );
      break;
    default:
      return <Chip sx={{ backgroundColor: '#FEE4A0', color: '#644444' }} label={status} />;
      break;
  }
};

export const orderStatesEnum = {
  wait_bids: 'wait_bids',
  wait_initial_payment: 'wait_initial_payment',
  wait_final_payment: 'wait_final_payment',
  await_user_completion: 'await_user_completion',
  completed: 'completed',
  confirmed: 'confirmed',
  in_progress: 'in_progress',
  wait_bids: 'wait_bids',
  wait_user_accept: 'wait_user_accept',
  wait_mod: 'wait_mod',
  wait_waredat_payment: 'wait_waredat_payment',
  await_user_payment_confirmation: 'await_user_payment_confirmation',
  order_missed: 'order_missed',
  delayed: 'delayed',
  delayed_waiting_client_inputs: 'delayed_waiting_client_inputs',
  have_issue: 'have_issue',
  resovling_issue: 'resolving_issue',
  cancelled_by_client: 'cancelled_by_client',
  cancelled_by_sp: 'cancelled_by_sp',
  have_issue: 'have_issue',
  resolving_issue: 'resolving_issue',
};

export const notificationConstants = {
  user_order_initial_invoice_issued: 'user:order:initial_invoice_issued',
  user_order_final_invoice_issued: 'user:order:final_invoice_issued',
  user_order_upload_doc: 'user:order:upload_doc',
  user_order_confirmed: 'user:order:confirmed',
  user_order_draft_bl_uploaded: 'user:order:draft_bl_uploaded',
  user_file_request: 'user:file_request',
  user_order_shipper_details: 'user:order:shipper_details',
  user_order_consignee_details: 'user:order:consignee_details',
};

export const getImportExportChipLabel = (
  shipmentType,
  gps_coord_lat,
  gps_coord_lng,
  service_id,
  translate
) => {
  if (
    shipmentType === 'import' &&
    gps_coord_lat !== null &&
    gps_coord_lng !== null &&
    service_id === 1
  ) {
    return `${translate('import')} + ${translate('local')}`;
  } else if (
    shipmentType === 'export' &&
    gps_coord_lat !== null &&
    gps_coord_lng !== null &&
    service_id === 1
  ) {
    return `${translate('local')} + ${translate('export')}`;
  } else if (shipmentType === 'import') {
    return translate('import');
  } else if (shipmentType === 'export') {
    return translate('export');
  }
};

export const getImportExportChipLetters = (
  shipmentType,
  gps_coord_lat,
  gps_coord_lng,
  service_id
) => {
  if (
    shipmentType === 'import' &&
    gps_coord_lat !== null &&
    gps_coord_lng !== null &&
    service_id === 1
  ) {
    return `I + L`;
  } else if (
    shipmentType === 'export' &&
    gps_coord_lat !== null &&
    gps_coord_lng !== null &&
    service_id === 1
  ) {
    return `L + E`;
  } else if (shipmentType === 'import') {
    return 'I';
  } else if (shipmentType === 'export') {
    return 'E';
  }
};

export const adminAccountType = (params, translate) => {
  if (params?.row?.is_superuser) {
    return <Chip label={translate('super_user')} />;
  } else {
    return <Chip label={translate('waredat_employee')} />;
  }
};

export const getProgressPercentageDate = (startDate, endDate) => {
  const total = +endDate - +startDate;
  const elaps = Date.now() - startDate;
  let percentage = Math.round((elaps / total) * 100);
  if (percentage >= 100) {
    return 100;
  } else {
    return percentage;
  }
};

export const SwalConfirmOrder = (translate) => {
  return Swal.fire({
    title: translate('disclaimer'),
    text: translate('accurate_info_liability'),
    icon: 'warning',
    showCancelButton: true,
    showConfirmButton: true,
    cancelButtonColor: '#d33',
    confirmButtonColor: '#3FBD3F',
    focusConfirm: false,
    customClass: 'swal-width-confirmOrder ',
  });
};

export const SwalHaveIssueOrder = (translate) => {
  return Swal.fire({
    text: translate('have_issue_text'),
    icon: 'warning',
    showCancelButton: true,
    showConfirmButton: true,
    cancelButtonColor: '#d33',
    confirmButtonColor: '#3FBD3F',
    focusConfirm: false,
  });
};

export const SwalDelayOrder = (translate) => {
  return Swal.fire({
    text: translate('delay_issue_text'),
    icon: 'warning',
    showCancelButton: true,
    showConfirmButton: true,
    cancelButtonColor: '#d33',
    confirmButtonColor: '#3FBD3F',
    focusConfirm: false,
  });
};

export const SwalCancelOrder = (translate, loading) => {
  return Swal.fire({
    text: translate('cancel_order_text_request'),
    icon: 'warning',
    showCancelButton: true,
    showConfirmButton: true,
    cancelButtonColor: '#d33',
    confirmButtonColor: '#3FBD3F',
    focusConfirm: false,
    allowOutsideClick: !loading,
  });
};

export const adminFileTemplate = (file_template_id, sp_status, waredat_status, client_status) => {
  if (file_template_id === 22 || file_template_id === 23 || file_template_id === 24) {
    switch (waredat_status) {
      case 'read':
        return <Chip className="chip-green-c fw-500" label={waredat_status} />;
        break;
      case 'accepted':
        return <Chip className="chip-green-c fw-500" label={waredat_status} />;
        break;
      case 'rejected':
        return (
          <Chip
            className="chip-red-c fw-500"
            icon={<Iconify icon="basil:cross-solid" color="#FF5252" width={26} />}
            label={waredat_status}
          />
        );
        break;
      case 'pending':
        return (
          <Chip
            className="chip-yellow-c fw-500"
            icon={<Iconify icon="ei:clock" color="#ba8216" width={26} />}
            label={waredat_status}
          />
        );
        break;
      case 'unread':
        return <Chip className="chip-gray-c fw-500" label={waredat_status} />;
        break;
      default:
        return <Chip className="chip-gray-c fw-500" label={waredat_status} />;
    }
  } else if (file_template_id === 26 || file_template_id === 27 || file_template_id === 28) {
    switch (waredat_status) {
      case 'read':
        return <Chip className="chip-green-c fw-500" label={waredat_status} />;
        break;
      case 'accepted':
        return <Chip className="chip-green-c fw-500" label={waredat_status} />;
        break;
      case 'rejected':
        return (
          <Chip
            className="chip-red-c fw-500"
            icon={<Iconify icon="basil:cross-solid" color="#FF5252" width={26} />}
            label={waredat_status}
          />
        );
        break;
      case 'pending':
        return (
          <Chip
            className="chip-yellow-c fw-500"
            icon={<Iconify icon="ei:clock" color="#ba8216" width={26} />}
            label={waredat_status}
          />
        );
        break;
      case 'unread':
        return <Chip className="chip-gray-c fw-500" label={waredat_status} />;
        break;
      default:
        return <Chip className="chip-gray-c fw-500" label={waredat_status} />;
    }
  } else if (file_template_id === 19 || file_template_id === 20 || file_template_id === 21) {
    switch (waredat_status) {
      case 'read':
        return <Chip className="chip-green-c fw-500" label={waredat_status} />;
        break;
      case 'accepted':
        return <Chip className="chip-green-c fw-500" label={waredat_status} />;
        break;
      case 'rejected':
        return (
          <Chip
            className="chip-red-c fw-500"
            icon={<Iconify icon="basil:cross-solid" color="#FF5252" width={26} />}
            label={waredat_status}
          />
        );
        break;
      case 'pending':
        return (
          <Chip
            className="chip-yellow-c fw-500"
            icon={<Iconify icon="ei:clock" color="#ba8216" width={26} />}
            label={waredat_status}
          />
        );
        break;
      case 'unread':
        return <Chip className="chip-gray-c fw-500" label={waredat_status} />;
        break;
      default:
        return <Chip className="chip-gray-c fw-500" label={waredat_status} />;
    }
  } else if (file_template_id === 29 || file_template_id === 30 || file_template_id === 31) {
    switch (waredat_status) {
      case 'read':
        return <Chip className="chip-green-c fw-500" label={waredat_status} />;
        break;
      case 'accepted':
        return <Chip className="chip-green-c fw-500" label={waredat_status} />;
        break;
      case 'rejected':
        return (
          <Chip
            className="chip-red-c fw-500"
            icon={<Iconify icon="basil:cross-solid" color="#FF5252" width={26} />}
            label={waredat_status}
          />
        );
        break;
      case 'pending':
        return (
          <Chip
            className="chip-yellow-c fw-500"
            icon={<Iconify icon="ei:clock" color="#ba8216" width={26} />}
            label={waredat_status}
          />
        );
        break;
      case 'unread':
        return <Chip className="chip-gray-c fw-500" label={waredat_status} />;
        break;
      default:
        return <Chip className="chip-gray-c fw-500" label={waredat_status} />;
    }
  } else {
    switch (sp_status) {
      case 'read':
        return <Chip className="chip-green-c fw-500" label={sp_status} />;
        break;
      case 'accepted':
        return <Chip className="chip-green-c fw-500" label={sp_status} />;
        break;
      case 'rejected':
        return (
          <Chip
            className="chip-red-c fw-500"
            icon={<Iconify icon="basil:cross-solid" color="#FF5252" width={26} />}
            label={sp_status}
          />
        );
        break;
      case 'pending':
        return (
          <Chip
            className="chip-yellow-c fw-500"
            icon={<Iconify icon="ei:clock" color="#ba8216" width={26} />}
            label={sp_status}
          />
        );
        break;
      case 'unread':
        return <Chip className="chip-gray-c fw-500" label={sp_status} />;
        break;
      default:
        return <Chip className="chip-gray-c fw-500" label={sp_status} />;
    }
  }
};

// const start = new Date(2024, 2 , 20);
// const end = new Date(2024, 3, 5);
// console.log(getProgressDate(start, end));

// let start = new Date(2024, 2 , 20);
// let finish = new Date(2024, 3, 15);
// let midpoint = new Date();

// let elapsed = midpoint - start;
// let percent = (elapsed / (finish - start)) * 100;

// console.log('elapsed', elapsed, ' ms', percent, ' % complete');
