/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  orders: [],
};

const slice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setOrders: (state, action) => {
      if (state.orders.length === 0) {
        state.orders = [action.payload];
      } else {
        state.orders = [...state.orders, action.payload];
      }
    },
    deleteOrder: (state, action) => {
      state.orders = state.orders?.filter((e, i) => i !== action.payload);
    },
    clearOrders: (state, action) => {
      state.orders = [];
    },
    changeCustomClearanceOrder: (state, action) => {
      if (state.orders.length === 1) {
        const editArray = state.orders[0];
        state.orders = [{ ...editArray, ...action.payload }];
      } else {
        const editArray = state.orders.pop();
        state.orders = [...state.orders, { ...editArray, ...action.payload }];
      }
    },
    deleteLastOrder: (state, action) => {
      if (state.orders.length === 1) {
        state.orders = [];
      } else {
        let newArr = state.orders.slice(0, -1);
        state.orders = [...newArr];
      }
    },
  },
});

// Reducer
export default slice.reducer;

export const { setOrders, deleteOrder, clearOrders, changeCustomClearanceOrder, deleteLastOrder } =
  slice.actions;
