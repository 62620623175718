/* eslint-disable */
import { useState } from 'react';
// @mui
import {
  Box,
  Stack,
  List,
  Badge,
  Avatar,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
// utils
import { useNavigate } from 'react-router-dom';

import { NewfToNow } from '../../../utils/formatTime';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
import { useLocales } from '../../../locales';
import { dispatch, useSelector } from 'src/redux/store';
import { MarkAllNotificationRead, MarkNotificationRead } from 'src/redux/slices/notifications';
import { getServiceName } from 'src/utils/serviceUtils';
import SimpleBar from 'simplebar-react';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const { translate, currentLang } = useLocales();
  const navigate = useNavigate();
  const notifications = useSelector((state) => state.notifications.notifications);
  const [openPopover, setOpenPopover] = useState(null);
  const totalUnRead = notifications.filter((item) => item?.read === false).length;

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const onClickNotification = (notification) => {
    dispatch(MarkNotificationRead(notification.id));
    setOpenPopover(null);
    let order_id_notification = notification?.payload?.message?.split('#')[1];
    let user_type = localStorage.getItem('userType');

    if (user_type === 'client') {
      if (
        notification?.notification_type === 'user:order:created' ||
        notification?.notification_type === 'user:order:waiting_user_accept'
      ) {
        navigate(`/dashboard/client/order/details/${order_id_notification}`, {
          state: { order_id_notification, service_id: notification?.payload?.service_id },
        });
      } else if (
        notification?.notification_type === 'user:order:confirmed' &&
        notification?.payload?.service_id === 2
      ) {
        navigate(`/dashboard/client-orders/${order_id_notification}`, {
          state: { service_id: notification?.payload?.service_id, notification: notification },
        });
      } else if (
        notification?.notification_type === 'user:ticket:in_progress' ||
        notification?.notification_type === 'user:ticket:delayed' ||
        notification?.notification_type === 'user:ticket:on_hold' ||
        notification?.notification_type === 'user:ticket:resolved' ||
        notification?.notification_type === 'user:ticket:unresolved'
      ) {
        navigate(`/dashboard/client/support`);
      } else if (notification?.notification_type === 'user:chat_recieved') {
        navigate(`/dashboard/client/support-chat`, {
          state: {
            ticket_chat: { id: notification?.payload?.chat_id },
            id: notification?.payload?.ticket_id,
            assignee: {
              id: notification?.payload?.assignee_id,
              name: notification?.payload?.assignee_name,
            },
          },
        });
      } else if (
        notification.notification_type === 'user:upload_national_address_doc_approved' ||
        notification.notification_type === 'user:upload_national_address_doc_rejected' ||
        notification.notification_type === 'user:upload_vat_doc_approved' ||
        notification.notification_type === 'user:upload_vat_doc_rejected'
      ) {
        navigate(`/dashboard/user/account`);
      } else if (
        notification.notification_type === 'user:order:awaiting_completion' ||
        notification.notification_type === 'user:order_delayed' ||
        notification.notification_type === 'user:order_resumed' ||
        notification.notification_type === 'user:order:delivery_time_changed'
      ) {
        navigate(`/dashboard/client/list/orders`, { replace: true });
      } else {
        navigate(`/dashboard/client-orders/${order_id_notification}`, {
          state: { service_id: notification?.payload?.service_id, notification: notification },
        });
      }
    } else if (user_type === 'service_provider') {
      if (notification?.notification_type === 'user:order:upload_doc') {
        navigate(`/dashboard/order-details`, {
          state: { order_details: { id: order_id_notification }, notification: notification },
        });
      } else if (notification.notification_type === 'user:order:confirmed') {
        navigate(`/dashboard/order-details`, {
          state: { order_details: { id: order_id_notification }, notification: notification },
        });
      } else if (
        notification?.notification_type === 'user:ticket:in_progress' ||
        notification?.notification_type === 'user:ticket:delayed' ||
        notification?.notification_type === 'user:ticket:on_hold' ||
        notification?.notification_type === 'user:ticket:resolved' ||
        notification?.notification_type === 'user:ticket:unresolved'
      ) {
        navigate(`/dashboard/service-provider/support`);
      } else if (
        notification?.notification_type === 'user:order:region_approved' ||
        notification?.notification_type === 'user:order:region_rejected'
      ) {
        navigate(`/dashboard/service-provider-services`, {
          state: { service_id: notification?.payload?.service_id },
        });
      } else if (notification?.notification_type === 'user:chat_recieved') {
        navigate(`/dashboard/service-provider/support-chat`, {
          state: {
            ticket_chat: { id: notification?.payload?.chat_id },
            id: notification?.payload?.ticket_id,
            assignee: {
              id: notification?.payload?.assignee_id,
              name: notification?.payload?.assignee_name,
            },
          },
        });
      } else if (
        notification.notification_type === 'user:upload_national_address_doc_approved' ||
        notification.notification_type === 'user:upload_national_address_doc_rejected' ||
        notification.notification_type === 'user:upload_vat_doc_approved' ||
        notification.notification_type === 'user:upload_vat_doc_rejected'
      ) {
        navigate(`/dashboard/user/account`);
      } else if (notification.notification_type === 'user:order:awaiting_completion') {
        navigate(`/dashboard/service-provider-orders`, { replace: true });
      } else {
        navigate(`/dashboard/order-details`, {
          state: { order_details: { id: order_id_notification }, notification: notification },
        });
      }
    } else if (user_type === 'waredat' || user_type === 'admin') {
      if (
        notification?.notification_type === 'user:ticket:created' ||
        notification?.notification_type === 'user:ticket:assigned'
      ) {
        navigate(`/dashboard/admin/support`);
      } else if (
        notification?.notification_type === 'user:order:region_waiting_approval_registration'
      ) {
        navigate(`/dashboard/admin/service-provider/regions`, {
          state: { state: { id: notification?.payload?.user_id } },
        });
      } else if (
        notification?.notification_type === 'user:order:transfer_receipt_uploaded' ||
        notification?.notification_type === 'user:order:issue_final_invoice' ||
        notification?.notification_type === 'user:order:final_transfer_receipt_uploaded' ||
        notification?.notification_type === 'user:order:issue_initial_invoice' ||
        notification?.notification_type === 'review_cancel_order_request' ||
        notification?.notification_type === 'user:order_delayed' ||
        notification?.notification_type === 'user:order_resumed' ||
        notification?.notification_type === 'user:order:have_issue'
      ) {
        navigate(`/dashboard/admin/orders`);
      } else if (notification?.notification_type === 'user:upload_doc_waiting_approval') {
        navigate(
          notification?.payload?.user_type === 'client'
            ? `/dashboard/admin/client/account`
            : `/dashboard/admin/service-provider/account`,
          { state: { data: notification } }
        );
      } else if (notification?.notification_type === 'user:chat_recieved') {
        navigate(`/dashboard/admin/support-chat`, {
          state: {
            ticket_chat: { id: notification?.payload?.chat_id },
            id: notification?.payload?.ticket_id,
            assignee: {
              id: notification?.payload?.assignee_id,
              name: notification?.payload?.assignee_name,
            },
          },
        });
      } else if (notification?.notification_type === 'review_offer_client_reject_note') {
        navigate(`/dashboard/admin/offers`, {
          state: {
            row: {
              id: notification?.payload?.order_id,
            },
          },
        });
      }
    }
  };

  return (
    <>
      <IconButtonAnimate
        color={openPopover ? 'primary' : 'default'}
        onClick={handleOpenPopover}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 360, p: 0 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">{translate('notifications')}</Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {translate('you_have')} {totalUnRead} {translate('unread_messages')}
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton
                color="primary"
                onClick={() => {
                  dispatch(MarkAllNotificationRead());
                  setOpenPopover(null);
                }}
              >
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {/* <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}> */}
        <SimpleBar style={{ maxHeight: 600 }}>
          <List>
            {notifications.map(
              (notification) =>
                notification?.notification_type !== 'user:order:retracted_offer' && (
                  <span key={notification?.id}>
                    <NotificationItem
                      currentLang={currentLang}
                      translate={translate}
                      ReadNotification={() => onClickNotification(notification)}
                      notification={notification}
                    />
                  </span>
                )
            )}
          </List>
        </SimpleBar>
        {/* </Scrollbar> */}
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

function NotificationItem({ notification, ReadNotification, translate, currentLang }) {
  const { avatar, title } = renderContent(notification, translate, currentLang);

  return (
    <ListItemButton
      onClick={() => (notification.read === false ? ReadNotification() : '')}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>

      <ListItemText
        disableTypography
        primary={title}
        secondary={
          <Stack direction="row" sx={{ mt: 0.5, typography: 'caption', color: 'text.disabled' }}>
            <Iconify icon="eva:clock-fill" width={16} sx={{ mr: 0.5 }} />
            <Typography variant="caption">
              {NewfToNow(notification?.payload?.time, currentLang)}
            </Typography>
          </Stack>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification, translate, currentLang) {
  let displayText = '';
  let displayMessage = '';
  let displayMessageType = notification.payload?.message?.split('Order')[0]?.trim();
  let orderNumber = notification.payload?.message?.split('Order')[1];

  if (notification.display_text === 'Order Created') {
    displayText = translate('order_created');
  } else if (notification.display_text === 'Waiting User Accept') {
    displayText = translate('wait_user_accept');
  } else if (notification.display_text === 'Confirmed') {
    displayText = translate('confirmed');
  } else if (notification.display_text === 'Rejected') {
    displayText = translate('rejected');
  } else if (notification.display_text === 'In Progress') {
    displayText = translate('in_progress');
  } else if (notification.display_text === 'Completed') {
    displayText = translate('completed');
  } else if (notification.display_text === 'Cancelled') {
    displayText = translate('cancelled');
  } else if (notification.display_text === 'Offer Accepted') {
    displayText = translate('offer_accepted');
  } else if (notification.display_text === 'Consignee Details') {
    displayText = translate('consignee_contact_details');
  } else if (notification.display_text === 'Shipper Details') {
    displayText = translate('shiperContactDetails');
  } else if (notification.display_text === 'Upload Document') {
    displayText = translate('upload_documents');
  } else if (notification.notification_type === 'user:order:awaiting_completion') {
    displayText = translate('await_user_completion');
  } else if (notification.display_text === 'Intital Invoice Issued') {
    displayText = translate('initial_invoice_issued');
  } else if (notification.display_text === 'Final Invoice Issued') {
    displayText = translate('final_invoice_issued');
  } else if (notification.display_text === 'DRAFT_BL Uploaded') {
    displayText = translate('draft_bl_uploaded');
  } else if (notification.display_text === 'DRAFT_BL Accepted') {
    displayText = translate('draft_bl_accepted');
  } else if (notification.display_text === 'DRAFT_BL Rejected') {
    displayText = translate('draft_bl_rejected');
  } else if (notification.display_text === 'Order Completion Time Finished') {
    displayText = translate('order_overdue');
  } else if (notification.display_text === 'Support Ticket Created') {
    displayText = translate('support_ticket');
  } else if (notification.notification_type === 'user:ticket:delayed') {
    displayText = translate('delayed');
  } else if (notification.notification_type === 'user:ticket:on_hold') {
    displayText = translate('on_hold');
  } else if (notification.notification_type === 'user:ticket:resolved') {
    displayText = translate('resolved');
  } else if (notification.notification_type === 'user:ticket:unresolved') {
    displayText = translate('un_resolved');
  } else if (notification.notification_type === 'user:ticket:assigned') {
    displayText = translate('ticket_assigned');
  } else if (notification.notification_type === 'user:order:transfer_receipt_uploaded') {
    displayText = translate('transfer_receipt_uploaded');
  } else if (notification.notification_type === 'user:order:region_waiting_approval_registration') {
    displayText = translate('waiting_for_approval');
  } else if (notification.notification_type === 'user:order:region_approved') {
    displayText = translate('region_approved');
  } else if (notification.notification_type === 'user:order:region_rejected') {
    displayText = translate('region_rejected');
  } else if (notification.notification_type === 'user:file_request') {
    displayText = translate('file_request');
  } else if (notification.notification_type === 'user:chat_recieved') {
    displayText = translate('message');
  } else if (notification.notification_type === 'user:upload_doc_waiting_approval') {
    displayText = translate('waiting_for_approval');
  } else if (notification.notification_type === 'user:upload_national_address_doc_approved') {
    displayText = translate('approved');
  } else if (notification.notification_type === 'user:upload_national_address_doc_rejected') {
    displayText = translate('rejected');
  } else if (notification.notification_type === 'user:upload_vat_doc_approved') {
    displayText = translate('approved');
  } else if (notification.notification_type === 'user:upload_vat_doc_rejected') {
    displayText = translate('rejected');
  } else if (notification.notification_type === 'user:order:info_updated') {
    displayText = translate('order_info_updated');
  } else if (notification.notification_type === 'user:order:request_file_upload_doc') {
    displayText = translate('file_request_uploaded');
  } else if (notification.notification_type === 'user:order:files_uploaded') {
    displayText = translate('order_files_uploaded');
  } else if (notification.notification_type === 'user:order:issue_final_invoice') {
    displayText = translate('issue_final_invoice');
  } else if (notification.notification_type === 'user:order:final_transfer_receipt_uploaded') {
    displayText = translate('final_transfer_receipt_uploaded');
  } else if (notification.notification_type === 'user:order:issue_initial_invoice') {
    displayText = translate('issue_initial_invoice');
  } else if (notification.notification_type === 'review_offer_client_reject_note') {
    displayText = translate('review_client_reject_note');
  } else if (notification.notification_type === 'review_cancel_order_request') {
    displayText = translate('review_cancel_order_request');
  } else if (notification.notification_type === 'user:order:cancel_request_approved') {
    displayText = translate('order_cancelled');
  } else if (notification.notification_type === 'user:order:cancel_request_rejected') {
    displayText = translate('order_cancel_request_rejected');
  } else if (notification.notification_type === 'user:order_delayed') {
    displayText = translate('order_delayed');
  } else if (notification.notification_type === 'user:order_resumed') {
    displayText = translate('order_resumed');
  } else if (notification.notification_type === 'user:order:issue_resolving') {
    displayText = translate('resolving_issue');
  } else if (notification.notification_type === 'user:order:have_issue') {
    displayText = translate('have_issue');
  } else if (notification.notification_type === 'user:order:issue_resolved') {
    displayText = translate('issue_resolved');
  } else if (notification.notification_type === 'user:order:delivery_time_changed') {
    displayText = translate('delivery_time_changed');
  }

  if (displayMessageType === 'Sea shipping') {
    displayMessage = `${translate('sea_shipping')} ${translate('order')} ${orderNumber}`;
  } else if (displayMessageType === 'Customs clearance') {
    displayMessage = `${translate('Customs_Clearance')} ${translate('order')} ${orderNumber}`;
  } else if (displayMessageType === 'Local land transportation') {
    displayMessage = `${translate('Local_Land_Transportation')} ${translate(
      'order'
    )} ${orderNumber}`;
  } else if (displayMessageType === 'Waiting User Accept.') {
    displayMessage = `${translate('wait_user_accept')} ${translate('order')} #${Number(
      orderNumber?.split('#')?.[1]
    ).toLocaleString(currentLang.value === 'ar' ? 'ar-EG' : 'en-US', { useGrouping: false })}`;
  } else if (displayMessageType === 'Confirmed') {
    displayMessage = `${translate('confirmed')} ${translate('order')} #${Number(
      orderNumber?.split('#')?.[1]
    ).toLocaleString(currentLang.value === 'ar' ? 'ar-EG' : 'en-US', { useGrouping: false })}`;
  } else if (displayMessageType === 'Rejected') {
    displayMessage = `${translate('rejected')} ${translate('order')} #${Number(
      orderNumber?.split('#')?.[1]
    ).toLocaleString(currentLang.value === 'ar' ? 'ar-EG' : 'en-US', { useGrouping: false })}`;
  } else if (displayMessageType === 'In Progress') {
    displayMessage = `${translate('in_progress')} ${translate('order')} #${Number(
      orderNumber?.split('#')?.[1]
    ).toLocaleString(currentLang.value === 'ar' ? 'ar-EG' : 'en-US', { useGrouping: false })}`;
  } else if (displayMessageType === 'Completed') {
    displayMessage = `${translate('completed')} ${translate('order')} #${Number(
      orderNumber?.split('#')?.[1]
    ).toLocaleString(currentLang.value === 'ar' ? 'ar-EG' : 'en-US', { useGrouping: false })}`;
  } else if (displayMessageType === 'Cancelled') {
    displayMessage = `${translate('cancelled')} ${translate('order')} #${Number(
      orderNumber?.split('#')?.[1]
    ).toLocaleString(currentLang.value === 'ar' ? 'ar-EG' : 'en-US', { useGrouping: false })}`;
  } else if (notification.display_text === 'Offer Accepted') {
    displayMessage = `${translate('offer_accepted')} ${translate('order')} #${Number(
      orderNumber?.split('#')?.[1]
    ).toLocaleString(currentLang.value === 'ar' ? 'ar-EG' : 'en-US', { useGrouping: false })}`;
  } else if (notification.display_text === 'Consignee Details') {
    displayMessage = `${translate('add')} ${translate('consignee_contact_details')} #${Number(
      orderNumber?.split('#')?.[1]
    ).toLocaleString(currentLang.value === 'ar' ? 'ar-EG' : 'en-US', { useGrouping: false })}`;
  } else if (notification.display_text === 'Shipper Details') {
    displayMessage = `${translate('add')} ${translate('shiperContactDetails')} #${Number(
      orderNumber?.split('#')?.[1]
    ).toLocaleString(currentLang.value === 'ar' ? 'ar-EG' : 'en-US', { useGrouping: false })}`;
  } else if (notification.display_text === 'Upload Document') {
    displayMessage = `${translate('upload_documents')} #${Number(
      orderNumber?.split('#')?.[1]
    ).toLocaleString(currentLang.value === 'ar' ? 'ar-EG' : 'en-US', { useGrouping: false })}`;
  } else if (notification.notification_type === 'user:order:awaiting_completion') {
    displayMessage = `${translate('await_user_completion')} #${Number(
      notification?.payload?.order_id
    ).toLocaleString(currentLang.value === 'ar' ? 'ar-EG' : 'en-US', { useGrouping: false })}`;
  } else if (notification.display_text === 'Intital Invoice Issued') {
    displayMessage = `${translate('initial_invoice_issued')} #${Number(
      orderNumber?.split('#')?.[1]
    ).toLocaleString(currentLang.value === 'ar' ? 'ar-EG' : 'en-US', { useGrouping: false })}`;
  } else if (notification.display_text === 'Final Invoice Issued') {
    displayMessage = `${translate('final_invoice_issued')} #${Number(
      orderNumber?.split('#')?.[1]
    ).toLocaleString(currentLang.value === 'ar' ? 'ar-EG' : 'en-US', { useGrouping: false })}`;
  } else if (notification.display_text === 'DRAFT_BL Uploaded') {
    displayMessage = `${translate('draft_bl_uploaded')} #${Number(
      orderNumber?.split('#')?.[1]
    ).toLocaleString(currentLang.value === 'ar' ? 'ar-EG' : 'en-US', { useGrouping: false })}`;
  } else if (notification.display_text === 'DRAFT_BL Accepted') {
    displayMessage = `${translate('draft_bl_accepted')} #${Number(
      orderNumber?.split('#')?.[1]
    ).toLocaleString(currentLang.value === 'ar' ? 'ar-EG' : 'en-US', { useGrouping: false })}`;
  } else if (notification.display_text === 'DRAFT_BL Rejected') {
    displayMessage = `${translate('draft_bl_rejected')} #${Number(
      orderNumber?.split('#')?.[1]
    ).toLocaleString(currentLang.value === 'ar' ? 'ar-EG' : 'en-US', { useGrouping: false })}`;
  } else if (notification.display_text === 'Order Completion Time Finished') {
    displayMessage = `${translate('order_overdue')} #${Number(
      notification?.payload?.order_id
    ).toLocaleString(currentLang.value === 'ar' ? 'ar-EG' : 'en-US', { useGrouping: false })}`;
  } else if (notification.notification_type === 'user:ticket:created') {
    displayMessage = `${translate('support_ticket')} #${Number(
      notification?.payload?.ticket_id
    ).toLocaleString(currentLang.value === 'ar' ? 'ar-EG' : 'en-US', { useGrouping: false })}`;
  } else if (notification.notification_type === 'user:ticket:in_progress') {
    displayMessage = `${translate('support_ticket')} #${Number(
      notification?.payload?.ticket_id
    ).toLocaleString(currentLang.value === 'ar' ? 'ar-EG' : 'en-US', { useGrouping: false })}`;
  } else if (notification.notification_type === 'user:ticket:delayed') {
    displayMessage = `${translate('support_ticket')} #${Number(
      notification?.payload?.ticket_id
    ).toLocaleString(currentLang.value === 'ar' ? 'ar-EG' : 'en-US', { useGrouping: false })}`;
  } else if (notification.notification_type === 'user:ticket:on_hold') {
    displayMessage = `${translate('support_ticket')} #${Number(
      notification?.payload?.ticket_id
    ).toLocaleString(currentLang.value === 'ar' ? 'ar-EG' : 'en-US', { useGrouping: false })}`;
  } else if (notification.notification_type === 'user:ticket:resolved') {
    displayMessage = `${translate('support_ticket')} #${Number(
      notification?.payload?.ticket_id
    ).toLocaleString(currentLang.value === 'ar' ? 'ar-EG' : 'en-US', { useGrouping: false })}`;
  } else if (notification.notification_type === 'user:ticket:unresolved') {
    displayMessage = `${translate('support_ticket')} #${Number(
      notification?.payload?.ticket_id
    ).toLocaleString(currentLang.value === 'ar' ? 'ar-EG' : 'en-US', { useGrouping: false })}`;
  } else if (notification.notification_type === 'user:ticket:assigned') {
    displayMessage = `${translate('support_ticket')} #${Number(
      notification?.payload?.ticket_id
    ).toLocaleString(currentLang.value === 'ar' ? 'ar-EG' : 'en-US', { useGrouping: false })}`;
  } else if (notification.notification_type === 'user:order:transfer_receipt_uploaded') {
    displayMessage = `${translate('transfer_receipt_shipping')} #${Number(
      notification?.payload?.order_id
    ).toLocaleString(currentLang.value === 'ar' ? 'ar-EG' : 'en-US', { useGrouping: false })}`;
  } else if (notification.notification_type === 'user:order:region_waiting_approval_registration') {
    displayMessage = `${getServiceName(notification?.payload?.service_id, translate)}, ${
      notification?.payload?.region_name
    } ${translate('reigon')} ${translate('waiting_for_approval')}`;
  } else if (notification.notification_type === 'user:order:region_approved') {
    displayMessage = `${notification?.payload?.region_name} ${translate('approved')}`;
  } else if (notification.notification_type === 'user:order:region_rejected') {
    displayMessage = `${notification?.payload?.region_name} ${translate('rejected')}`;
  } else if (notification.notification_type === 'user:file_request') {
    displayMessage = `${translate('file_request')} #${notification?.payload?.order_id}`;
  } else if (notification.notification_type === 'user:chat_recieved') {
    displayMessage = `${translate('message_received')} #${notification?.payload?.ticket_id}`;
  } else if (notification.notification_type === 'user:upload_doc_waiting_approval') {
    displayMessage = `${translate('documents')} ${translate('waiting_for_approval')}`;
  } else if (notification.notification_type === 'user:upload_national_address_doc_approved') {
    displayMessage = `${translate('national_address')} ${translate('approved')}`;
  } else if (notification.notification_type === 'user:upload_vat_doc_approved') {
    displayMessage = `${translate('vat_file')} ${translate('approved')}`;
  } else if (notification.notification_type === 'user:upload_national_address_doc_rejected') {
    displayMessage = `${translate('national_address')} ${translate('rejected')}`;
  } else if (notification.notification_type === 'user:upload_vat_doc_rejected') {
    displayMessage = `${translate('vat_file')} ${translate('rejected')}`;
  } else if (notification.notification_type === 'user:order:info_updated') {
    displayMessage = `${translate('order_info_updated')} #${notification.payload?.message
      ?.split('#')?.[1]
      ?.trim()}`;
  } else if (notification.notification_type === 'user:order:request_file_upload_doc') {
    displayMessage = `${translate('file_request_uploaded')} #${notification.payload?.message
      ?.split('#')?.[1]
      ?.trim()}`;
  } else if (notification.notification_type === 'user:order:files_uploaded') {
    displayMessage = `${translate('order_files_uploaded')} #${notification.payload?.message
      ?.split('#')?.[1]
      ?.trim()}`;
  } else if (notification.notification_type === 'user:order:issue_final_invoice') {
    displayMessage = `${translate('issue_final_invoice')} #${notification?.payload?.order_id}`;
  } else if (notification.notification_type === 'user:order:final_transfer_receipt_uploaded') {
    displayMessage = `${translate('final_transfer_receipt_uploaded')} #${
      notification?.payload?.order_id
    }`;
  } else if (notification.notification_type === 'user:order:issue_initial_invoice') {
    displayMessage = `${translate('issue_initial_invoice')} #${notification?.payload?.order_id}`;
  } else if (notification.notification_type === 'review_offer_client_reject_note') {
    displayMessage = `${translate('review_client_reject_note')} #${
      notification?.payload?.order_id
    }`;
  } else if (notification.notification_type === 'review_cancel_order_request') {
    displayMessage = `${translate('review_cancel_order_request')} #${
      notification?.payload?.order_id
    }`;
  } else if (notification.notification_type === 'user:order:cancel_request_approved') {
    displayMessage = `${translate('order_cancelled')} #${notification?.payload?.order_id}`;
  } else if (notification.notification_type === 'user:order:cancel_request_rejected') {
    displayMessage = `${translate('order_cancel_request_rejected')} #${
      notification?.payload?.order_id
    }`;
  } else if (notification.notification_type === 'user:order_delayed') {
    displayMessage = `${translate('order_delayed')} #${notification?.payload?.order_id}`;
  } else if (notification.notification_type === 'user:order_resumed') {
    displayMessage = `${translate('order_resumed')} #${notification?.payload?.order_id}`;
  } else if (notification.notification_type === 'user:order:issue_resolving') {
    displayMessage = `${translate('resolving_issue')} #${notification?.payload?.order_id}`;
  } else if (notification.notification_type === 'user:order:have_issue') {
    displayMessage = `${translate('have_issue')} #${notification?.payload?.order_id}`;
  } else if (notification.notification_type === 'user:order:issue_resolved') {
    displayMessage = `${translate('issue_resolved')} #${notification?.payload?.order_id}`;
  } else if (notification.notification_type === 'user:order:delivery_time_changed') {
    displayMessage = `${notification?.payload?.message} #${notification?.payload?.order_id}`;
  }

  const title = (
    <>
      <Typography variant="subtitle2">{displayText}</Typography>
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        {displayMessage}
      </Typography>
    </>
  );

  if (notification.notification_type === 'user:order:created') {
    return {
      avatar: (
        <img alt={notification.display_text} src="/assets/icons/notification/ic_package.svg" />
      ),
      title,
    };
  } else if (notification.notification_type === 'user:order:waiting_user_accept') {
    return {
      avatar: (
        <img
          alt={notification.display_text}
          width={24}
          height={25}
          src="/assets/icons/notification/sand_clock.svg"
        />
      ),
      title,
    };
  } else if (notification.notification_type === 'user:order:confirmed') {
    return {
      avatar: (
        <img
          alt={notification.display_text}
          width={24}
          height={25}
          src="/assets/icons/notification/confirmed.png"
        />
      ),
      title,
    };
  } else if (notification.notification_type === 'user:order:rejected') {
    return {
      avatar: (
        <img
          alt={notification.display_text}
          width={24}
          height={25}
          src="/assets/icons/notification/reject.png"
        />
      ),
      title,
    };
  } else if (notification.notification_type === 'user:order:in_progress') {
    return {
      avatar: <Iconify icon="svg-spinners:3-dots-bounce" color="green" width={24} />,
      title,
    };
  } else if (notification.notification_type === 'user:ticket:in_progress') {
    return {
      avatar: <Iconify icon="svg-spinners:3-dots-bounce" color="green" width={24} />,
      title,
    };
  } else if (notification.notification_type === 'user:order:completed') {
    return {
      avatar: <Iconify icon="fluent:checkmark-16-filled" color="green" width={26} />,
      title,
    };
  } else if (notification.notification_type === 'user:order:issue_resolved') {
    return {
      avatar: <Iconify icon="fluent:checkmark-16-filled" color="green" width={26} />,
      title,
    };
  } else if (notification.notification_type === 'user:order:cancelled') {
    return {
      avatar: <Iconify icon="heroicons:x-mark-20-solid" color="red" width={26} />,
      title,
    };
  } else if (notification.notification_type === 'user:ticket:created') {
    return {
      avatar: <Iconify icon="streamline:customer-support-1-solid" width={26} />,
      title,
    };
  } else if (notification.notification_type === 'user:ticket:delayed') {
    return {
      avatar: (
        <Iconify sx={{ color: 'rgb(255,195,0)' }} icon="zondicons:exclamation-outline" width={30} />
      ),
      title,
    };
  } else if (notification.notification_type === 'user:order_delayed') {
    return {
      avatar: (
        <Iconify sx={{ color: 'rgb(255,195,0)' }} icon="zondicons:exclamation-outline" width={30} />
      ),
      title,
    };
  } else if (notification.notification_type === 'user:order_resumed') {
    return {
      avatar: <Iconify icon="svg-spinners:3-dots-bounce" color="green" width={24} />,
      title,
    };
  } else if (notification.notification_type === 'user:ticket:on_hold') {
    return {
      avatar: (
        <Iconify sx={{ color: 'rgb(255,195,0)' }} icon="zondicons:exclamation-outline" width={30} />
      ),
      title,
    };
  } else if (notification.notification_type === 'user:ticket:resolved') {
    return {
      avatar: <Iconify icon="fluent:checkmark-16-filled" color="green" width={30} />,
      title,
    };
  } else if (notification.notification_type === 'user:ticket:unresolved') {
    return {
      avatar: <Iconify icon="heroicons:x-mark-20-solid" color="red" width={30} />,
      title,
    };
  } else if (notification.notification_type === 'user:ticket:assigned') {
    return {
      avatar: <Iconify icon="fluent-mdl2:assign" width={30} />,
      title,
    };
  } else if (notification.notification_type === 'user:order:consignee_details') {
    return {
      avatar: <Iconify icon="gridicons:user-add" width={35} />,
      title,
    };
  } else if (notification.notification_type === 'user:order:shipper_details') {
    return {
      avatar: <Iconify icon="mdi:group-add" width={37} />,
      title,
    };
  } else if (notification.notification_type === 'review_offer_client_reject_note') {
    return {
      avatar: <Iconify icon="solar:document-linear" width={37} />,
      title,
    };
  } else if (notification.notification_type === 'user:upload_doc_waiting_approval') {
    return {
      avatar: <Iconify icon="carbon:document" width={30} />,
      title,
    };
  } else if (notification.notification_type === 'user:order:upload_doc') {
    return {
      avatar: <Iconify icon="tabler:file-upload" width={37} />,
      title,
    };
  } else if (notification.notification_type === 'user:order:transfer_receipt_uploaded') {
    return {
      avatar: <Iconify icon="iconamoon:invoice-light" width={37} />,
      title,
    };
  } else if (notification.notification_type === 'user:order:draft_bl_uploaded') {
    return {
      avatar: <Iconify icon="tabler:file-upload" width={37} />,
      title,
    };
  } else if (notification.notification_type === 'user:order:accepted_offer') {
    return {
      avatar: <Iconify icon="flat-color-icons:checkmark" width={37} />,
      title,
    };
  } else if (notification.notification_type === 'user:order:awaiting_completion') {
    return {
      avatar: <Iconify icon="zondicons:exclamation-outline" width={30} />,
      title,
    };
  } else if (notification.notification_type === 'user:order:initial_invoice_issued') {
    return {
      avatar: <Iconify icon="iconamoon:invoice-light" width={30} />,
      title,
    };
  } else if (notification.notification_type === 'user:order:final_invoice_issued') {
    return {
      avatar: <Iconify icon="iconamoon:invoice-light" width={30} />,
      title,
    };
  } else if (notification.notification_type === 'user:order:final_transfer_receipt_uploaded') {
    return {
      avatar: <Iconify icon="iconamoon:invoice-light" width={30} />,
      title,
    };
  } else if (notification.notification_type === 'user:order:issue_final_invoice') {
    return {
      avatar: <Iconify icon="iconamoon:invoice-light" width={30} />,
      title,
    };
  } else if (notification.notification_type === 'user:order:issue_initial_invoice') {
    return {
      avatar: <Iconify icon="iconamoon:invoice-light" width={30} />,
      title,
    };
  } else if (notification.notification_type === 'user:chat_recieved') {
    return {
      avatar: <Iconify icon="tabler:message" width={30} />,
      title,
    };
  } else if (notification.notification_type === 'user:file_request') {
    return {
      avatar: <Iconify icon="uiw:file-add" width={30} />,
      title,
    };
  } else if (notification.notification_type === 'user:order:draft_bl_accepted') {
    return {
      avatar: <Iconify color="green" icon="fluent:document-checkmark-20-regular" width={30} />,
      title,
    };
  } else if (notification.notification_type === 'user:order:request_file_upload_doc') {
    return {
      avatar: <Iconify icon="material-symbols-light:upload-file-outline" width={30} />,
      title,
    };
  } else if (notification.notification_type === 'user:order:draft_bl_rejected') {
    return {
      avatar: <Iconify color="red" icon="fluent:document-dismiss-24-regular" width={30} />,
      title,
    };
  } else if (notification.notification_type === 'user:order:region_waiting_approval_registration') {
    return {
      avatar: <Iconify color="#FFAD01" icon="ion:warning-outline" width={30} />,
      title,
    };
  } else if (notification.notification_type === 'user:upload_national_address_doc_approved') {
    return {
      avatar: <Iconify color="green" icon="fluent:document-checkmark-20-regular" width={30} />,
      title,
    };
  } else if (notification.notification_type === 'user:upload_vat_doc_approved') {
    return {
      avatar: <Iconify color="green" icon="fluent:document-checkmark-20-regular" width={30} />,
      title,
    };
  } else if (notification.notification_type === 'user:upload_national_address_doc_rejected') {
    return {
      avatar: <Iconify color="red" icon="fluent:document-dismiss-24-regular" width={30} />,
      title,
    };
  } else if (notification.notification_type === 'user:upload_vat_doc_rejected') {
    return {
      avatar: <Iconify color="red" icon="fluent:document-dismiss-24-regular" width={30} />,
      title,
    };
  } else if (notification.notification_type === 'user:order:region_approved') {
    return {
      avatar: (
        <Iconify
          color="green"
          icon="material-symbols-light:order-approve-outline-rounded"
          width={30}
        />
      ),
      title,
    };
  } else if (notification.notification_type === 'user:order:region_rejected') {
    return {
      avatar: <Iconify color="red" icon="mdi:invoice-text-remove-outline" width={30} />,
      title,
    };
  } else if (notification.notification_type === 'user:order:delivery_time_changed') {
    return {
      avatar: <Iconify icon="tabler:clock-edit" width={30} />,
      title,
    };
  } else if (
    notification.notification_type === 'user:order:info_updated' ||
    notification.notification_type === 'user:order:files_uploaded'
  ) {
    return {
      avatar: <Iconify icon="fluent:document-one-page-sparkle-16-regular" width={30} />,
      title,
    };
  } else if (notification.notification_type === 'order_shipped') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/notification/ic_shipping.svg" />,
      title,
    };
  } else if (notification.notification_type === 'mail') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/notification/ic_mail.svg" />,
      title,
    };
  } else if (notification.notification_type === 'user:order:completion:time:finish') {
    return {
      avatar: <Iconify color="red" icon="pajamas:time-out" width={30} />,
      title,
    };
  } else if (notification.notification_type === 'review_cancel_order_request') {
    return {
      avatar: <Iconify color="red" icon="lucide:package-search" width={30} />,
      title,
    };
  } else if (notification.notification_type === 'user:order:cancel_request_approved') {
    return {
      avatar: <Iconify icon="heroicons:x-mark-20-solid" color="red" width={30} />,
      title,
    };
  } else if (notification.notification_type === 'user:order:issue_resolving') {
    return {
      avatar: <Iconify icon="material-symbols-light:fast-forward" width={30} />,
      title,
    };
  } else if (notification.notification_type === 'user:order:cancel_request_rejected') {
    return {
      avatar: <Iconify icon="heroicons:x-mark-20-solid" color="red" width={30} />,
      title,
    };
  } else if (notification.notification_type === 'user:order:have_issue') {
    return {
      avatar: <Iconify icon="zondicons:exclamation-outline" color="#ba8216" width={30} />,
      title,
    };
  } else if (notification.notification_type === 'chat_message') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/notification/ic_chat.svg" />,
      title,
    };
  }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}
