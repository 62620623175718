/* eslint-disable */
import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
// import MainLayout from '../layouts/main';
// import SimpleLayout from '../layouts/simple';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,
  // Dashboard: General
  GeneralAppPage,
  UserAccountPage,
  EcommerceCheckoutPage,
  //
  BlankPage,
  PermissionDeniedPage,
  //
  Page500,
  Page403,
  Page404,
  MaintenancePage,
  ServicePage,
  WaitingOffersPage,
  OrderDetailsPage,
  ClientOrderDetails,
  OffersPage,
  ExpenseDetailsPage,
  ClientServicesPage,
  ClientCreateOrderPage,
  ClientReviewOrderPage,
  ClientCreateSeaOrderPage,
  ClientCreateLandOrderPage,
  ClientListOrdersPage,
  ClientOrderReviewDetailsPage,
  AdminOrdersPage,
  AdminCreateUserPage,
  AdminCreateGroupPage,
  AdminCreatePermissionPage,
  AdminOffersPage,
  AdminOrderDetailsPage,
  AdminRegisterPage,
  ServiceRegionsSpPage,
  SupportPage,
  AdminSupportPage,
  ChatPage,
  AccountPage,
  CreateNewPasswordPage,
  ChangePasswordPage,
} from './elements';
// ----------------------------------------------------------------------

export default function Router() {
  const renderRoutes = () => {
    let userType = localStorage.getItem('userType');
    let clientRoutes = [
      { element: <Navigate to={'/dashboard/client'} replace />, index: true },
      { path: 'client', element: <GeneralAppPage /> },
      { path: 'client-orders/:clientOrderId', element: <ClientOrderDetails /> },
      { path: 'client-services', element: <ClientServicesPage /> },
      { path: 'client/order/create', element: <ClientCreateOrderPage /> },
      { path: 'client/order/create/sea', element: <ClientCreateSeaOrderPage /> },
      { path: 'client/order/create/land', element: <ClientCreateLandOrderPage /> },
      { path: 'client/list/orders', element: <ClientListOrdersPage /> },
      { path: 'client/order/details/:id', element: <ClientOrderReviewDetailsPage /> },
      { path: 'client/review/order', element: <ClientReviewOrderPage /> },
      {
        path: 'e-commerce',
        children: [
          { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
          { path: 'checkout', element: <EcommerceCheckoutPage /> },
        ],
      },
      { path: 'client/support', element: <SupportPage /> },
      { path: 'client/support-chat', element: <ChatPage /> },
      {
        path: 'user',
        children: [
          { path: 'account', element: <UserAccountPage /> },
          { path: 'change-password', element: <ChangePasswordPage /> },
        ],
      },
    ];

    let serviceProviderRoutes = [
      { element: <Navigate to={'/dashboard/service-provider'} replace />, index: true },
      { path: 'service-provider', element: <GeneralAppPage /> },
      { path: 'service-provider-orders', element: <WaitingOffersPage /> },
      { path: 'service-provider-services', element: <ServicePage /> },
      { path: 'service', element: <ServicePage /> },
      { path: 'waiting-offers', element: <WaitingOffersPage /> },
      { path: 'order-details', element: <OrderDetailsPage /> },
      { path: 'offers/:id/:order_id', element: <OffersPage /> },
      { path: 'expense_details', element: <ExpenseDetailsPage /> },
      { path: 'service-provider/support', element: <SupportPage /> },
      { path: 'service-provider/support-chat', element: <ChatPage /> },
      {
        path: 'user',
        children: [
          { path: 'account', element: <UserAccountPage /> },
          { path: 'change-password', element: <ChangePasswordPage /> },
        ],
      },
    ];

    let adminRoutes = [
      { element: <Navigate to={'/dashboard/admin'} replace />, index: true },
      { path: 'admin', element: <GeneralAppPage /> },
      { path: 'admin/register', element: <AdminRegisterPage /> },
      { path: 'admin/service-provider/regions', element: <ServiceRegionsSpPage /> },
      { path: 'admin/user', element: <AdminCreateUserPage /> },
      { path: 'admin/group', element: <AdminCreateGroupPage /> },
      { path: 'admin/permission', element: <AdminCreatePermissionPage /> },
      { path: 'admin/orders', element: <AdminOrdersPage /> },
      { path: 'admin/order/details', element: <AdminOrderDetailsPage /> },
      { path: 'admin/offers', element: <AdminOffersPage /> },
      { path: 'admin/support', element: <AdminSupportPage /> },
      { path: 'admin/support-chat', element: <ChatPage /> },
      { path: 'admin/client/account', element: <AccountPage /> },
      { path: 'admin/service-provider/account', element: <AccountPage /> },
      {
        path: 'user',
        children: [{ path: 'change-password', element: <ChangePasswordPage /> }],
      },
    ];

    if (userType === 'client') {
      return clientRoutes;
    } else if (userType === 'service_provider') {
      return serviceProviderRoutes;
    } else if (userType === 'waredat' || userType === 'admin') {
      return adminRoutes;
    } else {
      return [];
    }
  };

  return useRoutes([
    // Auth
    {
      path: 'login',
      element: (
        <GuestGuard>
          <LoginPage />
        </GuestGuard>
      ),
    },
    {
      path: 'register',
      element: (
        <GuestGuard>
          <RegisterPage />
        </GuestGuard>
      ),
    },
    {
      path: 'reset-password',
      element: <CompactLayout />,
      children: [{ path: '', element: <ResetPasswordPage /> }],
    },
    {
      path: 'new-password',
      element: <CompactLayout />,
      children: [{ path: '', element: <NewPasswordPage /> }],
    },
    {
      path: 'create-new-password',
      element: <CompactLayout />,
      children: [{ path: '', element: <CreateNewPasswordPage /> }],
    },
    {
      path: 'verify',
      element: <CompactLayout />,
      children: [{ path: '', element: <VerifyCodePage /> }],
    },

    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'login/ar',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'create-new-password', element: <CreateNewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    // Dashboard
    { path: '/', element: <Navigate replace to="/dashboard" /> },

    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        ...renderRoutes(),
        { path: 'permission-denied', element: <PermissionDeniedPage /> },
        { path: 'blank', element: <BlankPage /> },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}