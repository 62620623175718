/* eslint-disable */
import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo, useState } from 'react';
import { Button } from '@mui/material';
// utils
import axios from '../utils/axios';
import localStorageAvailable from '../utils/localStorageAvailable';
import SessionDialog from '../components/session-dialog/SessionDialog';
//
import { isValidToken, jwtDecode } from './utils';
// import { isValidToken, setSession } from './utils';
import { PATH_AUTH } from '../routes/paths';
import { setUser } from 'src/redux/slices/user';
import { dispatch as dispatchRedux, persistor } from '../redux/store';
import { setOtp } from 'src/redux/slices/Otp';
import { clearTimeout, setTimeout } from 'worker-timers';
import { useLocales } from 'src/locales';
import { useAbly } from 'ably/react';
// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  hasTokenExpired: false,
  isLoading: false,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }

  if (action.type === 'SESSION_END') {
    return {
      ...state,
      hasTokenExpired: action.payload.hasTokenExpired,
    };
  }

  if (action.type === 'IS_LOADING') {
    return {
      ...state,
      isLoading: action.payload.isLoading,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const { translate } = useLocales();
  const [state, dispatch] = useReducer(reducer, initialState);
  const ably = useAbly();

  const storageAvailable = localStorageAvailable();

  const setSession = useCallback((accessToken) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);

      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

      const { exp } = jwtDecode(accessToken);
      tokenExpired(exp);
    } else {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('userInfo');

      delete axios.defaults.headers.common.Authorization;
    }
  }, []);

  const tokenExpired = (exp) => {
    let expiredTimer;
    const currentTime = Date.now();
    // const timeLeft = currentTime + 10000 - currentTime; // ~10s
    const timeLeft = exp * 1000 - currentTime;

    expiredTimer = setTimeout(async () => {
      dispatch({
        type: 'SESSION_END',
        payload: {
          hasTokenExpired: true,
        },
      });
      clearTimeout(expiredTimer);
    }, timeLeft);
  };

  const initialize = useCallback(async () => {
    try {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
      const userType = localStorage.getItem('userType');
      const user_id = localStorage.getItem('user_id');
      const tokenValid = isValidToken(accessToken);

      if (user_id !== null && tokenValid === true) {
        ably.options.clientId = user_id;
        ably.connect();
      }

      if (accessToken && isValidToken(accessToken)) {
        if (userType !== 'admin' || userType !== 'waredat') {
          setSession(accessToken);
        }

        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: true,
            // user,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [storageAvailable, setSession]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(
    async (username, password) => {
      const response = await axios.post(
        '/api/v1/users/login',
        {
          username,
          password,
        },
        {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
      );
      if (response.data.id && response.data.user_id) {
        dispatchRedux(setOtp({ otp: true, id: response.data.id, user_id: response.data.user_id }));
      } else {
        dispatchRedux(setUser(response.data));
        localStorage.setItem('accessToken', response.data.access_token);
        localStorage.setItem('refreshToken', response.data.refresh_token);
        localStorage.setItem('user_id', response.data.user_id);
        localStorage.setItem('userName', response.data.name)
        localStorage.setItem('companyName', response.data.company)
        if (response.data?.is_superuser === true) {
          localStorage.setItem('userType', 'admin');
        } else {
          localStorage.setItem('userType', response?.data?.user_type);
          setSession(response.data.access_token);
        }
        ably.options.clientId = response.data.user_id;
        ably.connect();
        dispatch({
          type: 'LOGIN',
          payload: {
            user: response.data,
          },
        });
      }
    },
    [setSession]
  );

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  }, []);

  // LOGOUT
  const logout = () => {
    persistor.purge();
    ably?.channels?.all[`notifications:${localStorage.getItem(`user_id`)}`]?.detach();
    localStorage.clear();
    dispatch({
      type: 'LOGOUT',
    });
    dispatch({
      type: 'SESSION_END',
      payload: {
        hasTokenExpired: false,
      },
    });
    setSession(null);
    ably.close();
  };

  const continueSession = useCallback(async () => {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('refreshToken')}`;
    dispatch({
      type: 'IS_LOADING',
      payload: {
        isLoading: true,
      },
    });
    const response = await axios.get('https://api-s2.waredat.sa/api/v1/users/refresh');
    localStorage.setItem('accessToken', response.data.access_token);
    localStorage.setItem('refreshToken', response.data.refresh_token);
    setSession(response.data.access_token);

    if (response?.data?.user_type === 'waredat') {
      localStorage.setItem('userType', 'admin');
    } else {
      localStorage.setItem('userType', response?.data?.user_type);
    }

    dispatch({
      type: 'IS_LOADING',
      payload: {
        isLoading: false,
      },
    });

    dispatch({
      type: 'SESSION_END',
      payload: {
        hasTokenExpired: false,
      },
    });
  }, [setSession]);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      hasTokenExpired: state.hasTokenExpired,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'jwt',
      login,
      register,
      logout,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.hasTokenExpired,
      state.user,
      login,
      logout,
      register,
    ]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {state.hasTokenExpired && (
        <SessionDialog
          // open={HasSessionEnd}
          open={state.hasTokenExpired}
          onDispatchLogout={() => {
            dispatch({
              type: 'SESSION_END',
              payload: {
                hasTokenExpired: false,
              },
            });
            dispatch({
              type: 'LOGOUT',
            });
            persistor.purge();
            ably?.channels?.all[`notifications:${localStorage.getItem(`user_id`)}`]?.detach();
            localStorage.clear();
            window.history.pushState({}, '', PATH_AUTH.login);
            window.history.replaceState({}, '');
            setSession(null);
            ably.close();
            window.location.reload();
          }}
          title={translate('continue_end_session')}
          content={
            <>
              {translate('session_expired')} <br />
              {translate('want_to_continue')}
            </>
          }
          action={
            <>
              <Button
                variant="contained"
                color="primary"
                disabled={state.isLoading}
                onClick={() => {
                  continueSession();
                }}
              >
                {translate('yes')}
              </Button>
              <Button
                variant="outlined"
                color="error"
                disabled={state.isLoading}
                onClick={() => {
                  dispatch({
                    type: 'SESSION_END',
                    payload: {
                      hasTokenExpired: false,
                    },
                  });
                  dispatch({
                    type: 'LOGOUT',
                  });
                  persistor.purge();
                  ably?.channels?.all[`notifications:${localStorage.getItem(`user_id`)}`]?.detach();
                  localStorage.clear();
                  setSession(null);
                  ably.close();
                  window.history.replaceState({}, '');
                  window.history.pushState({}, '', PATH_AUTH.login);
                  window.location.reload();
                }}
              >
                {translate('logout')}
              </Button>
            </>
          }
        />
      )}

      <div>{children}</div>
    </AuthContext.Provider>
  );
}
