import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import { CustomAvatar } from '../../../components/custom-avatar';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

export default function NavAccount() {
  const { user } = useAuthContext();
  const user_type = localStorage.getItem('userType')
  const user_name = localStorage.getItem('userName')
  const company_name = localStorage.getItem('companyName')

  if (user_type === 'client' || user_type === 'service_provider') {
    return (
      <Link
        component={RouterLink}
        to={PATH_DASHBOARD.user.account}
        underline="none"
        color="inherit"
      >
        <StyledRoot>
          {/* <CustomAvatar
            src="healthicons:ui-user-profile"
            type="icon"
            alt={user?.displayName}
            name={user?.displayName}
          /> */}

          <Box sx={{ minWidth: 0 }}>
            <Typography variant="subtitle2" noWrap>
              {company_name}

            </Typography>

            <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
              {user_name}
            </Typography>
          </Box>
        </StyledRoot>
      </Link>
    );
  }

  if (user_type === 'waredat' || user_type === 'admin') {
    return (
      <StyledRoot>
        <CustomAvatar
          src="healthicons:ui-user-profile"
          type="icon"
          alt={user?.displayName}
          name={user?.displayName}
        />

        <Box sx={{ ml: 2, minWidth: 0 }}>
          <Typography variant="subtitle2" noWrap>
            {user_name}
          </Typography>
        </Box>
      </StyledRoot>
    );
  }
}