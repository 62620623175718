import Swal from 'sweetalert2';
import { createSlice } from '@reduxjs/toolkit';
import TrackerApi from '../../api/index';
// ----------------------------------------------------------------------

const initialState = {
  orders: [],
  page: 0,
  totalItems: 0,
  rowsPerPage: 10,
  loading: false,
  backGroundLoading: false,
  date_modal_view: false,
};

const slice = createSlice({
  name: 'waitingOffers',
  initialState,
  reducers: {
    setWaitingOffers: (state, action) => {
      state.orders = action.payload;
    },
    setWaitingOffersLoading: (state, action) => {
      state.loading = action.payload;
    },
    setTotalItems: (state, action) => {
      state.totalItems = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setBackGroundLoading: (state, action) => {
      state.backGroundLoading = action.payload;
    },
    setDateModalView: (state, action) => {
      state.date_modal_view = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const {
  setDateModalView,
  setWaitingOffers,
  setWaitingOffersLoading,
  setTotalItems,
  setPage,
  setBackGroundLoading,
  setRowsPerPage,
} = slice.actions;

export function getWaitingOrders(page, rowsPerPage) {
  return async (dispatch) => {
    try {
      dispatch(setWaitingOffersLoading(true));
      dispatch(setPage(page));
      dispatch(setRowsPerPage(rowsPerPage));
      const response = await TrackerApi.get('/orders/list/service_provider/all', {
        params: { skip: page * rowsPerPage, limit: rowsPerPage },
      });
      dispatch(setTotalItems(response?.data?.metadata?.total_elements));
      dispatch(setWaitingOffers(response.data.data));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setWaitingOffersLoading(false));
    }
  };
}

export function onClickActions(
  state,
  order_id,
  data,
  page,
  rowsPerPage,
  translate,
  orderDetails,
  navigate,
  reason
) {
  return async (dispatch) => {
    try {
      dispatch(setWaitingOffersLoading(true));
      let response = '';
      if (state === 'in_progress') {
        response = await TrackerApi.patch(`/orders/${order_id}/changeorderstate`, {
          state,
          expected_delivery_time: data,
          reason: null || reason
        });
      } else {
        response = await TrackerApi.patch(`/orders/${order_id}/changeorderstate`, {
          state,
        });
      }

      if (response.status === 200) {
        dispatch(getWaitingOrders(page, rowsPerPage));
        if (state === 'in_progress') {
          dispatch(setDateModalView(false));
        }
        if (state === 'completed') {
          navigate(`/dashboard/order-details`, {
            state: {
              order_details: orderDetails,
              final_service_invoice: true,
              notification: { notification_type: '' },
            },
          });
        }
      }
    } catch (error) {
      let message = '';

      if (state === 'in_progress') {
        if (orderDetails?.service_id === 2) {
          const packing_list = error.response.data?.detail?.missing_files?.filter(
            (e) => e.name === 'packing_list' && e?.state === 'not_uploaded'
          );

          const shipper_contact_details = error.response.data?.detail?.missing_files?.filter(
            (e) => e.name === 'shipper_contact_details' && e?.state === 'not_uploaded'
          );

          const consignee_contact_details = error.response.data?.detail?.missing_files?.filter(
            (e) => e.name === 'consignee_contact_details' && e?.state === 'not_uploaded'
          );

          if (packing_list?.length > 0) {
            message = translate('client_didnt_upload_packing_list');
          } else if (shipper_contact_details.length > 0) {
            message = translate('client_didnt_fill_shipper_contact');
          } else if (consignee_contact_details.length > 0) {
            message = translate('client_didnt_fill_consignee_contact');
          }
        } else if (orderDetails?.service_id === 1) {
          const customs_release_doc = error.response.data?.detail?.missing_files?.filter(
            (e) => e?.name === 'customs_release_doc' && e?.state === 'not_uploaded'
          );
          const bill_of_lading = error.response.data?.detail?.missing_files?.filter(
            (e) => e?.name === 'bill_of_lading' && e?.state === 'not_uploaded'
          );
          const pur_inv = error.response.data?.detail?.missing_files?.filter(
            (e) => e?.name === 'purchase_invoice_goods' && e?.state === 'not_uploaded'
          );
          const saber_cert = error.response.data?.detail?.missing_files?.filter(
            (e) => e?.name === 'saber_certificate' && e?.state === 'not_uploaded'
          );

          if (customs_release_doc.length > 0) {
            message = translate('upload_custom_release_doc');
          } else if (bill_of_lading?.length > 0) {
            message = translate('client_didnt_upload_bl');
          } else if (pur_inv?.length > 0) {
            message = translate('client_didnt_upload_purch_inv');
          } else if (saber_cert?.length > 0) {
            message = translate('client_didnt_upload_saber_cert');
          }
        } else if (orderDetails?.service_id === 3) {
          const customs_release_doc = error.response.data?.detail?.missing_files?.filter(
            (e) => e?.name === 'custom_release_doc_land_trans' && e?.state === 'not_uploaded'
          );

          if (customs_release_doc?.length > 0) {
            message = translate('client_didnt_upload_cust_release_doc');
          }
        }
      }

      if (state === 'completed') {
        if (orderDetails?.service_id === 2) {
          const draft_bl = error.response.data?.detail?.missing_files?.filter(
            (e) => e.name === 'draft_bl' && e?.state === 'not_uploaded'
          );
          const draft_bl_accepted = error.response.data?.detail?.missing_files?.filter(
            (e) => e.name === 'draft_bl' && e?.state === 'not_accepted'
          );
          const final_bl = error.response.data?.detail?.missing_files?.filter(
            (e) => e.name === 'final_bl' && e?.state === 'not_uploaded'
          );

          const file_request_not_accepted = error.response.data?.detail?.missing_files?.filter(
            (e) => e?.state === 'not_accepted'
          );

          const file_request_not_uploaded = error.response.data?.detail?.missing_files?.filter(
            (e) => e?.state === 'not_uploaded'
          );

          if (draft_bl.length > 0) {
            message = translate('draft_bl_not_found');
          } else if (draft_bl_accepted?.length > 0) {
            message = translate('draft_bl_not_accepted_by_client');
          } else if (final_bl.length > 0) {
            message = translate('upload_final_bl');
          } else if (file_request_not_accepted.length > 0) {
            message = translate('file_request_accept_rejected');
          } else if (file_request_not_uploaded.length > 0) {
            message = translate('file_request_client_upload');
          }
        }
        if (orderDetails?.service_id === 1) {
          const customs_release_doc = error.response.data?.detail?.missing_files?.filter(
            (e) => e?.name === 'customs_release_doc' && e?.state === 'not_uploaded'
          );
          const file_request_not_accepted = error.response.data?.detail?.missing_files?.filter(
            (e) => e?.state === 'not_accepted'
          );

          const file_request_not_uploaded = error.response.data?.detail?.missing_files?.filter(
            (e) => e?.state === 'not_uploaded'
          );

          if (customs_release_doc?.length > 0) {
            message = translate('upload_custom_release_doc');
          } else if (file_request_not_accepted.length > 0) {
            message = translate('file_request_accept_rejected');
          } else if (file_request_not_uploaded.length > 0) {
            message = translate('file_request_client_upload');
          }
        }
      }

      if (error.response.status === 422) {
        Swal.fire({
          icon: 'error',
          title: translate('error'),
          text: message,
          allowOutsideClick: false,
        });
      }
    } finally {
      dispatch(setWaitingOffersLoading(false));
    }
  };
}

export function spCancelOrder(page, rowsPerPage, order_id) {
  return async (dispatch) => {
    try {
      dispatch(setWaitingOffersLoading(true));
      dispatch(setPage(page));
      dispatch(setRowsPerPage(rowsPerPage));
      const response = await TrackerApi.patch(`/orders/${order_id}/sp-cancel-order`);
      if (response.status === 200) {
        dispatch(getWaitingOrders(page, rowsPerPage));
      }
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setWaitingOffersLoading(false));
    }
  };
}

export function spHaveIssueOrder(page, rowsPerPage, order_id) {
  return async (dispatch) => {
    try {
      dispatch(setWaitingOffersLoading(true));
      dispatch(setPage(page));
      dispatch(setRowsPerPage(rowsPerPage));
      const response = await TrackerApi.patch(`/orders/${order_id}/order-state-to-issues`);
      if (response.status === 200) {
        dispatch(getWaitingOrders(page, rowsPerPage));
      }
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setWaitingOffersLoading(false));
    }
  };
}

export function spDelayOrder(page, rowsPerPage, order_id) {
  return async (dispatch) => {
    try {
      dispatch(setWaitingOffersLoading(true));
      dispatch(setPage(page));
      dispatch(setRowsPerPage(rowsPerPage));
      const response = await TrackerApi.patch(`/orders/${order_id}/delay-order`);
      if (response.status === 200) {
        dispatch(getWaitingOrders(page, rowsPerPage));
      }
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setWaitingOffersLoading(false));
    }
  };
}

export function spResumeOrder(page, rowsPerPage, order_id) {
  return async (dispatch) => {
    try {
      dispatch(setWaitingOffersLoading(true));
      dispatch(setPage(page));
      dispatch(setRowsPerPage(rowsPerPage));
      const response = await TrackerApi.patch(`/orders/${order_id}/resume-order`);
      if (response.status === 200) {
        dispatch(getWaitingOrders(page, rowsPerPage));
      }
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setWaitingOffersLoading(false));
    }
  };
}
