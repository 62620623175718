/* eslint-disable */
import ReactDOM from 'react-dom/client';
//
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import * as Ably from 'ably';
import { AblyProvider } from 'ably/react';
// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));
const client = new Ably.Realtime.Promise({
  key: process.env.REACT_APP_ABLY_KEY,
  clientId: null,
  autoConnect: false,
});

root.render(
  <AblyProvider client={client}>
    <App />
  </AblyProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
