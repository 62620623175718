/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
import TrackerApi from '../../api/index';
// ----------------------------------------------------------------------

const initialState = {
  notifications: [],
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    setWebSocketNotification: (state, action) => {
      if (state.notifications?.length === 0) {
        if (action.payload?.data?.length > 0) {
          state.notifications = [...action.payload?.data];
        } else {
          state.notifications = [action.payload?.data];
        }
      } else {
        let data = state.notifications;

        if (action.payload?.data?.length > 0) {
          state.notifications = [...action?.payload?.data, ...data];
        } else {
          data.unshift(action.payload?.data);
          state.notifications = data;
        }
      }
    },
  },
});

// Reducer
export default slice.reducer;

export const { setNotifications, setWebSocketNotification } = slice.actions;

export function getNotifications() {
  return async (dispatch) => {
    try {
      const response = await TrackerApi.get(`/notifications/list/unread`);
      dispatch(slice.actions.setNotifications(response.data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function MarkNotificationRead(id) {
  return async (dispatch) => {
    try {
      const response = await TrackerApi.patch(`/notifications/${id}/read`);
      dispatch(getNotifications());
    } catch (error) {
      console.log(error);
    }
  };
}

export function MarkAllNotificationRead(id) {
  return async (dispatch) => {
    try {
      const response = await TrackerApi.patch(`/notifications/all/read`);
      dispatch(getNotifications());
    } catch (error) {
      console.log(error);
    }
  };
}
