// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const ar = {
  demo: {
    title: `Arabic`,
    introduction: `لوريم إيبسوم هو ببساطة نص شكلي يستخدم في صناعة الطباعة والتنضيد. كان Lorem Ipsum هو النص الوهمي القياسي في الصناعة منذ القرن الخامس عشر الميلادي ، عندما أخذت طابعة غير معروفة لوحًا من النوع وتدافعت عليه لعمل كتاب عينة. لقد نجت ليس فقط خمسة قرون ، ولكن أيضًا القفزة في التنضيد الإلكتروني ، وظلت دون تغيير جوهري. تم نشره في الستينيات من القرن الماضي بإصدار أوراق Letraset التي تحتوي على مقاطع Lorem Ipsum ، ومؤخرًا مع برامج النشر المكتبي مثل Aldus PageMaker بما في ذلك إصدارات Lorem Ipsum.`,
  },
  docs: {
    hi: `أهلا`,
    description: `هل تحتاج لمساعدة؟ \n الرجاء مراجعة مستنداتنا`,
    documentation: `توثيق`,
  },
  app: `تطبيق`,
  user: `المستخدم`,
  list: `قائمة`,
  edit: `تعديل`,
  shop: `متجر`,
  blog: `مقال`,
  post: `مشاركة`,
  mail: `بريد`,
  chat: `محادثة`,
  cards: `البطاقات`,
  posts: `المشاركات`,
  create: `إنشاء`,
  kanban: `جدول`,
  general: `عام`,
  banking: `الخدمات المصرفية`,
  booking: `الحجز`,
  profile: `الملف الشخصي`,
  account: `الحساب`,
  product: `المنتج`,
  invoice: `فاتورة`,
  details: `تفاصيل`,
  checkout: `الدفع`,
  calendar: `التقويم`,
  analytics: `التحليلات`,
  ecommerce: `التجارة الإلكترونية`,
  management: `إدارة`,
  menu_level: `مستوى القائمة `,
  menu_level_2a: `مستوى القائمة 2 أ`,
  menu_level_2b: `مستوى القائمة 2 ب`,
  menu_level_3a: `مستوى القائمة 3 أ`,
  menu_level_3b: `مستوى القائمة 3 ب`,
  menu_level_4a: `مستوى القائمة 4 أ`,
  menu_level_4b: `مستوى القائمة 4 ب`,
  item_disabled: `العنصر معطل`,
  item_label: `تسمية العنصر`,
  item_caption: `عنوان العنصر`,
  item_external_link: `رابط خارجي للعنصر`,
  description: `وصف`,
  other_cases: `حالات اخرى`,
  item_by_roles: `عنصر حسب الأدوار`,
  only_admin_can_see_this_item: `يمكن للمسؤول فقط رؤية هذا العنصر`,
  transport_type: `نوع النقل`,
  shipment_type: `نوع الشحن`,
  climate_control: `التحكم في المناخ`,
  humidity: `رطوبة`,
  temp: `درجة الحرارة`,
  container_size: `حجم الحاوية`,
  land_transport_method: `طريقة النقل البري`,
  container: `الحاوية`,
  dry: `جاف`,
  frozen: `مجمدة`,
  refregirated: `مبردة`,
  boxes: `صناديق`,
  bulk: `فرط`,
  dimentions: `الأبعاد (سم)`,
  height: `الارتفاع`,
  width: `العرض`,
  length: `الطول`,
  Van: `فان`,
  ft40: `40 قدم`,
  ftHC40: `40 قدم HC`,
  ft20: `20 قدم`,
  Dyna_Truck: `داينا شاحنة`,
  Lowbed_Truck: `شاحنة لوبيد`,
  Flatbed_Truck: `شاحنة سطحة`,
  Details: `تفاصيل`,
  Name: `الاسم`,
  value: `القيمة`,
  quantity: `كمية`,
  total_completed_orders: `إجمالي الطلبات المكتملة`,
  total_in_progress_orders: `إجمالي الطلبات قيد التنفيذ`,
  total_canceled_orders: `إجمالي الطلبات الملغاة`,
  orders: `الطلبات`,
  in_progress_orders: `الطلبات الجارية`,
  completed_orders: `الطلبات المكتملة`,
  than_last_year: `من العام الماضي`,
  dashbord: `لوحة التحكم`,
  services: `خدمات`,
  review_order_details: `مراجعة تفاصيل الطلب`,
  there_is_no_order_to_submit: `لا يوجد طلب مقدم`,
  item: `عنصر`,
  add_another_service: `أضف خدمة أخرى`,
  submit_order: `تقديم الطلب`,
  notifications: `إشعارات`,
  you_have: `لديك`,
  unread_messages: `رسائل غير مقروءة`,
  active_orders: `الطلبات النشطة`,
  history_orders: `الطلبات السابقة`,
  settings: `الإعدادات`,
  logout: `تسجيل خروج`,
  add_order_details: `أضف تفاصيل الطلب`,
  service: `خدمة`,
  reigon: `منطقة`,
  status: `حالة`,
  time_created: `تاريخ الإنشاء`,
  no_data: `لاتوجد بيانات`,
  create_order: `إنشاء طلب`,
  please_select_a_region: `الرجاء تحديد منطقة`,
  Handle_the_Custom_clearance_process_and_documentation: `التعامل مع عملية التخليص الجمركي والتوثيق`,
  Customs_Clearance: `التخليص الجمركي`,
  Sea_Shipping: `الشحن البحري`,
  Provide_Large_Scale_Shipping_from_Overseas: `توفير الشحن على نطاق واسع من الخارج`,
  Provide_All_kinds_of_transportation_in_KSA: `توفير جميع أنواع المواصلات في المملكة العربية السعودية`,
  Local_Land_Transportation: `النقل البري المحلي`,
  Jeddah_Islamic_Port: `ميناء جدة الإسلامي`,
  Jeddah: `جدة`,
  Saudi_Arabia: `المملكة العربية السعودية`,
  Germany: `ألمانيا`,
  Frankfort: `فرانكفورت`,
  Frankfurt_International_Airpot: `مطار فرانكفورت الدولي`,
  Jeddah_Ground_Shipping: `جدة للشحن البري`,
  Port_of_Los_Angeles: `ميناء لوس أنجلوس`,
  Los_Angeles: `لوس أنجلوس`,
  United_States: `الولايات المتحدة`,
  City: `المدينة`,
  Country: `الدولة`,
  Create_a_new_order: `أنشئ طلب جديد`,
  Remove: `حذف`,
  Add_Item: `إضافة عنصر`,
  id: `المعرف`,
  type: `النوع`,
  order_type: `نوع الطلب`,
  order_number: `رقم الطلب`,
  date: `التاريخ`,
  price: `السعر`,
  offers: `العروض`,
  new: `جديد`,
  draft: `مسودة`,
  Waiting_Offers: `بانتظار العروض`,
  completed: `مكتمل`,
  cancelled: `ملغى`,
  Supplier_Number: `رقم المورد`,
  Supplier_Rank: `تصنيف المورد`,
  Offer_Price: `سعر العرض`,
  Delete: `حذف`,
  Accept: `قبول`,
  ReOrder: `إعادة الطلب`,
  View: `معاينة`,
  View_more: `استعرض المزيد`,
  Supplier: `المورد`,
  reject: `رفض`,
  wait_bids: `بانتظار العروض`,
  wait_user_accept: `بانتظار قبول المستخدم`,
  expense: `مصاريف`,
  cost: `التكلفة`,
  confirmed: `تم التأكيد`,
  offer: `العرض`,
  state: `الحالة`,
  accepted: `مقبول`,
  pending: `قيد الانتظار`,
  rejected: `مرفوض`,
  auto_rejected: `تم الرفض التلقائي`,
  have_saber: `شهادة سابر`,
  origin_sea_port: `الميناء البحري للانطلاق`,
  destination_sea_port: `الميناء البحري للوصول`,
  origin_airport: `مطار الانطلاق`,
  origin_land_port: `الميناء البري للانطلاق`,
  destination_land_port: `الميناء البري للوصول`,
  expected_weight: `الوزن المتوقع`,
  service_type: 'شروط الشحن',
  date_of_readiness: `تاريخ الجاهزية`,
  hazardous: `خطير`,
  location: `الموقع`,
  get_location: `الحصول على الموقع`,
  commodity: `سلعة`,
  from: `من`,
  to: `إلى`,
  summary: `موجز`,
  documents: `المستندات`,
  shiperContactDetails: `معلومات الشاحن`,
  communication: `التواصل`,
  origin: 'المنشأ',
  destination: 'وجهة',
  make_offer: 'تقديم عرض',
  create_service: 'إنشاء خدمة',
  add_field: 'إضافة حقل',
  fee_name: 'نوع الرسوم',
  fee_charge: 'تكلفة الرسوم',
  submit: 'قدم',
  note: 'ملحوظة',
  order_details: 'تفاصيل الطلب',
  view_details: 'عرض التفاصيل',
  created_at: 'أنشئت في',
  required: 'مطلوب',
  operation_successful: 'تمت العملية بنجاح',
  operation_failed: 'فشلت العملية',
  client: 'عميل',
  service_provider: 'مقدم الخدمة',
  admin: 'المسؤول',
  select_service: 'اختر الخدمة',
  port: 'ميناء',
  enter: 'ادخل',
  required_documents: 'المستندات المطلوبة',
  file_name: 'اسم الملف',
  file: 'ملف',
  registered_services: 'خدمات التسجيل',
  orderSummary: 'ملخص الطلب',
  orderDetails: 'تفاصيل الطلب',
  deliveryAddress: 'عنوان التسليم',
  region: 'منطقة',
  createdDate: 'تاريخ الإنشاء',
  lastUpdate: 'آخر تحديث',
  register_account: 'تسجيل حساب جديد',
  first_name: 'الاسم الأول',
  last_name: 'اسم العائلة',
  email: 'بريد إلكتروني',
  password: 'كلمة المرور',
  type_of_account: 'نوع الحساب',
  enter_cr: ' ادخل السجل التجاري',
  sign_up: 'إنشاء حساب',
  register_a_new_service: 'سجل خدمة جديدة',
  select_region: 'اختر المنطقة',
  apply: 'تقديم',
  search: 'ابحث',
  search_for_offer: 'ابحث عن عرض',
  view_all: 'مشاهدة الكل',
  offer_name: 'اسم العرض',
  offer_id: 'معرف العرض',
  offer_date: 'تاريخ العرض',
  total_price: 'السعر الكلي',
  offers_expenses: 'مصاريف العرض',
  read: 'إقرأ',
  expense_details: 'تفاصيل المصاريف',
  add_an_expense: 'أضف مصروف',
  item_name: 'اسم العنصر',
  initiated: 'بدأت',
  waiting_acceptance: 'انتظار القبول',
  upload: 'رفع',
  expenses: 'مصاريف',
  actions: 'إجراءات',
  are_you_sure: 'هل أنت متأكد؟',
  yes_send_offer: 'نعم، أرسل العرض',
  success: 'نجاح',
  your_offer_sent: 'تم إرسال عرضك',
  error: 'خطأ',
  send_offer: 'إرسال العرض',
  national_id: 'الهوية الوطنية',
  drag_and_drop: 'قم بسحب وإسقاط ملفك أو',
  browse: 'تصفح',
  to_upload: 'للتحميل',
  supported_formats: 'التنسيق المدعوم هو PDF',
  cr: 'السجل التجاري',
  sar: 'ريال',
  add_offer: 'أضف العرض',
  add: 'أضف',
  auto_rejected_tooltip: 'الرفض التلقائي يعني أن العميل قد اختار عرض آخر',
  successfully_registered: 'سجلت بنجاح',
  custom_clearance_licence: 'رخصة التخليص الجمركي',
  sea_shipping: 'الشحن البحري',
  order_created: 'تم إنشاء الطلب',
  order_time: 'وقت الطلب',
  total: 'المجموع',
  '10%': '١٠%',
  shipping: 'الشحن',
  customs_clearance_description: 'التعامل مع عملية التخليص الجمركي والمستندات',
  shipping_description: 'توفير الشحن على نطاق واسع من الخارج',
  local_land_description: 'توفير جميع أنواع النقل من وإلى المنافذ السعودية',
  local_transportation: 'النقل البري المحلي',
  action: 'أمر',
  name: 'اسم',
  yes: 'نعم',
  no: 'لا',
  find_location: 'البحث عن الموقع',
  sea: 'بحري',
  air: 'جوي',
  land: 'بري',
  metric_tons: 'طن متري',
  kilogram: 'كيلوغرام',
  gps_coord: 'احداثيات نظام تحديد الموقع',
  order_submitted_successfully: 'تم تقديم الطلب بنجاح',
  upload_document: 'تحميل الوثيقة',
  reason_for_rejection: 'سبب الرفض',
  reason: 'السبب',
  save: 'حفظ',
  cancel: 'إلغاء',
  offer_reject_auto: 'تم رفض العرض تلقائيًا',
  offer_reject: 'العرض مرفوض',
  offer_details: 'تفاصيل العرض',
  bid_id: 'معرف العرض',
  would_you_like_local_transport: 'هل ترغب في النقل المحلي؟',
  get_gps_coord: ' احصل على إحداثيات نظام تحديد المواقع',
  confirm: 'تأكيد',
  freight_fowarder_broker_license: 'رخصة وسيط وكيل الشحن',
  road_freight_transport_licence: 'رخصة نقل البضائع على الطرق',
  consignee_contact_details: 'معلومات المرسل إليه',
  company_name: 'اسم الشركة',
  company_address: 'عنوان الشركة',
  district: 'الحي',
  street_name: 'اسم الشارع',
  postal_code: 'رمز بريدي',
  po_box_number: 'رقم صندوق البريد',
  contact_details: 'معلومات الإتصال',
  contact_person: 'اسم المسؤول',
  mobile_number: 'رقم الهاتف المحمول',
  vat_number: 'رقم ضريبة القيمة المضافة',
  add_new_shipping_contact: 'إضافة جهة اتصال شحن جديدة',
  add_new_consignee_contact: 'إضافة جهة اتصال المرسل إليه الجديدة',
  vat_length: 'يجب أن تتكون ضريبة القيمة المضافة من 15 رقمًا',
  cr_length: 'يجب أن يحتوي السجل التجاري على 10 أرقام',
  draft_bl: 'مشروع بوليصة الشحن',
  final_bl: 'بوليصة الشحن النهائية',
  final_service_invoice: 'فاتورة الخدمة النهائية',
  change_file: 'تغيير الملف',
  info: 'المعلومات',
  upload_file: 'رفع ملف',
  document_name: 'اسم الوثيقة',
  document_type: 'نوع الوثيقة',
  document_date: 'تاريخ الوثيقة',
  last_modified: 'آخر تعديل',
  related_documents: 'الوثائق ذات الصلة',
  add_document: 'إضافة وثيقة',
  no_files: 'لايوجد ملفات',
  request_file: 'طلب ملف',
  file_type: 'نوع الملف',
  pdf: 'بي دي إف',
  start: 'إبدأ',
  complete: 'مكتمل',
  in_progress: 'تحت الإجراء',
  add_contact: 'إضافة جهة اتصال',
  address: 'العنوان',
  tag: 'بطاقة شعار',
  serial_number: 'رقم تسلسلي',
  disclaimer_liability: 'أتعهد بإخلاء المسؤولية في حالة عدم توفر الشهادة المطلوبة',
  latitude: 'خط العرض',
  longitude: 'خط الطول',
  Destination_Address: 'عنوان الوجهة',
  bill_of_lading: 'بوليصة الشحن',
  purchase_invoice_goods: 'فاتورة شراء البضائع',
  saber_certificate: 'شهادة سابر',
  customs_release_doc: 'بيان الفسح الجمركي',
  final_service_invoice_for_custom: 'فاتورة الخدمة النهائية',
  custom_release_doc_land_trans: 'بيان الفسح الجمركي',
  final_service_invoice_land_trans: 'فاتورة الخدمة النهائية',
  no_orders: 'لا توجد طلبات',
  back: 'السابق',
  'Order Created': 'تم إنشاء الطلب',
  order: 'طلب',
  'Customs Clearance': `التخليص الجمركي`,
  'Local Land Transportation': `النقل البري المحلي`,
  'Sea Shipping': 'الشحن البحري',
  Sea: 'بحري',
  Air: 'جوي',
  Land: 'بري',
  'user management': 'إدارة المستخدم',
  group: 'مجموعة',
  role: 'دور',
  permission: 'الإذن',
  create_user: 'إنشاء مستخدم',
  search_user: 'بحث بالمستخدم',
  create_group: 'إنشاء مجموعة',
  search_group: 'بحث بالمجموعة',
  group_name: 'اسم المجموعة',
  group_description: 'وصف المجموعة',
  search_role: 'دور البحث',
  create_role: 'إنشاء دور',
  role_name: 'اسم الدور',
  role_description: 'وصف الدور',
  operations: 'عمليات',
  finance: 'مالية',
  sales: 'مبيعات',
  marketing: 'تسويق',
  customer_care: 'العناية بالعميل',
  enter_valid_email: 'أدخل بريدًا إلكترونيًا صالحًا',
  pass_length: 'يجب أن تتكون كلمة المرور من 8 أحرف على الأقل',
  permission_name: 'اسم الصلاحية',
  permission_description: 'وصف الصلاحية',
  create_permission: 'إنشاء الصلاحية',
  search_permission: 'بحث بالصلاحية',
  add_user_to_group: 'إضافة مستخدم إلى المجموعة',
  remove_user_group: 'إزالة المستخدم من المجموعة',
  view_groups: 'عرض المجموعات',
  remove_user: 'إزالة المستخدم',
  add_user: 'إضافة مستخدم',
  add_permission: 'إضافة صلاحية',
  remove_permission: 'إزالة الصلاحية',
  update_group: 'تحديث المجموعة',
  delete_group: 'حذف المجموعة',
  add_permission_to_groups: 'إضافة صلاحيات إلى المجموعة',
  permissions: 'الصلاحيات',
  remove_permission_from_group: 'إزالة الصلاحية من المجموعة',
  view_permissions: 'عرض الصلاحيات',
  update: 'تحديث',
  delete_permission: 'حذف صلاحية',
  add_permission_to_user: 'إضافة صلاحية للمستخدم',
  remove_permission_from_user: 'إزالة الصلاحية من المستخدم',
  search_orders: 'تقسيمات البحث',
  flag_order: 'ترتيب العلم',
  issue_final_invoice: 'إصدار الفاتورة النهائية',
  service_provider_id: 'معرف مزود الخدمة',
  client_reject_note: 'ملاحظة رفض العميل',
  uploaded_by: 'تم الرفع بواسطة',
  sp_status: 'حالة مقدم الخدمة',
  client_status: 'حالة العميل',
  register: 'تسجيل',
  email_already_registered: 'البريد الإلكتروني مسجل مسبقاً',
  cr_already_registered: 'السجل التجاري مسجل بالفعل',
  time_registered: 'وقت التسجيل',
  account_status: 'حالة الحساب',
  active: 'نشيط',
  in_active: 'غير نشط',
  activate: 'تفعيل',
  deactivate: 'إلغاء التنشيط',
  flag_comment: 'تعليق العلم',
  view_flag: 'عرض العلم',
  final_invoice_admin_shipping: 'الفاتورة النهائية للشحن',
  final_invoice_admin_custom: 'الفاتورة النهائية للتخليص الجمركي',
  final_invoice_admin_land_transport: 'الفاتورة النهائية للنقل المحلي',
  expected_days: 'الأيام المتوقعة لإكمال الطلب',
  expected_days_offer: 'الأيام المتوقعة',
  expected_delivery_time: 'وقت التسليم المتوقع',
  remove_flag: 'إزالة العلم',
  wait_payment: 'في انتظار الدفع',
  confirm_order: 'أكد الطلب',
  offer_accepted: 'تم قبول العرض',
  issue_initial_invoice: 'إصدار الفاتورة الأولية',
  initial_invoice_admin_shipping: 'الفاتورة الأولية للشحن',
  initial_invoice_admin_custom: 'الفاتورة الأولية للتخليص الجمركي',
  initial_invoice_admin_land_transport: 'الفاتورة الأولية للنقل المحلي',
  upload_documents: 'تحميل المستندات',
  users: 'المستخدمين',
  groups: 'المجموعات',
  dashboard_page: 'صفحة لوحة التحكم',
  groups_page: 'صفحة المجموعات',
  orders_page: 'صفحة الطلبات',
  register_page: 'صفحة التسجيل',
  user_management_page: 'صفحة إدارة المستخدم',
  users_page: 'صفحة المستخدمين',
  view_orders: 'يتيح لك هذا الإذن عرض الطلبات، وتوفير رؤى حول تفاعلات العميل ومقدم الخدمة.',
  view_user_management:
    'يمنح هذا الإذن الوصول إلى قسم إدارة المستخدم الذي يتضمن المستخدمين والمجموعات.',
  deleted: 'تم الحذف',
  Shipping: 'الشحن',
  'waredat.sa': 'واردات',
  Jan: 'يناير',
  Feb: 'فبراير',
  Mar: 'مارس',
  Apr: 'أبريل',
  May: 'مايو',
  Jun: 'يونيو',
  Jul: 'يوليو',
  Aug: 'أغسطس',
  Sep: 'سبتمبر',
  Oct: 'اكتوبر',
  Nov: 'نوفمبر',
  Dec: 'ديسمبر',
  login: 'تسجيل الدخول',
  draft_bl_not_accepted_by_client: 'مسودة البوليصة غير مقبولة من قبل العميل',
  upload_final_bl: 'تحميل البوليصة النهائية',
  draft_bl_not_found: 'لم يتم تحميل مسودة البوليصة',
  upload_custom_release_doc: 'إرفع مستند بيان الفسح',
  client_didnt_fill_consignee_contact: 'لم يقم العميل بملء معلومات المرسل إليه',
  client_didnt_fill_shipper_contact: 'لم يقم العميل بملء معلومات الشاحن',
  client_didnt_upload_bl: 'لم يقم العميل بتحميل بوليصة الشحن',
  client_didnt_upload_purch_inv: 'لم يقم العميل بتحميل فاتورة شراء البضائع',
  client_didnt_upload_saber_cert: 'لم يقم العميل بتحميل شهادة سابر',
  client_didnt_upload_cust_release_doc: 'لم يقم العميل بتحميل مستند الفسح الجمركي',
  mobile_number_10_digits: 'رقم الجوال يجب أن يكون 10 أرقام',
  enter_verification_code: 'أدخل رمز التحقق',
  mobile_number_format: '٠٥xxxxxxxx صيغة رقم الجوال',
  mobile_number_taken: 'رقم الجوال مأخوذ',
  verification_code_send_to: 'رمز التحقق أرسل إلى',
  verification_failed: 'فشل التحقق',
  pending_completion: 'في انتظار الانتهاء',
  edit_expense: 'تحرير المصاريف',
  value_incl_vat: 'القيمة (شاملة ضريبة القيمة المضافة)',
  price_incl_vat: 'السعر (شامل ضريبة القيمة المضافة)',
  initial_invoice_issued: 'تم إصدار الفاتورة الأولية',
  final_invoice_issued: 'تم إصدار الفاتورة النهائية',
  draft_bl_uploaded: 'تم تحميل مسودة البوليصة',
  draft_bl_accepted: 'تم قبول مسودة البوليصة',
  draft_bl_rejected: 'تم رفض مشروع البوليصة',
  qty: 'الكمية',
  successfully_verified: 'تم التحقق بنجاح',
  packing_list: 'قائمة التعبئة',
  client_didnt_upload_packing_list: 'لم يقم العميل بتحميل قائمة التعبئة',
  approve_rejection_note: 'الموافقة على مذكرة الرفض',
  transfer_receipt_shipping: 'إيصال التحويل للشحن',
  transfer_receipt_custom: 'إيصال التحويل للتخليص الجمركي',
  transfer_receipt_land: 'إيصال التحويل للنقل المحلي',
  client_didnt_upload_transfer_receipt: 'لم يقم العميل بتحميل إيصال النقل',
  waiting_for_approval: 'بانتظار الموافقة',
  service_regions: 'مناطق الخدمة',
  region_name: 'اسم المنطقة',
  waredat_verified: 'تم التحقق من وردات',
  verified: 'تم التحقق',
  verification_pending: 'التحقق معلق',
  approve_region: 'الموافقة على المنطقة',
  view_documents: 'عرض المستندات',
  close: 'يغلق',
  client_sp: 'العميل/مقدم الخدمة',
  client_sp_page: 'صفحة العميل/مقدم الخدمة',
  rate_service_provider: 'معدل مقدم الخدمة',
  feedback_waredat: 'تعليق لواردات (اختياري)',
  complete_order: 'اكمل الطلب',
  download_pdf: 'تحميل PDF',
  order_time_finished: 'انتهى وقت الطلب',
  order_time_finished_order: 'تواصل مع واردات في حال تأخر إنهاء الطلب',
  support: 'الدعم',
  title: 'الموضوع',
  open_ticket: 'تذكرة مفتوحة',
  search_ticket: 'البحث بالتذكرة',
  attach_images: 'إرفاق الصور',
  max_size_5mb: 'الحد الأقصى للحجم 5 ميجابايت',
  max_file_5: 'الحد الأقصى 5 ملفات',
  order_overdue: 'تأخر الطلب',
  order_time_finished_waredat: 'تأخر مقدم الخدمة في إنهاء الطلب في الوقت المناسب',
  time_updated: 'تم تحديث الوقت',
  view_images: 'عرض الصور',
  support_ticket: 'تذكرة الدعم',
  open: 'فتح',
  delayed: 'تأخير',
  on_hold: 'في الانتظار',
  resolved: 'تم الحل',
  un_resolved: 'لم تحل',
  final_invoice: 'الفاتورة النهائية',
  initial_invoice: 'الفاتورة الأولية',
  assignee: 'المكلف',
  images: 'الصور',
  assign_waredat_employee: 'تعيين موظف وردات للتذكرة',
  assign: 'تعيين',
  Employee: 'موظف',
  ticket_assigned: 'تم تخصيص تذكرة الدعم',
  assignee_already_working: 'أحد المكلفين يعمل بالفعل على التذكرة',
  session_expired: 'انتهت صلاحية الجلسة! ',
  want_to_continue: 'ترغب في الاستمرار ؟ فقط انقر على زر نعم.',
  transfer_receipt_uploaded: 'تم تحميل إيصال التحويل',
  region_approved: 'المنطقة معتمدة',
  approved: 'موافقة',
  vat: 'ضريبة القيمة المضافة',
  reject_region: 'رفض المنطقة',
  region_rejected: 'المنطقة مرفوضة',
  support_page: 'صفحة الدعم',
  file_request: 'طلب الملف',
  message: 'رسالة',
  type_message_here: 'اكتب الرسالة هنا',
  message_received: 'وصلت الرسالة',
  national_address: 'العنوان الوطني',
  vat_file: 'ملف ضريبة القيمة المضافة',
  account_details: 'تفاصيل الحساب',
  email_address: 'عنوان البريد الإلكتروني',
  phone_number: 'رقم التليفون',
  state_region: 'المنطقة',
  zip_code: 'الرمز البريدي',
  about: 'عن',
  cr_number: 'رقم السجل التجاري',
  document: 'مستند',
  replace_file: 'استبدل الملف',
  update_success: 'نجاح التحديث',
  old_chat: 'الدردشة القديمة',
  user_profile: 'ملف تعريفي للمستخدم',
  wait_initial_payment: 'في انتظار الدفع الأولي',
  wait_final_payment: 'في انتظار الدفع النهائي',
  await_user_completion: 'في انتظار اكتمال المستخدم',
  add_additonal_expense: 'إضافة مصاريف إضافية',
  transfer_receipt_custom_final: 'إيصال التحويل النهائي',
  transfer_receipt_land_final: 'إيصال التحويل النهائي',
  transfer_receipt_shipping_final: 'إيصال التحويل النهائي',
  wait_waredat_payment: 'في انتظار التحويل من واردات',
  payment_cleared: 'تم الدفع',
  payment_confirmed: 'تم تأكيد عملية الدفع',
  receipt: 'إيصال',
  await_user_payment_confirmation: 'في انتظار تأكيد الدفع',
  import: 'إستيراد',
  export: 'تصدير',
  container_type: 'نوع الحاوية',
  shipping_terms: 'شروط الشحن',
  shipping_type: 'نوع الشحن',
  signin_to_waredat: 'تسجيل الدخول في منصة واردات',
  new_user: 'مستخدم جديد؟',
  create_a_account: 'إنشاء حساب جديد',
  forgot_password: 'نسيت كلمة المرور؟',
  welcome_back_to: 'مرحباً بك في',
  waredat_platform: 'منصة واردات',
  already_have_an_account: 'لديك حساب مسبقاً!',
  sign_in: 'تسجيل الدخول',
  cr_signup: 'السجل التجاري',
  transportType: `نوع النقل`,
  continue_end_session: 'أستمر أو أنهي الجلسة',
  temperature: 'درجة الحرارة',
  local: 'محلي',
  saber: 'سابر',
  hazard: 'خطر',
  custom_local_transport: 'تخليص جمركي + نقل محلي',
  local_custom_clearacnce: 'نقل محلي + تخليص جمركي',
  cargo_type: 'نوع البضائع',
  CBM: 'سي. بي. إم',
  kg: 'كجم',
  port_type: 'نوع المنفذ',
  port_licence: 'رخصة الميناء',
  notify_party: 'تذكير بشأن الحفلة',
  information: 'معلومات',
  waredat_transfer_receipt: 'إيصال حوالة واردات',
  order_info_updated: 'تم تحديث معلومات الطلب',
  file_request_uploaded: 'تم تحميل طلب الملف',
  file_request_accept_rejected: 'قبول أو رفض الملف المطلوب',
  file_request_client_upload: 'لم يقم العميل بتحميل الملف المطلوب',
  waredat_receipt: 'إيصال واردات',
  order_files_uploaded: 'تم تحميل ملفات الطلب',
  'Land Transportation': 'النقل البرى',
  Land_Transportation: 'النقل البرى',
  return_to_sign_in: 'عودة الى التسجيل',
  request_sent_successfully: 'تم إرسال الطلب بنجاح',
  reset_password_link_email: 'لقد قمنا بارسال رابط إعادة الباسورد الى ايميلك المسجل',
  reset_password_text: 'من فضلك ادخل الايميل المسجل بحسابك وسنقوم بارسال رابط إعادة الباسورد',
  send_request: 'ارسال الطلب',
  enter_new_password: 'أدخل الرقم السرى',
  re_enter_password: 'أعد إدخال كلمة السر',
  passwords_not_match: 'الرقم السرى غير متطابق',
  reset_link_expired: 'رابط إعادة الضبط انتهى',
  reset_link_expired_text: 'رابط إعادة كلمة السر انتهى',
  password_reset_successful: 'تمت إعادة كلمة السر بنجاح',
  password_reset_limit: 'الحد المسموح لاعادة كلمة السر',
  please_contact_waredat_support: 'من فضلك تواصل مع دعم واردات',
  password_reset_link_invalid: 'رابط إعادة تعيين كلمة المرور غير صالح',
  upload_files: 'تحميل الملفات',
  upload_vat_national_address: 'حدث ضريبة القيمة المضافة و العنوان الوطنى',
  account_type: 'نوع الحساب',
  super_user: 'هذه ما اعرفها',
  waredat_employee: 'موظف واردات',
  order_missed: 'الطلب مفقود',
  change_password: 'تغيير الرقم السرى',
  old_password: 'الرقم السرى القديم',
  final_transfer_receipt_uploaded: 'إيصال التحويل الأخير تم تحميله',
  new_password: 'رقم سري جديد',
  re_enter_new_password: 'اعد ادخال كلمة السر الجديدة',
  order_id: 'رقم الطلب',
  expected_finish_date: 'التاريخ المتوقع لانتهاء الطلب',
  confirmed_date: 'تاريخ الطلب المؤكد',
  order_in_progress: 'الطلب قيد المعالجة',
  reject_rejection_note: 'رفض ملاحظة الرفض',
  review_client_reject_note: 'اظهار ملاحظة الرفض للعميل',
  cr_not_active: 'السجل التجارى غير فعال',
  additional_expense_receipt: 'إيصال المصاريف الإضافية',
  disclaimer: 'تنبيه',
  accurate_info_liability: 'أقر وأؤكد أن المعلومات التي قدمتها صحيحة',
  pickup_address: 'عنوان التحميل',
  reject_offer: 'رفض العرض',
  retract_offer: 'استرجاع العرض',
  warning_text_retract_offer: 'هل أنت واثق من استرجاعك للعرض ؟',
  delete_offer: 'حذف العرض',
  warning_text_delete_offer: 'هل أنت واثق من حذف العرض ؟',
  cancel_order: 'الغاء الطلب',
  cancel_order_text: 'هل انت واثق من انك تريد الغاء الطلب ؟',
  cancelled_by_client: 'تم الإلغاء من قبل العميل',
  cancelled_by_sp: 'تم الإلغاء من قبل مقدم الخدمة',
  approve_cancel_order_request: 'الموافقة على الغاء الطلب',
  reject_cancel_order_request: 'الغاء الطلب مرفوض',
  review_cancel_order_request: 'مراجعة طلب الإلغاء',
  order_cancellation_request_review: 'جاري مراجعة طلب الإلغاء',
  order_cancel_request_approved: 'تمت الموافقة على طلب الإلغاء',
  order_cancel_request_rejected: 'تم رفض طلب الإلغاء',
  order_cancelled: 'تم الغاء الطلب',
  cancel_order_text_request: 'هل ترغب بإرسال طلب الإلغاء ؟',
  have_issue: 'توجد مشكلة',
  have_issue_text: 'هل أنت متأكد من وجود مشكلة في الطلب ؟',
  delay_issue_text: 'هل ترغب بتأجيل تقديم الطلب ؟',
  resume: 'استكمال',
  order_delayed: 'تم تأجيل تقديم الطلب',
  order_resumed: 'تم استكمال الطلب',
  delayed_waiting_client_inputs: 'تم التأجيل بانتظار معلومات من العميل',
  resolving_issue: 'جاري معالجة المشكلة',
  resovled: 'تم حل المشكلة',
  issue_resolved: 'تم حل المشكلة',
  invalid: 'غير صحيح',
  same_as_notify_party: 'إشعار المستلم نفسه',
  reason_change_delivery_time: 'سبب تغيير تاريخ التوصيل',
  delivery_time_changed: "تم تغيير تاريخ التوصيل"
};
export default ar;
