/* eslint-disable */
// routes
import { permissions } from '../../../utils/serviceUtils';
import { PATH_DASHBOARD } from '../../../routes/paths';

// components
import SvgColor from '../../../components/svg-color';
import { uniqBy } from 'lodash';
import { element } from 'prop-types';
// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  dashboard: icon('ic_dashboard'),
  file: icon('ic_file'),
  ecommerce: icon('ic_ecommerce'),
  user: icon('ic_user'),
  support: icon('customer-support-1-solid'),
  register: icon('register'),
};

const navConfig = [
  {
    items: [
      { title: 'dashbord', path: PATH_DASHBOARD.clientDashboard, icon: ICONS.dashboard },
      { title: 'services', path: PATH_DASHBOARD.clientServices, icon: ICONS.file },
      { title: 'orders', path: PATH_DASHBOARD.clientOrders, icon: ICONS.ecommerce },
      { title: 'support', path: PATH_DASHBOARD.clientSupport, icon: ICONS.support },
    ],
  },
];

export default navConfig;

export const serviceProviderConfig = [
  {
    items: [
      { title: 'dashbord', path: PATH_DASHBOARD.serviceProviderDashboard, icon: ICONS.dashboard },
      { title: 'services', path: PATH_DASHBOARD.serviceProviderServices, icon: ICONS.file },
      { title: 'orders', path: PATH_DASHBOARD.serviceProviderOrders, icon: ICONS.ecommerce },
      { title: 'support', path: PATH_DASHBOARD.serviceProviderSupport, icon: ICONS.support },
    ],
  },
];

export const superAdminConfig = [
  {
    items: [
      { title: 'dashbord', path: PATH_DASHBOARD.superAdminDashboard, icon: ICONS.dashboard },
      { title: 'client_sp', path: PATH_DASHBOARD.superAdminRegister, icon: ICONS.register },
      {
        title: 'user management',
        path: PATH_DASHBOARD.superAdminUserManagement,
        icon: ICONS.user,
        children: [
          { title: 'user', path: PATH_DASHBOARD.superAdminCreateUser },
          { title: 'group', path: PATH_DASHBOARD.superAdminCreateGroup },
          { title: 'permission', path: PATH_DASHBOARD.superAdminCreatePermission },
        ],
      },
      { title: 'orders', path: PATH_DASHBOARD.superAdminOrders, icon: ICONS.ecommerce },
      { title: 'support', path: PATH_DASHBOARD.superAdminSupport, icon: ICONS.support },
    ],
  },
];

export const superAdminConfigFn = (user) => {
  let config = [
    {
      items: [
        { title: 'dashbord', path: PATH_DASHBOARD.superAdminDashboard, icon: ICONS.dashboard },
      ],
    },
  ];

  if (user?.is_superuser === true) {
    config = [
      {
        items: [
          { title: 'dashbord', path: PATH_DASHBOARD.superAdminDashboard, icon: ICONS.dashboard },
          { title: 'client_sp', path: PATH_DASHBOARD.superAdminRegister, icon: ICONS.register },
          {
            title: 'user management',
            path: PATH_DASHBOARD.superAdminUserManagement,
            icon: ICONS.user,
            children: [
              { title: 'users', path: PATH_DASHBOARD.superAdminCreateUser },
              { title: 'groups', path: PATH_DASHBOARD.superAdminCreateGroup },
              { title: 'permission', path: PATH_DASHBOARD.superAdminCreatePermission },
            ],
          },
          { title: 'orders', path: PATH_DASHBOARD.superAdminOrders, icon: ICONS.ecommerce },
          { title: 'support', path: PATH_DASHBOARD.superAdminSupport, icon: ICONS.support },
        ],
      },
    ];
  } else if (user?.user_type === 'waredat') {
    if (user?.groups?.length > 0) {
      const waredatPermissions = [];
      user?.groups?.forEach((e) => {
        [...user.user_permission, ...e.permissions]?.forEach((a) => waredatPermissions.push(a));
      });
      let filteredPermissions = uniqBy(waredatPermissions, (i) => i.name);
      let sortedPermission = filteredPermissions?.sort(
        (a, b) => new Date(a.time_created) - new Date(b.time_created)
      );
      sortedPermission?.forEach((q) => checkPermissionNav([q], config));
    } else {
      checkPermissionNav([...user.user_permission], config);
    }
  }

  return config;
};

const checkPermissionNav = (permission, config) => {
  if (permission.some((e) => e?.name === permissions.view_client_sp)) {
    config?.[0]?.items?.push({
      title: 'client_sp',
      path: PATH_DASHBOARD.superAdminRegister,
      icon: ICONS.register,
    });
  }

  if (
    permission.some((e) => e?.name === permissions.view_user_management) ||
    permission.some((e) => e?.name === permissions.view_users) ||
    permission.some((e) => e?.name === permissions.view_groups)
  ) {
    const data = {
      title: 'user management',
      path: PATH_DASHBOARD.superAdminUserManagement,
      icon: ICONS.user,
      children: [],
    };

    if (permission.some((e) => e?.name === permissions.view_user_management)) {
      data.children = [
        { title: 'users', path: PATH_DASHBOARD.superAdminCreateUser },
        { title: 'groups', path: PATH_DASHBOARD.superAdminCreateGroup },
      ];
    }
    if (permission.some((e) => e?.name === permissions.view_users)) {
      data.children.push({ title: 'users', path: PATH_DASHBOARD.superAdminCreateUser });
    }
    if (permission.some((e) => e?.name === permissions.view_groups)) {
      data.children.push({ title: 'groups', path: PATH_DASHBOARD.superAdminCreateGroup });
    }

    config?.[0]?.items?.push(data);
  }

  if (permission.some((e) => e?.name === permissions.view_orders)) {
    config?.[0]?.items?.push({
      title: 'orders',
      path: PATH_DASHBOARD.superAdminOrders,
      icon: ICONS.ecommerce,
    });
  }

  if (permission.some((e) => e?.name === permissions.view_support)) {
    config?.[0]?.items?.push({
      title: 'support',
      path: PATH_DASHBOARD.superAdminSupport,
      icon: ICONS.support,
    });
  }
};
