import { createSlice } from '@reduxjs/toolkit';
import TrackerApi from '../../api/index';
// ----------------------------------------------------------------------

const initialState = {
  orders: [],
  page: 1,
  totalItems: 0,
  loading: false,
};

const slice = createSlice({
  name: 'historyOrders',
  initialState,
  reducers: {
    setHistoryOrder: (state, action) => {
      state.orders = action.payload;
    },
    setHistoryOrdersLoading: (state, action) => {
      state.loading = action.payload;
    },
    setTotalItems: (state, action) => {
      state.totalItems = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const { setHistoryOrder, setHistoryOrdersLoading, setTotalItems, setPage } = slice.actions;

export function getHistoryOrders(page) {
  return async (dispatch) => {
    try {
      dispatch(setHistoryOrdersLoading(true));
      dispatch(setPage(page));
      const response = await TrackerApi.get('/orders/clients/completed', {
        params: { skip: (page - 1) * 10, limit: 10 },
      });
      dispatch(setTotalItems(Math.ceil(Number(response?.data?.metadata?.total_elements) / 10)));
      dispatch(setHistoryOrder(response.data.data));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setHistoryOrdersLoading(false));
    }
  };
}
