// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  demo: {
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  // docs: {
  //   hi: `Hi`,
  //   description: `Need help? \n Please check our docs.`,
  //   documentation: `documentation`,
  // },
  app: `app`,
  user: `user`,
  list: `list`,
  edit: `Edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `Chat`,
  cards: `cards`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `Details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level: `menu level`,
  menu_level_2a: `menu level 2a`,
  menu_level_2b: `menu level 2b`,
  menu_level_3a: `menu level 3a`,
  menu_level_3b: `menu level 3b`,
  menu_level_4a: `menu level 4a`,
  menu_level_4b: `menu level 4b`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  item_external_link: `item external link`,
  description: `Description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
  transport_type: `Transport Type`,
  shipment_type: `Shipment Type`,
  climate_control: `Climate Control`,
  humidity: `Humidity`,
  temp: `Temperature`,
  container_size: `Container Size`,
  land_transport_method: `Land Transport Method`,
  container: `Container`,
  dry: `Dry`,
  frozen: `Frozen`,
  refregirated: `Refregirated`,
  boxes: `Boxes`,
  bulk: `Bulk`,
  dimentions: `Dimensions(cm)`,
  height: `Height`,
  width: `Width`,
  length: `Length`,
  Van: `Van`,
  ft40: `40ft`,
  ftHC40: `40ft HC`,
  ft20: `20ft`,
  Dyna_Truck: `Dyna Truck`,
  Lowbed_Truck: `Lowbed Truck`,
  Flatbed_Truck: `Flatbed Truck`,
  Details: `Details`,
  Name: `Name`,
  value: `Value`,
  quantity: `Quantity`,
  total_completed_orders: `Total Completed Orders`,
  total_in_progress_orders: `Total In-Progress Orders`,
  total_canceled_orders: `Total Canceled Orders`,
  orders: `Orders`,
  in_progress_orders: `In-Progress Orders`,
  completed_orders: `Completed Orders`,
  than_last_year: `than last year`,
  dashbord: `Dashboard`,
  services: `Services`,
  review_order_details: `Review Order Details `,
  there_is_no_order_to_submit: `There is no order to submit`,
  item: `item`,
  add_another_service: `Add another service`,
  submit_order: `Submit Order`,
  notifications: `Notifications`,
  you_have: `You have`,
  unread_messages: `unread messages`,
  active_orders: `Active Orders`,
  history_orders: `History Orders`,
  settings: `Settings`,
  logout: `Logout`,
  add_order_details: `Create Order`,
  service: `Service`,
  reigon: `Region`,
  status: `Status`,
  time_created: `Time created`,
  no_data: `No Data`,
  create_order: `Create Order`,
  please_select_a_region: `Please select a region`,
  Handle_the_Custom_clearance_process_and_documentation: `Handle the Custom clearance process and documentation`,
  Customs_Clearance: `Customs Clearance`,
  Sea_Shipping: `Shipping`,
  Provide_Large_Scale_Shipping_from_Overseas: `Provide Large Scale Shipping from Overseas`,
  Provide_All_kinds_of_transportation_in_KSA: `Provide All kinds of transportation in KSA`,
  Local_Land_Transportation: `Local Land Transportation`,
  Jeddah_Islamic_Port: `Jeddah Islamic Port`,
  Jeddah: `Jeddah`,
  Saudi_Arabia: `Saudi Arabia`,
  Germany: `Germany`,
  Frankfort: `Frankfort`,
  Frankfurt_International_Airpot: `Frankfurt International Airpot`,
  Jeddah_Ground_Shipping: `Jeddah Ground Shipping`,
  Port_of_Los_Angeles: `Port of Los Angeles	`,
  Los_Angeles: `Los Angeles`,
  United_States: `United States`,
  City: `City`,
  Country: `Country`,
  Create_a_new_order: `Create a new order`,
  Remove: `Remove`,
  Add_Item: `Add Item`,
  id: `ID`,
  type: `Type`,
  order_type: `Order Type`,
  order_number: `Order Number`,
  date: `Date`,
  price: `Price`,
  offers: `Offers`,
  upload_doc: `Upload Doc`,
  actions: `Actions`,
  new: `New`,
  draft: `Draft`,
  Waiting_Offers: `Waiting Offers`,
  completed: `Completed`,
  cancelled: `Cancelled`,
  Supplier_Number: `Supplier Number`,
  Supplier_Rank: `Supplier Rank`,
  Offer_Price: `Offer Price`,
  Delete: `Delete`,
  Accept: `Accept`,
  ReOrder: `Re-order`,
  View: `View`,
  View_more: `View more`,
  Supplier: `Supplier`,
  reject: `Reject`,
  wait_bids: `Waiting Offers`,
  wait_user_accept: `Waiting user accept`,
  expense: `Expense`,
  cost: `Cost`,
  confirmed: `Confirmed`,
  offer: `Offer`,
  state: `State`,
  accepted: `Accepted`,
  pending: `Pending`,
  rejected: `Rejected`,
  auto_rejected: `Auto Rejected`,
  have_saber: `Have Saber`,
  origin_sea_port: `Origin Sea Port`,
  destination_sea_port: `Destination Sea Port`,
  origin_airport: `Origin Airport`,
  origin_land_port: `Origin Land Port`,
  destination_land_port: `Destination Land Port`,
  expected_weight: `Expected Weight`,
  service_type: 'Shipping Term',
  date_of_readiness: `Date of Readiness`,
  hazardous: `Hazardous`,
  location: `Location`,
  get_location: `Get Location`,
  commodity: `Commodity`,
  from: `From`,
  to: `To`,
  summary: `Summary`,
  documents: `Documents`,
  shiperContactDetails: `Shipper Information`,
  communication: `Communication`,
  origin: 'Origin',
  destination: 'Destination',
  make_offer: 'Make Offer',
  create_service: 'Create Service',
  add_field: 'Add Field',
  fee_name: 'Fee Name',
  fee_charge: 'Fee Charge',
  submit: 'Submit',
  note: 'Note',
  order_details: 'Order Details',
  view_details: 'View Details',
  created_at: 'Created At',
  required: 'Required',
  operation_successful: 'Operation Successful',
  operation_failed: 'Operation Failed',
  client: 'Client',
  service_provider: 'Service Provider',
  admin: 'Admin',
  select_service: 'Select Service',
  port: 'Port',
  enter: 'Enter',
  required_documents: 'Required Documents',
  file_name: 'File Name',
  file: 'File',
  registered_services: 'Registered Services',
  orderSummary: 'Order Summary',
  orderDetails: 'Order Details',
  deliveryAddress: 'Delivery Address',
  region: 'Region',
  createdDate: 'Created Date',
  lastUpdate: 'Last Updated',
  register_account: 'Register A New Account',
  first_name: 'First Name',
  last_name: 'Last Name',
  email: 'Email',
  password: 'Password',
  type_of_account: 'Type of account',
  enter_cr: 'Enter CR',
  sign_up: 'Sign Up',
  register_a_new_service: 'Register A New Service',
  select_region: 'Select Region',
  apply: 'Apply',
  search: 'Search',
  search_for_offer: 'Search For Offer',
  view_all: 'View All',
  offer_name: 'Offer Name',
  offer_id: 'Offer ID',
  offer_date: 'Offer Date',
  total_price: 'Total Price',
  offers_expenses: "Offer's Expenses",
  read: 'Read',
  expense_details: 'Expense Details',
  add_an_expense: 'Add an Expense',
  item_name: 'Item Name',
  initiated: 'Initiated',
  waiting_acceptance: 'Waiting Acceptance',
  upload: 'Upload',
  expenses: 'Expenses',
  are_you_sure: 'Are you sure ?',
  yes_send_offer: 'Yes, send offer',
  success: 'Sucess',
  your_offer_sent: 'Your offer has been sent',
  error: 'Error',
  send_offer: 'Send Offer',
  national_id: 'National ID',
  drag_and_drop: 'Drag & drop your file or',
  browse: 'Browse',
  to_upload: 'to upload',
  supported_formats: 'Supported formats are: PDF',
  cr: 'Commerical Registration',
  sar: 'SAR',
  add_offer: 'Add Offer',
  add: 'Add',
  auto_rejected_tooltip: 'Auto rejected means the client has chosen other offers',
  successfully_registered: 'Successfully Registered',
  custom_clearance_licence: 'Custom Clearance Licence',
  sea_shipping: 'Sea Shipping',
  order_created: 'Order Created',
  order_time: 'Order Time',
  total: 'Total',
  '10%': '10%',
  shipping: 'Shipping',
  customs_clearance_description: 'Handle the custom clearance process & documents',
  shipping_description: 'Provide large scale shipping from overseas',
  local_land_description: 'Provide all kind of transportation in KSA',
  local_transportation: 'Local Land Transportation',
  action: 'Action',
  yes: 'Yes',
  no: 'No',
  find_location: 'Find Location',
  sea: 'Sea',
  air: 'Air',
  land: 'Land',
  metric_tons: 'Metric Tons',
  kilogram: 'Kilogram',
  gps_coord: 'GPS Coordinates',
  order_submitted_successfully: 'Order Submitted Successfully',
  upload_document: 'Upload Document',
  reason_for_rejection: 'Reason for rejection',
  reason: 'Reason',
  save: 'Save',
  cancel: 'Cancel',
  offer_reject_auto: 'The offer was rejected automatically',
  offer_reject: 'The offer is rejected',
  offer_details: 'Offer Details',
  bid_id: 'Bid ID',
  would_you_like_local_transport: 'Would you like local transportation ?',
  get_gps_coord: 'Get GPS Coordinates',
  confirm: 'Confirm',
  freight_fowarder_broker_license: 'Freight Forwarder broker License',
  road_freight_transport_licence: 'Road Freight Transport Licence',
  consignee_contact_details: 'Consignee Information',
  company_name: 'Company Name',
  company_address: 'Company Address',
  district: 'District',
  street_name: 'Street Name',
  postal_code: 'Postal Code',
  po_box_number: 'PO Box Number',
  contact_person: 'Contact Person',
  mobile_number: 'Mobile Number',
  vat_number: 'VAT Number',
  add_new_shipping_contact: 'Add New Shipping Contact',
  add_new_consignee_contact: 'Add New Consignee Contact',
  vat_length: 'VAT must have 15 digits',
  cr_length: 'CR must have 10 digits',
  draft_bl: 'Draft BL',
  final_bl: 'Final BL',
  final_service_invoice: 'Final Service Invoice',
  change_file: 'Change File',
  info: 'Info',
  upload_file: 'Upload File',
  document_name: 'Document Name',
  document_type: 'Document Type',
  document_date: 'Document Date',
  last_modified: 'Last Modified',
  related_documents: 'Related Documents',
  add_document: 'Add Document',
  no_files: 'No Files',
  request_file: 'Request File',
  file_type: 'File Type',
  pdf: 'PDF',
  start: 'Start',
  in_progress: 'In Progress',
  add_contact: 'Add Contact',
  address: 'Address',
  tag: 'Tag',
  serial_number: 'SN',
  disclaimer_liability:
    'I am undertaking to disclaim liability in absence of the required certificate',
  latitude: 'Latitude',
  longitude: 'Longitude',
  Destination_Address: 'Destination Address',
  bill_of_lading: 'Bill Of Lading',
  purchase_invoice_goods: 'Purchase Invoice Of Goods',
  saber_certificate: 'SABER Certificate',
  customs_release_doc: 'Customs Release Document',
  final_service_invoice_for_custom: 'Final Service Invoice',
  custom_release_doc_land_trans: 'Customs Release Document',
  final_service_invoice_land_trans: 'Final Service Invoice',
  no_orders: 'No Orders',
  back: 'Back',
  add_new_expense_to_offer: 'Add New Expense To The Offer',
  'Order Created': 'Order Created',
  order: 'Order',
  'Customs Clearance': `Customs Clearance`,
  'Local Land Transportation': `Local Land Transportation`,
  'Sea Shipping': 'Sea Shipping',
  Sea: 'Sea',
  Air: 'Air',
  Land: 'Land',
  'user management': 'User Management',
  group: 'Group',
  role: 'Role',
  permission: 'Permission',
  create_user: 'Create User',
  search_user: 'Search User',
  create_group: 'Create Group',
  search_group: 'Search Group',
  group_name: 'Group Name',
  group_description: 'Group Description',
  search_role: 'Search Role',
  create_role: 'Create Role',
  role_name: 'Role Name',
  role_description: 'Role Description',
  operations: 'OPERATIONS',
  finance: 'FINANCE',
  sales: 'SALES',
  marketing: 'MARKETING',
  customer_care: 'CUSTOMER CARE',
  enter_valid_email: 'Enter valid email',
  pass_length: 'Password should be minimum 8 characters',
  permission_name: 'Permission Name',
  permission_description: 'Permission Description',
  create_permission: 'Create Permission',
  search_permission: 'Search Permission',
  add_user_to_group: 'Add User To Group',
  remove_user_group: 'Remove User From Group',
  view_groups: 'View Groups',
  remove_user: 'Remove User',
  add_user: 'Add User',
  add_permission: 'Add Permission',
  remove_permission: 'Remove Permission',
  update_group: 'Update Group',
  delete_group: 'Delete Group',
  add_permission_to_groups: 'Add Permissions To Group',
  permissions: 'Permissions',
  remove_permission_from_group: 'Remove Permission From Group',
  view_permissions: 'View Permissions',
  update: 'Update',
  delete_permission: 'Delete Permission',
  add_permission_to_user: 'Add Permission To User',
  remove_permission_from_user: 'Remove Permission From User',
  search_orders: 'Search Orders',
  flag_order: 'Flag Order',
  issue_final_invoice: 'Issue Final Invoice',
  service_provider_id: 'Service Provider ID',
  client_reject_note: 'Client Reject Note',
  sp_status: 'Service Provider Status',
  client_status: 'Client Status',
  register: 'Register',
  email_already_registered: 'Email Already Registered',
  cr_already_registered: 'CR Already Registered',
  time_registered: 'Time Registered',
  account_status: 'Account Status',
  active: 'Active',
  in_active: 'In Active',
  activate: 'Activate',
  deactivate: 'Deactivate',
  flag_comment: 'Flag Comment',
  view_flag: 'View Flag',
  final_invoice_admin_shipping: 'Final Invoice Admin Shipping',
  final_invoice_admin_custom: 'Final Invoice Admin Custom Clearance',
  final_invoice_admin_land_transport: 'Final Invoice Admin Local Land Transportation',
  expected_days: 'Expected Days To Complete Order',
  expected_days_offer: 'Expected Days',
  expected_delivery_time: 'Expected Delivery Time',
  remove_flag: 'Remove Flag',
  wait_payment: 'Waiting For Payment',
  confirm_order: 'Confirm Order',
  offer_accepted: 'Offer Accepted',
  issue_initial_invoice: 'Issue Initial Invoice',
  initial_invoice_admin_shipping: 'Initial Invoice Admin Shipping',
  initial_invoice_admin_custom: 'Initial Invoice Custom Clearance',
  initial_invoice_admin_land_transport: 'Initial Invoice Admin Local Land Transportation',
  upload_documents: 'Upload Documents',
  users: 'Users',
  groups: 'Groups',
  dashboard_page: 'Dashboard Page',
  groups_page: 'Groups Page',
  orders_page: 'Orders Page',
  register_page: 'Register Page',
  user_management_page: 'User Management Page',
  users_page: 'Users Page',
  view_orders:
    'This permission allows you to view orders, providing insights into client and service provider interactions.',
  view_user_management: 'View User Management',
  deleted: 'deleted',
  Shipping: 'Shipping',
  'waredat.sa': 'Waredat.sa',
  Jan: 'Jan',
  Feb: 'Feb',
  Mar: 'Mar',
  Apr: 'Apr',
  May: 'May',
  Jun: 'Jun',
  Jul: 'Jul',
  Aug: 'Aug',
  Sep: 'Sep',
  Oct: 'Oct',
  Nov: 'Nov',
  Dec: 'Dec',
  login: 'Login',
  draft_bl_not_accepted_by_client: 'Draft BL Not Accepted By The Client',
  upload_final_bl: 'Upload Final BL',
  draft_bl_not_found: 'Draft BL Not Uploaded',
  upload_custom_release_doc: 'Upload Custom Release Document',
  client_didnt_fill_consignee_contact: 'Client Didnt Fill Consignee Information',
  client_didnt_fill_shipper_contact: 'Client Didnt Fill Shipper Information',
  client_didnt_upload_bl: 'Client Didnt Upload Bill Of Lading',
  client_didnt_upload_purch_inv: "Client Didn't Upload Purchase Invoice Of Goods",
  client_didnt_upload_saber_cert: "Client Didn't Upload Saber Certificate",
  client_didnt_upload_cust_release_doc: "Client Didn't Upload Customs Release Document",
  mobile_number_10_digits: 'Mobile Number Should 10 Digits',
  enter_verification_code: 'Enter Verification Code',
  mobile_number_format: 'Mobile Number Format 05XXXXXXXX',
  mobile_number_taken: 'Mobile Number Taken',
  verification_code_send_to: 'Verification code send to',
  verification_failed: 'Verification Failed',
  pending_completion: 'Pending Completion',
  edit_expense: 'Edit Expense',
  value_incl_vat: 'Value(Incl. VAT)',
  price_incl_vat: 'Price(Incl. VAT)',
  initial_invoice_issued: 'Initial Invoice Issued',
  final_invoice_issued: 'Final Invoice Issued',
  draft_bl_uploaded: 'Draft BL Uploaded',
  draft_bl_accepted: 'Draft BL Accepted',
  draft_bl_rejected: 'Draft BL Rejected',
  qty: 'Qty',
  successfully_verified: 'Successfully Verified',
  packing_list: 'Packing List',
  client_didnt_upload_packing_list: "Client Didn't Upload Packing List",
  approve_rejection_note: 'Approve Rejection Note',
  transfer_receipt_shipping: 'Transfer Receipt',
  transfer_receipt_custom: 'Transfer Receipt',
  transfer_receipt_land: 'Transfer Receipt',
  client_didnt_upload_transfer_receipt: "Client Didn't Upload Transfer Receipt",
  waiting_for_approval: 'Waiting For Approval',
  service_regions: 'Service Regions',
  region_name: 'Region Name',
  waredat_verified: 'Waredat Verified',
  verified: 'Verified',
  verification_pending: 'Verification Pending',
  approve_region: 'Approve Region',
  view_documents: 'View Documents',
  close: 'Close',
  client_sp: 'Client / Service Provider',
  client_sp_page: 'Client / Service Provider Page',
  rate_service_provider: 'Rate Service Provider',
  feedback_waredat: 'Feedback For Waredat (Optional)',
  complete_order: 'Complete Order',
  download_pdf: 'Download PDF',
  order_time_finished: 'Order Time Finished',
  order_time_finished_order: 'Overdue Finish The Order ASAP And Contact Waredat',
  support: 'Support',
  title: 'Title',
  open_ticket: 'Open Ticket',
  search_ticket: 'Search Ticket',
  attach_images: 'Attach Images',
  max_size_5mb: 'Max Size 5MB',
  max_file_5: 'Max Files 5',
  order_overdue: 'Order Overdue',
  order_time_finished_waredat: "Overdue The Service Provider Didn't Finish The Order In Time",
  time_updated: 'Time Updated',
  view_images: 'View Images',
  support_ticket: 'Support Ticket',
  open: 'Open',
  delayed: 'Delayed',
  on_hold: 'On Hold',
  resolved: 'Resolved',
  un_resolved: 'Unresolved',
  final_invoice: 'Final Invoice',
  initial_invoice: 'Initial Invoice',
  assignee: 'Assignee',
  images: 'Images',
  assign_waredat_employee: 'Assign Waredat Employee To Ticket',
  assign: 'Assign',
  Employee: 'Employee',
  ticket_assigned: 'Support Ticket Assigned',
  assignee_already_working: 'A assignee is already working on the ticket',
  session_expired: 'Session has expired! ',
  want_to_continue: 'Want to continue ? Just Click on Yes button.',
  transfer_receipt_uploaded: 'Transfer Receipt Uploaded',
  region_approved: 'Region Approved',
  approved: 'Approved',
  vat: 'VAT',
  reject_region: 'Reject Region',
  region_rejected: 'Region Rejected',
  support_page: 'Support Page',
  file_request: 'File Request',
  message: 'Message',
  type_message_here: 'Type message here',
  message_received: 'Message Received',
  national_address: 'National Address',
  vat_file: 'VAT File',
  account_details: 'Account Details',
  name: 'Name',
  email_address: 'Email Address',
  phone_number: 'Phone Number',
  state_region: 'State/Region',
  zip_code: 'Zip/Code',
  about: 'About',
  cr_number: 'CR Number',
  document: 'Document',
  replace_file: 'Replace File',
  update_success: 'Update Success',
  old_chat: 'OLD CHAT',
  user_profile: 'User Profile',
  wait_initial_payment: 'Waiting For Initial Payment',
  wait_final_payment: 'Waiting For Final Payment',
  await_user_completion: 'Awaiting User Completion',
  add_additonal_expense: 'Add Additional Expense',
  transfer_receipt_custom_final: 'Final Transfer Receipt',
  transfer_receipt_land_final: 'Final Transfer Receipt',
  transfer_receipt_shipping_final: 'Final Transfer Receipt',
  wait_waredat_payment: 'Waiting For Waredat Payment',
  payment_cleared: 'Payment Cleared',
  payment_confirmed: 'Payment Confirmed',
  receipt: 'Receipt',
  await_user_payment_confirmation: 'Awaiting Payment Confirmation',
  import: 'IMPORT',
  export: 'EXPORT',
  container_type: 'Container Type',
  shipping_terms: 'Shipping Term',
  shipping_type: 'Shippment Type',
  signin_to_waredat: 'Sign in to Waredat.sa',
  new_user: 'New user?',
  create_a_account: 'Create an account',
  forgot_password: 'Forgot your password?',
  welcome_back_to: 'Welcome back to',
  waredat_platform: 'Waredat Platform',
  already_have_an_account: 'Already have an account!',
  sign_in: 'Sign In',
  cr_signup: 'CR',
  transportType: "Transport Type",
  continue_end_session: "Continue/End Session",
  temperature: "Temperature",
  local: "LOCAL",
  saber: "Saber",
  hazard: "Harazard",
  custom_local_transport: "Custom Clearance + Local Transport",
  local_custom_clearacnce: "Local Transport + Custom Clearance",
  cargo_type: "Cargo Type",
  CBM: "CBM", 
  kg: "Kg",
  port_type: "Port Type",
  port_licence: "Port Licence",
  notify_party: "Notify Party",
  information: "Information",
  waredat_transfer_receipt: "Waredat Transfer Receipt",
  order_info_updated: "Order Info Updated",
  file_request_uploaded: "File Request Uploaded",
  file_request_accept_rejected: "Accept Or Rejected The Requested File",
  file_request_client_upload: "The Client Didn't Upload The Requested File",
  waredat_receipt: "Waredat Receipt",
  order_files_uploaded: "Order Files Uploaded",
  'Land Transportation': "Land Transportation",
  'Land_Transportation': "Land Transportation",
  return_to_sign_in: "Return to sign in",
  request_sent_successfully: "Request sent successfully!",
  reset_password_link_email: "We've sent a reset password link to your registered email.",
  reset_password_text: "Please enter the email address associated with your account and We will email you a link to reset your password.",
  send_request: "Send Request",
  enter_new_password: "Enter New Password",
  re_enter_password: "Re-enter Password",
  passwords_not_match: "Passwords do not match",
  reset_link_expired: "Reset Link Expired",
  reset_link_expired_text: "Password Reset Link Expired",
  password_reset_successful: "Password Reset Successful",
  password_reset_limit: "Password Reset Limit",
  please_contact_waredat_support: "Please Contact Waredat Support",
  password_reset_link_invalid: "Password Reset Link Invalid",
  upload_files: "Upload Files",
  upload_vat_national_address: "Upload VAT and National Address",
  account_type: "Account Type",
  super_user: "Super User",
  waredat_employee: "Waredat Employee",
  order_missed: "Order Missed",
  change_password: "Change Password",
  old_password: "Old Password",
  final_transfer_receipt_uploaded: "Final Transfer Receipt Uploaded",
  new_password: "New Password",
  re_enter_new_password: "Re-enter New Password",
  order_id: "Order ID",
  expected_finish_date: "Expected Order Finish Date",
  confirmed_date: "Confirmed Order Date",
  order_in_progress: "Order In Progress",
  reject_rejection_note: "Reject Rejection Note",
  review_client_reject_note: "Review Client Reject Note",
  cr_not_active: "CR Not Active",
  additional_expense_receipt: "Additional Expense Receipt",
  disclaimer: "Disclaimer",
  accurate_info_liability: "I acknowledge and confirm the information provided by me is accurate",
  pickup_address: "Pickup Address",
  reject_offer: "Reject Offer",
  retract_offer: "Retract Offer",
  warning_text_retract_offer: "Are You Sure You Want To Retract the offer ?",
  delete_offer: "Delete Offer",
  warning_text_delete_offer: "Are You Sure You Want To Delete the offer ?",
  cancel_order: "Cancel Order",
  cancel_order_text: "Are You Sure You Want To Cancel the Order ?",
  cancel_order_text_request: "Are You Sure You Want To Send A Cancellation Request For The Order ?",
  cancelled_by_client: "Cancelled By Client",
  cancelled_by_sp: "Cancelled By SP",
  approve_cancel_order_request: "Approve Cancel Order Request",
  reject_cancel_order_request: "Reject Cancel Order Request",
  review_cancel_order_request: "Review Cancel Order Request",
  order_cancellation_request_review: "Order Cancellation Request Under Review",
  order_cancel_request_approved: "Order Cancel Request Approved",
  order_cancel_request_rejected: "Order Cancel Request Rejected",
  order_cancelled: "Order Cancelled",
  have_issue: "Have Issue",
  have_issue_text: "Are You Sure You Have A Issue With The Order ?",
  delay_issue_text: "Are You Sure You Want To Delay The Order ?",
  resume: "Resume",
  order_delayed: "Order Delayed",
  order_resumed: "Order Resumed",
  delayed_waiting_client_inputs: "Delayed Waiting Client Inputs",
  resolving_issue: "Resolving Issue",
  resovled: "Resolved",
  issue_resolved: "Issue Resolved",
  invalid: "Invalid",
  same_as_notify_party: "Same As Notify Party",
  reason_change_delivery_time: "Reason To Change Delivery Time",
  delivery_time_changed: "Delivery Time Changed"
};

export default en;
