import { createSlice } from '@reduxjs/toolkit';
// ----------------------------------------------------------------------

const initialState = {
  otpState : {
    otp: false,
    user_id: null,
    id: null,
  }
};

const slice = createSlice({
  name: 'Otp',
  initialState,
  reducers: {
    setOtp: (state, action) => {
      state.otpState = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const { setOtp } = slice.actions;
