import { createSlice } from '@reduxjs/toolkit';
import TrackerApi from '../../api/index';
// ----------------------------------------------------------------------

const initialState = {
  orders: [],
  page: 0,
  totalItems: 0,
  rowsPerPage: 10,
  loading: false,
  backGroundLoading: false,
};

const slice = createSlice({
  name: 'historyOrdersSp',
  initialState,
  reducers: {
    setHistoryOrders: (state, action) => {
      state.orders = action.payload;
    },
    setWaitingOrdersLoading: (state, action) => {
      state.loading = action.payload;
    },
    setTotalItems: (state, action) => {
      state.totalItems = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setBackGroundLoading: (state, action) => {
      state.backGroundLoading = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const {
  setHistoryOrders,
  setWaitingOrdersLoading,
  setTotalItems,
  setPage,
  setBackGroundLoading,
  setRowsPerPage,
} = slice.actions;

export function getHistoryOrders(page, rowsPerPage) {
  return async (dispatch) => {
    try {
      dispatch(setWaitingOrdersLoading(true));
      dispatch(setPage(page));
      dispatch(setRowsPerPage(rowsPerPage));
      const response = await TrackerApi.get('/orders/service_provider/completed', {
        params: { skip: page * rowsPerPage, limit: rowsPerPage },
      });
      dispatch(setTotalItems(response?.data?.metadata?.total_elements));
      dispatch(setHistoryOrders(response.data.data));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setWaitingOrdersLoading(false));
    }
  };
}
